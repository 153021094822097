import axios from 'axios';
import get from 'lodash.get';

export const apiRequest = (url, payload, method = 'GET', debug = false) => {
  let debugMod = false;
  if (process.env.NODE_ENV === 'development' && debug) {
    debugMod = true;
  }
  const typeOfMethod = method === 'FILE_UPLOAD' ? 'POST' : method;

  return new Promise((resolve, reject) => {
    // let progress = 0;
    // axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
    // axios.defaults.headers.post['Content-Type'] ='application/json;charset=utf-8';
   
    const token = localStorage.getItem('userToken')
    let headerProps = {}
   if(token !=null ) {
     
      headerProps =  {
        Accept: '"application/json, text/plain, */*"',

        'Content-Type':
        method === 'FILE_UPLOAD' ? 'multipart/form-data' : 'application/json',
       Authorization: `Bearer ${token}`,
      }
   }
   else {
     headerProps =  {
      Accept: '"application/json, text/plain, */*"',
      'Content-Type':
      method === 'FILE_UPLOAD' ? 'multipart/form-data' : 'application/json',
      
     }
   }

    axios({
      method: typeOfMethod,
      url,
      data: method === 'FILE_UPLOAD' ? payload : JSON.stringify(payload),
      headers: headerProps,
    })
      .then(res => {
        if (debugMod) {
          console.group(
            `Network request ${url} request status code ${res.status}`,
          );
          console.log(res);
          console.groupEnd();
        }
        if (res.status !== 200) {
          reject(get(res, 'response.data.message', 'Something went wrong'));
        }
        resolve(res.data);
      })
      .catch(err => {
        if (debugMod) {
          console.group(`Error ${url}`);
          console.log(err);
          console.groupEnd();
        }
        reject(get(err, 'response.data.message', 'Something went wrong'));
      });
  });
};

export const apiRequestPOST = (url, payload, debug = false) => {
  let debugMod = false;
  if (process.env.NODE_ENV === 'development' && debug) {
    debugMod = true;
  }
  // console.log('>> >',payload)

  return new Promise((resolve, reject) => {
    const token = localStorage.getItem('userToken')
    let headerProps = {}
   if(token !=null ) {
     
      headerProps =  {
        Accept: '"application/json, text/plain, */*"',
        'Content-Type': 'application/x-www-form-urlencoded',
       Authorization: `Bearer ${token}`,
      }
   }
   else {
     headerProps =  {
      Accept: '"application/json, text/plain, */*"',
      'Content-Type': 'application/x-www-form-urlencoded',
      
     }
   }

const keys = Object.keys(payload);

   var formDate = []

keys.forEach((key, index) => {
     formDate.push(key + '=' + payload[key]) 

});
 formDate = formDate.join("&");

    axios({
      method: "POST",
      url,
      data:formDate,
      headers:headerProps,
    })
      .then(res => {
        if (debugMod) {
          console.group(
            `Network request ${url} request status code ${res.status}`,
          );
          console.log(res);
          console.groupEnd();
        }
        if (res.status !== 200) {
          reject(get(res, 'response.data.message', 'Something went wrong'));
        }
        resolve(res.data);
      })
      .catch(err => {
        if (debugMod) {
          console.group(`Error ${url}`);
          console.log(err);
          console.groupEnd();
        }
        reject(get(err, 'response.data.message', 'Something went wrong'));
      });
  });
};


