 
import React, { useEffect, useState, useContext,useRef } from 'react'
import { Link,useLocation } from 'react-router-dom'
import Header from './Header'
import Footer from './Footer'
import { BASE_URL } from '../utils/Constants'
import { apiRequestPOST } from '../utils/ApiCall'
import { toast } from 'react-toastify'
import GoogleMapReact from 'google-map-react';
import get from 'lodash.get'
import Geocode from "react-geocode";
import { Field, Formik, Form } from 'formik'
import * as Yup from 'yup';
import { userDetailContext } from '../App';
import { useHistory } from "react-router-dom";
   
   export default function Wishlist() {
      var contextData = useContext(userDetailContext);

   const [storeRateing, setStoreRateing] = useState([]);
   const [storeRateingValue, setStoreRateingValue] = useState([]);
   const [storeId, setStoreId] = useState('');
   const [ratingButton, setRatingButton] = useState('false')   
   let history = useHistory();
      const location = useLocation();
      useEffect(async()=> {   
          apiRequestPOST(`${BASE_URL}app_user_ctrl/store_rating`,'', false)
          .then((response) => {
             setStoreRateing(response) 
          }, (error) => {
             console.log(error);
          });
         },[location])

         const closee1_add_stote_rating = useRef(); 
       return (
        <React.Fragment> 
        <Header/>
        
        <div className="modal fade login-modal-main " id="bd-example-modal_user_store_rating"  data-backdrop="static" data-keyboard="false">
            <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
               <div className="modal-content">
                  <div className="modal-body ">
                     <div className="login-modal">
                        <div className="row"> 
                           <div className="col-lg-12">
                           <button type="button"  className="close close-top-right" data-dismiss="modal" aria-label="Close" id='closee1_add_stote_rating' ref={closee1_add_stote_rating} >
                                 <span aria-hidden="true"><i className="mdi mdi-close"></i></span>
                                 <span className="sr-only">Close</span>
                              </button>
                              
 
                              <Formik
                                    initialValues={{
                                       rating: '',  
                                       store_id: storeId, 
                                    }}  
                                    enableReinitialize
                                    onSubmit={values => {
                                       setRatingButton({ val: "disabled" })
                                       apiRequestPOST(`${BASE_URL}app_user_ctrl/add_stote_rating`, values, false)
                                          .then((response) => {
                                             setRatingButton({ val: "" }) 
                                             closee1_add_stote_rating.current.click() 
                                             if (response != 1) {
                                                setStoreRateing(response) 
                                              } 
                                          }, (error) => {
                                             console.log(error);
                                          });
 
                                    }}
                                 >
                                    {({ errors, touched }) => (
                                       <Form>
                           
 
                          
    
                           <div className="rating-css"> 
   <div>Store rating</div>
   <div className="star-icon"  onClick={() => setStoreRateingValue(10) }>
     <Field type="radio" name="rating" id="rating1" value="1"/>
     <label htmlFor="rating1" className="fa fa-star"></label>
     <Field type="radio" name="rating" id="rating2" value="2"/>
     <label htmlFor="rating2" className={storeRateingValue>=2?"fa fa-star":"fa fa-star ratingcolor"}></label>
     <Field type="radio" name="rating" id="rating3" value="3"/>
     <label htmlFor="rating3" className={storeRateingValue>=3?"fa fa-star":"fa fa-star ratingcolor"}></label>
     <Field type="radio" name="rating" id="rating4" value="4"/>
     <label htmlFor="rating4" className={storeRateingValue>=4?"fa fa-star":"fa fa-star ratingcolor"}></label>
     <Field type="radio" name="rating" id="rating5" value="5"/>
     <label htmlFor="rating5" className={storeRateingValue>=5?"fa fa-star":"fa fa-star ratingcolor"}></label>
     <div className="row"><div className="col-lg-3"></div>
   <div className="col-lg-3"> <button type="button" className="btn btn-lg btn-danger   btn-block" data-dismiss="modal">Cancel</button></div> 
    <div className="col-lg-3"> <button type="submit"  disabled={(ratingButton.val) ? "disabled" : ""} className="btn btn-lg btn-success btn-block"  >Post</button></div>
   </div> 
                                               
   </div>
    </div> 
 
    </Form>
                                    )}
                                 </Formik>



                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>


         <section className="account-page section-padding">
         <div className="container">
            <div className="row">
            <div className="col-lg-10 col-md-12 mx-auto">
                  <div className="row no-gutters">
                  <div className="col-md-4 col-lg-3">
                        <div className="card account-left">
                        <div className="user-profile-header">
                                 {/* <img alt="logo" src="assets/img/user.jpg"/> */}
                                 <h5 className="mb-1 text-secondary"><strong>Hi </strong> {contextData?.username?.name}</h5>
                                 <p> {contextData?.username?.mob}</p>
                              </div>
                           <div className="list-group">
                           <Link to="/my_profile" className="list-group-item list-group-item-action"><i aria-hidden="true" className="mdi mdi-account-outline"></i>  My Profile</Link>
                                 <Link to="/my_address" className="list-group-item list-group-item-action"><i aria-hidden="true" className="mdi mdi-map-marker-circle"></i>  My Address</Link>
                                 <Link to="/wishlist" className="list-group-item list-group-item-action "><i aria-hidden="true" className="mdi mdi-heart-outline"></i>  Wish List </Link>
                                 <Link to="/rating" className="list-group-item list-group-item-action active"><i aria-hidden="true" className="mdi mdi-star-outline"></i>  Store Rating </Link>
                                 <Link to="/orderlist" className="list-group-item list-group-item-action"><i aria-hidden="true" className="mdi mdi-format-list-bulleted"></i>  Order List</Link>
                                 <Link  className="list-group-item list-group-item-action"><i aria-hidden="true" className="mdi mdi-lock"></i>  Logout</Link>
                           </div>
                        </div>
                     </div>
                     <div className="col-md-8 col-lg-9">
                        <div className="card card-body account-right">
                           <div className="widget">
                              <div className="section-header">
                                 <h5 className="heading-design-h5">
                                    Store Rating
                                 </h5>
                              </div>
                        
                       
                              <div className="row no-gutters">
            {
                        storeRateing&&storeRateing.map(item => { 
                          return (    
                              <div className="col-md-5 shop_cls">
                                             <div className="item"  style={{ cursor: 'pointer' }} 
                                            onClick={() => 
                                              {  setStoreRateingValue(item.ratingsum)
                                                setStoreId(item.id)}
                                             } data-target="#bd-example-modal_user_store_rating" data-toggle="modal">
                                                <div className="product">

                                                   <div className="product-header"> 
                                                      <img className="img-fluid" src={`${BASE_URL}assets/stores/original/${item.logo_image}`} alt="" />
                                                    </div>
                                                   <div className="product-body">

                                                   <h4><center>{item.store_name?.toUpperCase()}</center></h4> 
                                               <center>
<span className={item.ratingsum>=1?"fa fa-star fastarchecked":'fa fa-star fastarunchecked'}></span>
<span className={item.ratingsum>=2?"fa fa-star fastarchecked":'fa fa-star fastarunchecked'}></span>
<span className={item.ratingsum>=3?"fa fa-star fastarchecked":'fa fa-star fastarunchecked'}></span>
<span className={item.ratingsum>=4?"fa fa-star fastarchecked":'fa fa-star fastarunchecked'}></span>
<span className={item.ratingsum>=5?"fa fa-star fastarchecked":'fa fa-star fastarunchecked'}></span>
                                               </center>
                                                        </div>
                                                  

                                                   </div> 
                                                </div>  
                                                </div>  
                           )
                        })
                     }
                     </div>  
                             
							 
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <section className="section-padding bg-white border-top">
         <div className="container">
            <div className="row">
               <div className="col-lg-4 col-sm-6">
                  <div className="feature-box">
                     <i className="mdi mdi-truck-fast"></i>
                     <h6>Free & Next Day Delivery</h6>
                     <p>Lorem ipsum dolor sit amet, cons...</p>
                  </div>
               </div>
               <div className="col-lg-4 col-sm-6">
                  <div className="feature-box">
                     <i className="mdi mdi-basket"></i>
                     <h6>100% Satisfaction Guarantee</h6>
                     <p>Rorem Ipsum Dolor sit amet, cons...</p>
                  </div>
               </div>
               <div className="col-lg-4 col-sm-6">
                  <div className="feature-box">
                     <i className="mdi mdi-tag-heart"></i>
                     <h6>Great Daily Deals Discount</h6>
                     <p>Sorem Ipsum Dolor sit amet, Cons...</p>
                  </div>
               </div>
            </div>
         </div>
      </section>
  
      
      <Footer/>
     
     </React.Fragment>
    )
}
