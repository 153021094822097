import { createContext, useState,useEffect } from "react";
import RouterPag from "./pags/RouterPags";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export const userDetailContext = createContext(null);
export function AddLibrary(urlOfTheLibrary) {
    const script = document.createElement('script');
    script.src = urlOfTheLibrary;
    script.async = true;
    document.body.appendChild(script);
  }

function App() { 
    {AddLibrary('https://theqwadd.com/custom.js')}
          
 
    const version = JSON.parse(localStorage.getItem("version"));
    // console.log('version',version);
   if(version==null || version<=4){
    window.localStorage.clear();
    localStorage.setItem('version',5)  
   }
    const localUsername = JSON.parse(localStorage.getItem("userName"));
    const [username, setUsername] = useState(
        localUsername != null ? localUsername : ""
    );

    const localGeoLoaction = localStorage.getItem("geoLocation");
    const [geoLoaction, setGeoLoaction] = useState(
        localGeoLoaction != null ? localGeoLoaction : ""
    );  

    const countrySelect = localStorage.getItem("country");
    const [country, setCountry] = useState(
        countrySelect != null ? countrySelect : ""
    );  
   
    const userCartist = JSON.parse(localStorage.getItem("userCartist"));
    const [usercartlist, setUserCartist] = useState(
        userCartist != null ? userCartist : []
    );  
   
    const userFavourite = JSON.parse(localStorage.getItem("userFavourite"));
    const [userfavourite, setUserFavourite] = useState(
        userFavourite != null ? userFavourite : []
    );  
    
   
    const admindata = JSON.parse(localStorage.getItem("adminData"));
    const [adminData, setAdminData] = useState(
        admindata != null ? admindata : []
    );  
   
    const datahistory = JSON.parse(localStorage.getItem('datahistory'));
    const [dataHistory, setDataHistory] = useState(
        datahistory != null ? datahistory : []
    );  
   
    const shopDatails = JSON.parse(localStorage.getItem('datahistory'))?.shop_datails;
    const [shopdatails, setShopDatails] = useState(
        shopDatails != null ? shopDatails : []
    );  

    const shopIdz = JSON.parse(localStorage.getItem('datahistory'))?.id;
    const [shopidz, setShopIdz] = useState(
        shopIdz != null ? shopIdz : []
    );  

    const branchId = JSON.parse(localStorage.getItem('datahistory'))?.shop_datails?.branch_id;
    const [branchid, setBranchId] = useState(
        branchId != null ? branchId : []
    );    

    const shoplist = JSON.parse(localStorage.getItem('shoplisttemphistory'));
    const [shoplistz, setShopList] = useState(
        shoplist != null ? shoplist : []
    );  

    const businessCategory = JSON.parse(localStorage.getItem('businessCategory'));
    const [businessCategoryz, setBusinessCategory] = useState(
        businessCategory != null ? businessCategory : []
    );  
   
    const userNotificvation = JSON.parse(localStorage.getItem("userNotificvation"));
    const [usernotificvation, setUserNotificvation] = useState(
        userNotificvation != null ? userNotificvation : 0
    );  

    return (
        <div class="App">
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <userDetailContext.Provider
                value={{
                    shoplistz:shoplistz,
                    username: username,
                    setUsername: (username) => setUsername(username),
                    geoLoaction: geoLoaction,
                    setGeoLoaction: (geoLoaction) => setGeoLoaction(geoLoaction),
                    country: country,
                    setCountry: (country) => setCountry(country),
                    usercartlist: usercartlist,
                    setUserCartist: (usercartlist) => setUserCartist(usercartlist),
                    userfavourite: userfavourite,
                    setUserFavourite: (userfavourite) => setUserFavourite(userfavourite),
                    admindata: adminData,
                    setAdminData: (admindata) => setAdminData(admindata),
                    datahistory: dataHistory,
                    setDataHistory: (datahistory) => setDataHistory(datahistory),
                    shopDatails: shopdatails,
                    setShopDatails: (shopDatails) => setShopDatails(shopDatails),
                    shopIdz: shopidz,
                    setShopIdz: (shopIdz) => setShopIdz(shopIdz),
                    branchId: branchid,
                    setBranchId: (branchId) => setBranchId(branchId),
                    shoplist: shoplistz,
                    setShopList: (shoplist) => setShopList(shoplist),
                    businessCategory: businessCategoryz,
                    setBusinessCategory: (businessCategory) => setBusinessCategory(businessCategory),
                    userNotificvation: usernotificvation,
                    setUserNotificvation: (userNotificvation) => setUserNotificvation(userNotificvation),
                }}
            >
                <RouterPag />
            </userDetailContext.Provider>
        </div>
    );
}

export default App;
