 
     import React from 'react'
     import { Link,useLocation } from 'react-router-dom'
     import Header from './Header'
     import Footer from './Footer'
     
     export default function Blog() {
         return (
          <React.Fragment> 
          <Header/>
          
         <section class="blog-page section-padding">
         <div class="container">
            <div class="row">
               <div class="col-md-8">
                  <div class="card blog mb-4">
                     <div class="blog-header">	
                        <Link ><img class="card-img-top" src="assets/img/blog/1.png" alt="Card image cap"/></Link>
                     </div>
                     <div class="card-body">
                        <h5 class="card-title"><Link >Aliquam euismod libero eu enim. Nulla nec felis sed leo.</Link></h5>
                        <div class="entry-meta">
                           <ul class="tag-info list-inline">
                              <li class="list-inline-item"><Link ><i class="mdi mdi-calendar"></i>  March 6, 2018</Link></li>
                              <li class="list-inline-item"><i class="mdi mdi-folder"></i> <Link rel="category tag" >Image</Link></li>
                              <li class="list-inline-item"><i class="mdi mdi-tag"></i> <Link rel="tag" >envato</Link>, <Link rel="tag" >sale</Link>, <Link rel="tag" >shop</Link> </li>
                              <li class="list-inline-item"><i class="mdi mdi-comment-account-outline"></i> <Link >4 Comments</Link></li>
                           </ul>
                        </div>
                        <p class="card-text">Aliquam convallis sollicitudin purus. Praesent aliquam, enim at fermentum mollis, ligula massa adipiscing nisl, ac euismod nibh nisl eu lectus. Fusce vulputate sem at sapien. Vivamus leo. Aliquam euismod libero eu enim. Nulla nec felis sed leo placerat imperdiet. Aenean suscipit nulla in justo. Suspendisse cursus rutrum augue. Nulla tincidunt tincidunt mi. Lorem ipsum dolor
                        </p>
                        <Link >READ MORE <span class="mdi mdi-chevron-right"></span></Link>
                     </div>
                  </div>
                  <div class="card blog mb-4">
                     <div class="blog-header">	
                        <Link ><img class="card-img-top" src="assets/img/blog/2.png" alt="Card image cap"/></Link>
                     </div>
                     <div class="card-body">
                        <h5 class="card-title"><Link >Aliquam euismod libero eu enim. Nulla nec felis sed leo.</Link></h5>
                        <div class="entry-meta">
                           <ul class="tag-info list-inline">
                              <li class="list-inline-item"><Link ><i class="mdi mdi-calendar"></i>  March 6, 2018</Link></li>
                              <li class="list-inline-item"><i class="mdi mdi-folder"></i> <Link rel="category tag" >Image</Link></li>
                              <li class="list-inline-item"><i class="mdi mdi-tag"></i> <Link rel="tag" >envato</Link>, <Link rel="tag" >sale</Link>, <Link rel="tag" >shop</Link> </li>
                              <li class="list-inline-item"><i class="mdi mdi-comment-account-outline"></i> <Link >4 Comments</Link></li>
                           </ul>
                        </div>
                        <p class="card-text">Aliquam convallis sollicitudin purus. Praesent aliquam, enim at fermentum mollis, ligula massa adipiscing nisl, ac euismod nibh nisl eu lectus. Fusce vulputate sem at sapien. Vivamus leo. Aliquam euismod libero eu enim. Nulla nec felis sed leo placerat imperdiet. Aenean suscipit nulla in justo. Suspendisse cursus rutrum augue. Nulla tincidunt tincidunt mi. Lorem ipsum dolor
                        </p>
                        <Link >READ MORE <span class="mdi mdi-chevron-right"></span></Link>
                     </div>
                  </div>
                  <div class="card blog mb-4">
                     <div class="blog-header">	
                        <Link ><img class="card-img-top" src="assets/img/blog/3.png" alt="Card image cap"/></Link>
                     </div>
                     <div class="card-body">
                        <h5 class="card-title"><Link >Aliquam euismod libero eu enim. Nulla nec felis sed leo.</Link></h5>
                        <div class="entry-meta">
                           <ul class="tag-info list-inline">
                              <li class="list-inline-item"><Link ><i class="mdi mdi-calendar"></i>  March 6, 2018</Link></li>
                              <li class="list-inline-item"><i class="mdi mdi-folder"></i> <Link rel="category tag" >Image</Link></li>
                              <li class="list-inline-item"><i class="mdi mdi-tag"></i> <Link rel="tag" >envato</Link>, <Link rel="tag" >sale</Link>, <Link rel="tag" >shop</Link> </li>
                              <li class="list-inline-item"><i class="mdi mdi-comment-account-outline"></i> <Link >4 Comments</Link></li>
                           </ul>
                        </div>
                        <p class="card-text">Aliquam convallis sollicitudin purus. Praesent aliquam, enim at fermentum mollis, ligula massa adipiscing nisl, ac euismod nibh nisl eu lectus. Fusce vulputate sem at sapien. Vivamus leo. Aliquam euismod libero eu enim. Nulla nec felis sed leo placerat imperdiet. Aenean suscipit nulla in justo. Suspendisse cursus rutrum augue. Nulla tincidunt tincidunt mi. Lorem ipsum dolor
                        </p>
                        <Link >READ MORE <span class="mdi mdi-chevron-right"></span></Link>
                     </div>
                  </div>
                  <ul class="pagination justify-content-center mt-4">
                     <li class="page-item disabled">
                        <span class="page-link">Previous</span>
                     </li>
                     <li class="page-item"><Link  class="page-link">1</Link></li>
                     <li class="page-item active">
                        <span class="page-link">
                        2
                        <span class="sr-only">(current)</span>
                        </span>
                     </li>
                     <li class="page-item"><Link  class="page-link">3</Link></li>
                     <li class="page-item">
                        <Link  class="page-link">Next</Link>
                     </li>
                  </ul>
               </div>
               <div class="col-md-4">
                  <div class="card sidebar-card mb-4">
                     <div class="card-body">
                        <div class="input-group">
                           <input type="text" placeholder="Search For" class="form-control"/>
                           <div class="input-group-append">
                              <button type="button" class="btn btn-secondary">Search <i class="mdi mdi-arrow-right"></i></button>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="card sidebar-card mb-4">
                     <div class="card-body">
                        <h5 class="card-title mb-3">Categories</h5>
                        <ul class="sidebar-card-list">
                           <li><Link ><i class="mdi mdi-chevron-right"></i> Audio</Link></li>
                           <li><Link ><i class="mdi mdi-chevron-right"></i> Gallery</Link></li>
                           <li><Link ><i class="mdi mdi-chevron-right"></i> Image</Link></li>
                           <li><Link ><i class="mdi mdi-chevron-right"></i> Uncategorized</Link></li>
                           <li><Link ><i class="mdi mdi-chevron-right"></i> Video</Link></li>
                        </ul>
                     </div>
                  </div>
                  <div class="card sidebar-card mb-4">
                     <div class="card-body">
                        <h5 class="card-title mb-3">Archives</h5>
                        <ul class="sidebar-card-list">
                           <li><Link ><i class="mdi mdi-chevron-right"></i> December, 2017</Link></li>
                           <li><Link ><i class="mdi mdi-chevron-right"></i> November, 2017</Link></li>
                           <li><Link ><i class="mdi mdi-chevron-right"></i> October, 2017</Link></li>
                        </ul>
                     </div>
                  </div>
                  <div class="card sidebar-card mb-4">
                     <div class="card-body">
                        <h5 class="card-title mb-3">Tags</h5>
                        <div class="tagcloud">
                           <Link class="tag-cloud-link" >coupon</Link>
                           <Link class="tag-cloud-link" >deals</Link>
                           <Link class="tag-cloud-link" >discount</Link>
                           <Link class="tag-cloud-link" >envato</Link>
                           <Link class="tag-cloud-link" >gallery</Link>
                           <Link class="tag-cloud-link" >sale</Link>
                           <Link class="tag-cloud-link" >shop</Link>
                           <Link class="tag-cloud-link" >stores</Link>
                           <Link class="tag-cloud-link" >video</Link>
                           <Link class="tag-cloud-link" >vimeo</Link>
                           <Link class="tag-cloud-link" >youtube</Link>
                        </div>
                     </div>
                  </div>
                  <div class="card sidebar-card mb-4">
                     <div class="card-body">
                        <h5 class="card-title mb-4">Newsletter</h5>
                        <div class="input-group">
                           <input type="text" placeholder="Your email address" class="form-control"/>
                           <div class="input-group-append">
                              <button type="button" class="btn btn-secondary">Sign up <i class="mdi mdi-arrow-right"></i></button>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="card sidebar-card mb-4">
                     <div class="card-body">
                        <h5 class="card-title mb-3">Meta</h5>
                        <ul class="sidebar-card-list">
                           <li><Link ><i class="mdi mdi-chevron-right"></i> Log in</Link></li>
                           <li><Link ><i class="mdi mdi-chevron-right"></i> Entries RSS</Link></li>
                           <li><Link ><i class="mdi mdi-chevron-right"></i> Comments RSS</Link></li>
                           <li><Link ><i class="mdi mdi-chevron-right"></i> WordPress.org</Link></li>
                        </ul>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <section class="section-padding bg-white border-top">
         <div class="container">
            <div class="row">
               <div class="col-lg-4 col-sm-6">
                  <div class="feature-box">
                     <i class="mdi mdi-truck-fast"></i>
                     <h6>Free & Next Day Delivery</h6>
                     <p>Lorem ipsum dolor sit amet, cons...</p>
                  </div>
               </div>
               <div class="col-lg-4 col-sm-6">
                  <div class="feature-box">
                     <i class="mdi mdi-basket"></i>
                     <h6>100% Satisfaction Guarantee</h6>
                     <p>Rorem Ipsum Dolor sit amet, cons...</p>
                  </div>
               </div>
               <div class="col-lg-4 col-sm-6">
                  <div class="feature-box">
                     <i class="mdi mdi-tag-heart"></i>
                     <h6>Great Daily Deals Discount</h6>
                     <p>Sorem Ipsum Dolor sit amet, Cons...</p>
                  </div>
               </div>
            </div>
         </div>
      </section>
      
      <Footer/>
        
        </React.Fragment>
       )
   }
   
     