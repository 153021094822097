import React from 'react'
import { Link,useLocation } from 'react-router-dom'
import Header from './Header'
import Footer from './Footer'

export default function Returnpolicy() {
    return (
        <React.Fragment>
            
    
            <Header/>
      
   
   
     <section className="section-padding bg-white">
        <div className="container">
           <div className="row"> 
              <div className="col-lg-12 col-md-12 pl-5 pr-5">
                 <h2 className="mt-5 mb-5 text-secondary">Refund Policy</h2>
                 <p>Refund is a scheme provided by respective sellers directly under this policy in terms of which the option of exchange, replacement and/ or refund is offered by the respective sellers to you. All products listed under a particular category may not have the same returns policy. For all products, the returns/replacement policy provided on the product page shall prevail over the general returns policy. Do refer the respective item's applicable return/replacement policy on the product page for any exceptions to this returns policy and the table below

The refund policy is divided into three parts; Do read all sections carefully to understand the conditions and cases under which returns will be accepted.

         </p>
<br/><br/>  </div>
           </div>
        </div>
     </section> 
     <Footer/>
     </React.Fragment>
    )
}
