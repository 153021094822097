import React from 'react'
import { Link,useLocation } from 'react-router-dom'
import Header from './Header'
import Footer from './Footer'

export default function Returnpolicy() {
    return (
        <React.Fragment>
            
    
            <Header/>
      
   
   
     <section className="section-padding bg-white">
        <div className="container">
           <div className="row"> 
              <div className="col-lg-12 col-md-12 pl-5 pr-5">
                 <h2 className="mt-5 mb-5 text-secondary">Shipping Policy</h2>
                 <p>
                
Delivery charge varies with each Seller.

Sellers incur relatively higher shipping costs on low value items. In such cases, charging a nominal delivery charge helps them offset logistics costs. Please check your order summary to understand the delivery charges for individual products.

For Products listed as multivendor, a Rs 40 charge for delivery per item may be applied if the order value is less than Rs 500. While, orders of Rs 500 or above are delivered free.

Why does the delivery date not correspond to the delivery timeline of X-Y business days?

It is possible that the Seller or our courier partners have a holiday between the day your placed your order and the date of delivery, which is based on the timelines shown on the product page. In this case, we add a day to the estimated date. Some courier partners and Sellers do not work on Sundays and this is factored in to the delivery dates.

What is the estimated delivery time?

Sellers generally procure and ship the items within the time specified on the product page. Business days exclude public holidays and Sundays.

Estimated delivery time depends on the following factors:

The Seller offering the product
Product's availability with the Seller
The destination to which you want the order shipped to and location of the Seller.
Are there any hidden costs (sales tax, octroi etc) on items sold by Sellers on multivendor?

There are NO hidden charges when you make a purchase on multivendor. List prices are final and all-inclusive. The price you see on the product page is exactly what you would pay.

Delivery charges are not hidden charges and are charged (if at all) extra depending on the Seller's shipping policy.

Why does the estimated delivery time vary for each seller?

You have probably noticed varying estimated delivery times for sellers of the product you are interested in. Delivery times are influenced by product availability, geographic location of the Seller, your shipping destination and the courier partner's time-to-deliver in your location.
 </p>
                  </div>
           </div>
        </div>
     </section> 
     <Footer/>
     </React.Fragment>
    )
}
