import React from 'react'
import { Link,useLocation } from 'react-router-dom'
import Header from './Header'
import Footer from './Footer'

export default function Returnpolicy() {
    return (
        <React.Fragment>
            
    
            <Header/>
      
   
   
     <section className="section-padding bg-white">
        <div className="container">
           <div className="row"> 
              <div className="col-lg-12 col-md-12 pl-5 pr-5">
                 <h2 className="mt-5 mb-5 text-secondary">Returns Policy</h2>
                 <p>
                 Can items be returned after the time period mentioned in the seller's Returns Policy?

No, sellers will not be able to accept returns after the time period mentioned in the seller's Returns
Policy.
Do I have to return the freebie when I return a product?

Yes, the freebie has to be returned along with the product.
How can I return or exchange an item?

To return/exchange your order, follow these simple steps:

1. Go to My Orders
2. Choose the item you wish to return or exchange
3. Fill in the details
4. Choose Request Return.
When is a return of an item not possible?

Sellers cannot accept returns of item(s) in the following cases:

1. When an item is damaged because of use or when it is not in the same condition as you received it
2. When any consumable item has been used or installed
3. When anything is missing from the package you've received including price tags, labels, original
packing, freebies & accessories
4. When non-returnable items are involved such as innerwear. lingerie etc.
5. When items are tampered with or have missing serial numbers
I ordered a wrong item. Can I return it?

The sellers' return policies don't support the return of item(s) ordered wrongly. You can refer the
respective seller's Returns policy on the product page.
I have requested for a return for my item. When will it happen?

You can now track the status of your return easily right from your multivendor account or mobile app.
Just visit the 'My Orders' page to see its status along with the date of pick-up and status of your refund if
applicable.

You will also receive an email & SMS with the details of your return.</p>
                  </div>
           </div>
        </div>
     </section> 
     <Footer/>
     </React.Fragment>
    )
}
