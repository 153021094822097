import React,{useContext} from 'react'
import { BrowserRouter as Router,Switch,Route,Redirect } from 'react-router-dom'
import Index from './Index' 
import Shoplist from './Shoplist'
import Home from './Home'
import About from './About'
import Blog from './Blog'
import Blog_detail from './Blog_detail'
import Cart from './Cart'
import Checkout from './Checkout'
import Contact from './Contact'
import Faq from './Faq' 
import My_address from './My_address'
import My_profile from './My_profile' 
import Not_found from './Not_found' 
import Orderlist from './Orderlist' 
import Orderview from './Orderview' 
import Product from './Product' 
import Products from './Products' 
import Category from './Category' 
import Categories from './Categories' 
import Subcategory from './Subcategory' 
import Shop from './Shop' 
import Single from './Single' 
import Wishlist from './Wishlist' 
import Rating from './Rating'  
import Notification from './Notification' 
import Privacypolicy from './Privacypolicy' 
import Returnpolicy from './Returnpolicy' 
import Shippingpolicy from './Shippingpolicy' 
import Refundpolicy from './Refundpolicy' 
import Cancellationpolicy from './Cancellationpolicy' 
import Terms from './Terms' 
import { userDetailContext } from '../App'; 

export default function RouterPags() {

    var contextData = useContext(userDetailContext);  
    const checkIfLocationStore = () => {
        const shopList = localStorage.getItem('geoLocation') 
        if(shopList !=null) {
            return true 
        }
        else {
            return false;
        }

    }
    return (
        <div>
            <Router>
                <Switch> 
                     <Route exact path="/" component={Index}>
                     {/* {localStorage.getItem('country')&&<Redirect to="/home" />} */}
                    </Route> 
                     <Route path="/shoplist"  component={Shoplist}> 
                     </Route>   

                    <Route path="/home"  component={Home}> 
                     </Route>  
                    <Route path="/about" component={About}>
                     {localStorage.getItem('country')?'':<Redirect to="/" />}
                     </Route>  
                    <Route path="/blog" component={Blog}>
                     {localStorage.getItem('country')?'':<Redirect to="/" />}
                     </Route>  
                    <Route path="/blog_detail" component={Blog_detail}>
                     {localStorage.getItem('country')?'':<Redirect to="/" />}
                     </Route>  
                    <Route path="/contact" component={Contact}>
                     {localStorage.getItem('country')?'':<Redirect to="/" />}
                     </Route>  
                    <Route path="/product" component={Product}>
                     {localStorage.getItem('country')?'':<Redirect to="/" />}
                     </Route>  
                    <Route path="/category" component={Category}>
                     {localStorage.getItem('country')?'':<Redirect to="/" />}
                     </Route> 
                    <Route path="/categories" component={Categories}>
                     {localStorage.getItem('country')?'':<Redirect to="/" />}
                     </Route> 
                    <Route path="/subcategory" component={Subcategory}>
                     {localStorage.getItem('country')?'':<Redirect to="/" />}
                     </Route>  
                    <Route path="/shop" component={Shop}>
                     {localStorage.getItem('country')?'':<Redirect to="/" />}
                     </Route>  
                    <Route path="/products" component={Products}>
                     {localStorage.getItem('country')?'':<Redirect to="/" />}
                     </Route>  
                    <Route path="/single" component={Single}>
                     {localStorage.getItem('country')?'':<Redirect to="/" />}
                     </Route>   
                    <Route path="/not_found" component={Not_found}>
                     {localStorage.getItem('country')?'':<Redirect to="/" />}
                     </Route>  

                    <Route path="/cart" component={Cart}> 
                    {localStorage.getItem('userToken')?'':<Redirect to="/" />}
                    </Route>

                    <Route path="/checkout" component={Checkout}>
                    {localStorage.getItem('userToken')?'':<Redirect to="/" />}
                    </Route>

                    <Route path="/my_address" component={My_address}>
                    {localStorage.getItem('userToken')?'':<Redirect to="/" />}
                    </Route>

                    <Route path="/my_profile" component={My_profile}>
                    {localStorage.getItem('userToken')?'':<Redirect to="/" />}
                    </Route>
                    <Route path="/orderlist" component={Orderlist}>
                    {localStorage.getItem('userToken')?'':<Redirect to="/" />}
                    </Route>
                    
                    <Route path="/orderview" component={Orderview}>
                    {localStorage.getItem('userToken')?'':<Redirect to="/" />}
                    </Route>

                    <Route path="/wishlist" component={Wishlist}>
                    {localStorage.getItem('userToken')?'':<Redirect to="/" />}
                    </Route> 

                    <Route path="/wishlist" component={Wishlist}>
                    {localStorage.getItem('userToken')?'':<Redirect to="/" />}
                    </Route> 

                    <Route path="/rating" component={Rating}>
                    {localStorage.getItem('userToken')?'':<Redirect to="/" />}
                    </Route> 

                    <Route path="/notification" component={Notification}>
                    {localStorage.getItem('userToken')?'':<Redirect to="/" />}
                    </Route>  
                     
                    <Route path="/privacypolicy" component={Privacypolicy}>
                    {localStorage.getItem('country')?'':<Redirect to="/" />}
                    </Route> 

                    <Route path="/returnpolicy" component={Returnpolicy}>
                    {localStorage.getItem('country')?'':<Redirect to="/" />}
                    </Route>

                    <Route path="/terms" component={Terms}>
                    {localStorage.getItem('country')?'':<Redirect to="/" />}
                    </Route>
                    
                    <Route path="/faq" component={Faq}>
                     {localStorage.getItem('country')?'':<Redirect to="/" />}
                     </Route>  

                    <Route path="/refundpolicy" component={Refundpolicy}>
                    {localStorage.getItem('country')?'':<Redirect to="/" />}
                    </Route>

                    <Route path="/cancellationpolicy" component={Cancellationpolicy}>
                    {localStorage.getItem('country')?'':<Redirect to="/" />}
                    </Route>

<Route path="/shippingpolicy" component={Shippingpolicy}>
{localStorage.getItem('country')?'':<Redirect to="/" />}
</Route>
                    

                </Switch>
            </Router>
        </div>
    )
}
