import React, { useState, useRef, useContext, useEffect } from 'react'
import { Link,useLocation } from 'react-router-dom'
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { apiRequestPOST } from '../utils/ApiCall';
import { BASE_URL } from '../utils/Constants';
import { toast } from 'react-toastify';
import { userDetailContext } from '../App';
import Autocomplete from "react-google-autocomplete";
import get from 'lodash.get'
import { useHistory } from "react-router-dom";  
import Geocode from "react-geocode";  
 
export default function Header() {
   const inputEl = useRef(null);
   const onButtonClick =  () => {
      setTimeout(() => {  
       inputEl.current.focus();
      }, 100);
    };
   let history = useHistory();
   var contextData = useContext(userDetailContext);
   const [tab, setTab] = useState('login')
   const [forgotButton, setForgotButton] = useState('false')
   const [registarionButton, setRegistarionButton] = useState('false')
   const [loginButton, setLoginButton] = useState('false')   
   const [ratingButton, setRatingButton] = useState('false')   
   const [geolocationStatus, setGeolocationStatus] = useState();
   const [loadImg, setLoadImg] = React.useState(false)
   const [suggestionValue,setSuggestionValue] = useState([])
   const [inputValue,setInputValue] = useState([])
   const [inputValueProduct,setInputValueProduct] = useState([])
   
   // const [country, setCountry] = useState(contextData.country )
    
   const geoLoaction=contextData.geoLoaction?.split(',')  
   const getlocalNameByCoord = (langititude, longtitude) => {
      
      Geocode.setApiKey("AIzaSyD_ivQdtQK5UuTcspqtw5u5_r27Xbi_f9w");

      Geocode.setLanguage("en");

      Geocode.setRegion("es");

      Geocode.setLocationType("ROOFTOP");

      Geocode.enableDebug();
      {(langititude && longtitude )&&
       
      Geocode.fromLatLng(langititude,longtitude).then(
         (response) => {
            const address = response.plus_code.compound_code;
            console.log("address", response.plus_code.compound_code); 
            localStorage.setItem('geoLocation',address)   
            contextData.setGeoLoaction(address)    
         },
         (error) => {
            console.error(error);
         }
      )
      } 
   }
   
   const getCountry = (val) => { 
      localStorage.setItem('country',val)     
      contextData.setCountry(val)
      document.getElementById('closee').click()
    }

   
   const getLocation = () => {
      if (!navigator.geolocation) {
        setGeolocationStatus('Geolocation is not supported by your browser');
      } else {
        setGeolocationStatus('Search Locating...');
        navigator.geolocation.getCurrentPosition((position) => {
           console.log('position',position);
          setGeolocationStatus("Search Locating...");
         //  setLat(position.coords.latitude);
         //  setLng(position.coords.longitude);
  
         getlocalNameByCoord(position.coords.latitude, position.coords.longitude)
       
         localStorage.setItem('geoLng',position.coords.latitude)     
         localStorage.setItem('geoLat',position.coords.longitude)     
         document.getElementById('closee').click()
         closeNav()     
         apiRequestPOST(`${BASE_URL}app_ctrl/get_geoCoordinate`,{ log: position.coords.longitude,lng:position.coords.latitude }, false)
         .then((response) => { 
            localStorage.setItem('shoplisttemphistory',JSON.stringify(response.query));  
            contextData.setShopList(response.query)
            history.push({
               pathname: '/home', 
               state:{lat: position.coords.latitude,lng:position.coords.longitude}
            })                  
         }, (error) => {
            console.log(error);
         }) 
        }, () => {
          setGeolocationStatus('Location permission not granted');
        });
      }
    }
   const getLocationSearch = () => {
      if (!navigator.geolocation) {
        setGeolocationStatus('Geolocation is not supported by your browser');
      } else {
        setGeolocationStatus('Search Locating...');
        navigator.geolocation.getCurrentPosition((position) => {
           console.log('position',position);
          setGeolocationStatus("Search Locating...");
         //  setLat(position.coords.latitude);
         //  setLng(position.coords.longitude);
  
         getlocalNameByCoord(position.coords.latitude, position.coords.longitude)
       
         localStorage.setItem('geoLng',position.coords.latitude)     
         localStorage.setItem('geoLat',position.coords.longitude)     
         document.getElementById('closee').click()
         closeNav()     
       
         apiRequestPOST(`${BASE_URL}app_ctrl/get_geoCoordinate`,{ log: position.coords.longitude,lng:position.coords.latitude }, false)
         .then((response) => { 
            localStorage.setItem('shoplisttemphistory',JSON.stringify(response.query));  
            contextData.setShopList(response.query)
            history.push({
               pathname: '/', 
               state:{lat: position.coords.latitude,lng:position.coords.longitude}
            })                  
         }, (error) => {
            console.log(error);
         })
            
        }, () => {
          setGeolocationStatus('Location permission not granted');
        });
      }
    }
   
 const openNav=()=>
{
   document.getElementById("mySidenav_input").focus();
   document.getElementById("mySidenav").style.width = "440px"
}
 
const closeNav=()=>
 {
   document.getElementById("mySidenav").style.width = "0"
 }

 
  
 const location = useLocation();
 useEffect(() => {  

   // localStorage.getItem("geoLat")==''&& document.getElementById("currentlocation_cls").click();
   
    setTimeout(() => {
      geolocationSearch.current.focus()  
    }, 3000);
  
      const localGeoLoaction = contextData?.geoLoaction
      // localGeoLoaction==''&&document.getElementById('localGeoLoaction').click() 

      
      const countrySelect = contextData?.country
      countrySelect==''&&document.getElementById('countryBtn').click() 

      const datahistory = contextData?.datahistory
     
      apiRequestPOST(`${BASE_URL}app_ctrl/get_admindata`, '', false)
         .then(async (response) => {
            localStorage.setItem('adminData', JSON.stringify(response));
            await contextData.setAdminData(response);
         }, (error) => {
            console.log(error);
         });
          
        
      apiRequestPOST(`${BASE_URL}app_ctrl/get_businesscategory`,{shopid:'',branchid:''}, false)
      .then(async (response) => {
         localStorage.setItem('businessCategory', JSON.stringify(response));
         await contextData.setBusinessCategory(response);
      }, (error) => {
         console.log(error);
      })
       


      if (localStorage.getItem("userToken") != null && localStorage.getItem("userToken") != '') {
         apiRequestPOST(`${BASE_URL}app_user_ctrl/get_userdata`, '', false)
            .then(async (response) => {
               localStorage.setItem('userName', JSON.stringify(response))
               await contextData.setUsername(response);

            }, (error) => {
               console.log(error);
            });

         apiRequestPOST(`${BASE_URL}app_user_ctrl/user_cart_list`, '', false)
            .then(async (response) => {
               console.log("response", response.branch_id);
               await contextData.setUserCartist(response.user_cart_list);
               localStorage.setItem('userCartist', JSON.stringify(response.user_cart_list))
               localStorage.setItem('userCartiststore_id', JSON.stringify(response.store_id))
               localStorage.setItem('userCartistbranch_id', JSON.stringify(response.branch_id))

               if(localStorage.getItem('datahistory')==null){  
               apiRequestPOST(`${BASE_URL}app_ctrl/datahistory`,{store_id:response.store_id,branch_id:response.branch_id}, false)
               .then(async (response2) => {
                  console.log('response2',response2);
                  await localStorage.setItem('datahistory',JSON.stringify(response2));
                  await contextData.setDataHistory(JSON.stringify(response2));
                  await contextData.setShopDatails(response2.shop_datails);
                  await contextData.setShopIdz(response2.id);
                  await contextData.setBranchId(response2.shop_datails.branch_id);
                   })
               .catch((error) => {
                  console.log(error);
               });
            }

            })
            .catch((error) => {
               console.log(error);
            });
      }


                                                

      apiRequestPOST(`${BASE_URL}app_user_ctrl/favourite_list`, '', false)
      .then(async (response3) => {
         await contextData.setUserFavourite(response3); 
          localStorage.setItem('userFavourite', JSON.stringify(response3))
            })
      .catch((error) => {
         console.log(error);
      });
                                            

      apiRequestPOST(`${BASE_URL}app_user_ctrl/notification_list`, '', false)
      .then(async (response3) => {
         await contextData.setUserNotificvation(response3); 
          localStorage.setItem('userNotificvation', JSON.stringify(response3))
            })
      .catch((error) => {
         console.log(error);
      });


      apiRequestPOST(`${BASE_URL}app_ctrl/get_geoCoordinate`,{ log: '',lng:'' }, false)
      .then((response) => { 
         localStorage.setItem('shoplisttemphistory',JSON.stringify(response.query));  
         contextData.setShopList(response.query) 
      }, (error) => {
         console.log(error);
      }) 

      window.scrollTo({top: 0 }); 
   }, [location])

   
    

   const phoneRegExp = /^!*([0-9]!*){10,14}$/

   const registrationSchema = Yup.object().shape({
      name: Yup.string().required('Name Required'),
      phonenumber: Yup.string().matches(phoneRegExp, 'Phone number is not valid').required('Phone number Required')
         .test('Unique Phone Number', 'Phone Number already in use',
            function (value) {
               if (value >= 10) {
                  return new Promise((resolve, reject) => {
                     apiRequestPOST(`${BASE_URL}app_ctrl/add_registration_phonenumbercheck`, { value: value }, false)
                        .then((res) => {
                           //   console.log(res);
                           if (res == 1) { resolve(true) } else { resolve(false) }
                        })
                        .catch((error) => {
                           resolve(false)
                        })
                  })
               }
            }
         ), 
      email: Yup.string().email('Invalid email')
         // .test('Unique Email', 'Email already in use',
         //    function (value) {
         //       const status = (typeof value == 'string') && value.match(
         //          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
         //       );
         //       if (status) {
         //          return new Promise((resolve, reject) => {
         //             apiRequestPOST(`${BASE_URL}app_ctrl/add_registration_emailcheck`, { value: value }, false)
         //                .then((res) => {
         //                   // console.log(res);
         //                   if (res == 1) { resolve(true) } else { resolve(false) }
         //                })
         //                .catch((error) => {
         //                   resolve(false)
         //                })
         //          })
         //       }
         //    }
         // )
       ,

      password: Yup.string()
         .required("Password is required")
         .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
            "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
          ),

      confirmpassword: Yup.string().when("password", {
         is: val => (val && val.length > 0 ? true : false),
         then: Yup.string().oneOf([Yup.ref("password")], "Both password need to be the same")
      }).required('Confirm Password Required'),
      terms_and_condition: Yup.boolean().oneOf([true], "You must accept the terms and conditions"),
   });

   const loginSchema = Yup.object().shape({
      username: Yup.string().required('Required'),
      password: Yup.string().required('Required'),
   });
   const forgotSchema = Yup.object().shape({
      email: Yup.string().required('Required'), 
   });
 
   const closee_add_stote_rating = useRef(); 
   const geolocationSearch = useRef(); 
   return (
      <React.Fragment>

<>


<input type="button"  style={{display:'none'}}  data-target="#bd-example-modal_searchcountry" data-toggle="modal" id='countryBtn' />
 
<div className="modal fade login-modal-main" id="bd-example-modal_searchcountry" data-backdrop="static" data-keyboard="false">
            <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
            
               <div className="modal-content">
               <button type="button" style={{display:'none'}} data-dismiss="modal" aria-label="Close" id='closee'/> 
                  <div className="modal-body">
                     <div className="login-modal">
                        <div className="row" style={{backgroundColor:'white'}}>
                           <div className="col-lg-6 pad-right-0"  >
                              <div className="login-modal-leftx"  >
                              <center>           <br/><br/><br/>                   
                              <img src={`${BASE_URL}assets/admin/original/${contextData?.admindata?.logo_image}`} alt="logo" style={{width:'50%'}}/>
                              {/* <br/>   <h5 style={{color:'#095c91'}}><b>Multivendor</b></h5> */}
                                  {/* <br/><br/>
                             <h7 ><b>NOW AVAILABLE</b></h7>
                             <br/>  */}
                             {/* <div className="app">
                    <Link ><img src="assets/img/google.png" alt=""/></Link>
                    <Link ><img src="assets/img/apple.png" alt=""/></Link>
                 </div> */}
                              </center>
                              </div>
                           </div>
                           <div className="col-lg-6 pad-left-0">
                             
                              <div className="login-modal-right">

 
                                          <div className="tab-content" style={{marginTop:'41px'}}>
                                             <div className= 'active tab-pane'  id="login" role="tabpanel">
                                              

                  <fieldset className="form-group" style={{marginTop:'21px'}}>
       <label style={{color:'#212529cc',fontSize:'17px'}}><b>
          <img src="assets/img/pin.png" alt="logo"  style={{width:'17px',marginRight:'5px'}}/>
                                                    Select Your Coutry</b></label><br/>
                                                 
                                              
                  </fieldset>
                                     
                                    
                  <fieldset className="form-group" style={{marginTop:'21px'}}>    
 <button  style={{width:'120px',backgroundColor:'#095c91',color:'#fff',fontSize:'18px',borderRadius:'17px',borderWidth:'0px'}}
      className="bg-sky-600 hover:bg-sky-800 py-2 px-4 rounded-md " 
        onClick={ () => getCountry('INDIA')}
      type="button"  
      >
      INDIA
      </button> <button  style={{width:'120px',backgroundColor:'#095c91',color:'#fff',fontSize:'18px',borderRadius:'17px',borderWidth:'0px'}}
      className="bg-sky-600 hover:bg-sky-800 py-2 px-4 rounded-md " 
        onClick={ () => getCountry('ABROAD')}
      type="button"  
      >
      ABROAD 
      </button>  </fieldset>
   
      <label style={{color:'#212529cc',fontSize:'12px'}}><b>You Can Select Your Country And Purchase Our Product</b></label>
                                    
                                             </div>
                                          </div> 

                                 <div className="clearfix"></div>
                                 
                              </div>

                           </div>
                           <div style={{backgroundColor:'#e1e2e3',width:"100%",bottom:0,height:'14px'}}></div> 
                            </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>

{/* <input type="button"  style={{display:'none'}}  data-target="#bd-example-modal_searchlocation" data-toggle="modal" id='localGeoLoactionx' /> */}
 
<div className="modal fade login-modal-main" id="bd-example-modal_searchlocation" data-backdrop="static" data-keyboard="false">
            <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
            
               <div className="modal-content">
               <button type="button" style={{display:'none'}} data-dismiss="modal" aria-label="Close" id='closee'/> 
                  <div className="modal-body">
                     <div className="login-modal">
                        <div className="row" style={{backgroundColor:'white'}}>
                           <div className="col-lg-6 pad-right-0"  >
                              <div className="login-modal-leftx"  >
                              <center>           <br/><br/><br/>                   
                              <img src={`${BASE_URL}assets/admin/original/${contextData?.admindata?.logo_image}`} alt="logo" style={{width:'50%'}}/>
                              {/* <br/>   <h5 style={{color:'#095c91'}}><b>Multivendor</b></h5> */}
                                  {/* <br/><br/>
                             <h7 ><b>NOW AVAILABLE</b></h7>
                             <br/>  */}
                             {/* <div className="app">
                    <Link ><img src="assets/img/google.png" alt=""/></Link>
                    <Link ><img src="assets/img/apple.png" alt=""/></Link>
                 </div> */}
                              </center>
                              </div>
                           </div>
                           <div className="col-lg-6 pad-left-0">
                             
                              <div className="login-modal-right">

 
                                          <div className="tab-content" style={{marginTop:'41px'}}>
                                             <div className= 'active tab-pane'  id="login" role="tabpanel">
                                                <h1 className="heading-design-h5">Search Your Location?</h1>

                                                <fieldset className="form-group" style={{marginTop:'21px'}}>
                                                   <label style={{color:'#212529cc',fontSize:'17px'}}><b> <img src="assets/img/pin.png" alt="logo"  style={{width:'17px',marginRight:'5px'}}/>
                                                    Please select your location</b></label><br/>
                                                 
                                             
                                                <Autocomplete  style={{borderColor:'#000'}}
                 placeholder='Search and locate places...'  ref={geolocationSearch}
                 className='form-control'
                  apiKey={'AIzaSyD_ivQdtQK5UuTcspqtw5u5_r27Xbi_f9w'}  
                  onPlaceSelected={(place) => { 
                     setLoadImg(true)
                     const geoCoordinate = get(place,'geometry.location',true) 
                     const placeName = get(place,'formatted_address',true)
                     localStorage.setItem('geoLocation',placeName)     
                     localStorage.setItem('geoLng',geoCoordinate.lng())     
                     localStorage.setItem('geoLat',geoCoordinate.lat())     
                     contextData.setGeoLoaction(placeName)
                     if(geoCoordinate) {
                     console.log("parse code",geoCoordinate.lat());
                     console.log("parse code",geoCoordinate.lng());
                      
                         apiRequestPOST(`${BASE_URL}app_ctrl/get_geoCoordinate`,{log:geoCoordinate.lng(),lng:geoCoordinate.lat()}, false)
                         .then(async(response) => {  
                           await  localStorage.setItem('shoplisttemphistory',JSON.stringify(response.query))  
                           await contextData.setShopList(response.query)     
                        document.getElementById('closee').click()
                        history.push({ pathname: "/home"}) 
                                 }, (error) => {
                           console.log(error);
                        })  
                     }

                  }}
               />
                  </fieldset>
                  { loadImg&&<center><img src="assets/img/load.gif" alt="logo"  style={{width:'62px'}}/></center>}
                                    
                                    
                  <center style={{marginTop:'21px'}}>---OR---</center>
 <button  style={{marginTop:'21px',width:'300px',backgroundColor:'#095c91',color:'#fff',fontSize:'18px',borderRadius:'17px',borderWidth:'0px'}}
      className="bg-sky-600 hover:bg-sky-800 py-2 px-4 rounded-md " id="localGeoLoaction"
      // id="currentlocation_cls"
      onClick={ getLocation}
      type="button"  
      >
      Current Location
      </button><br/>
      <center style={{marginTop:'12px',color:'#000',borderColor:'#fff'}}>{geolocationStatus}</center>
   
                                    
                                             </div>
                                          </div> 

                                 <div className="clearfix"></div>
                                 
                              </div>

                           </div>
                           <div style={{backgroundColor:'#e1e2e3',width:"100%",bottom:0,height:'14px'}}></div> 
                            </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>

 
         <div className="modal fade login-modal-main" id="bd-example-modal_start_selling" data-backdrop="static" data-keyboard="false">
            <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
            
               <div className="modal-content">
             
                  <div className="modal-body">
                     <div className="login-modal">
                        <div className="row" style={{backgroundColor:'white'}}>
                           <div className="col-lg-6 pad-right-0"  >
                              <div className="login-modal-leftx"  >
                              <center>           <br/><br/><br/>                   
                              <img src={`${BASE_URL}assets/admin/original/${contextData?.admindata?.logo_image}`} alt="logo" style={{width:'50%'}}/>
                              {/* <br/>   <h5 style={{color:'#095c91'}}><b>Multivendor</b></h5> */}
                                 
                              </center>
                              </div>
                           </div>
                           <div className="col-lg-6 pad-left-0">
                             
                              <div className="login-modal-right">

 
                                          <div className="tab-content" style={{marginTop:'41px'}}>
                                             <div className= 'active tab-pane'  id="login" role="tabpanel">
                                                <h1 className="heading-design-h5">Start Selling</h1>

                                                <p style={{textAlign:'center'}}>Please Contact Admin</p>
   
                                              <center>
                                                <button className="btn btn-secondary" type="button"  data-dismiss="modal" aria-label="Close" id='closee'> Ok</button>
                                                </center>
                                             </div>
                                          </div> 

                                 <div className="clearfix"></div>
                                 
                              </div>

                           </div>
                           <div style={{backgroundColor:'#e1e2e3',width:"100%",bottom:0,height:'14px'}}></div> 
                            </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>


         <div className="modal fade login-modal-main " id="bd-example-modal_store_rating"  data-backdrop="static" data-keyboard="false">
            <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
               <div className="modal-content">
                  <div className="modal-body ">
                     <div className="login-modal">
                        <div className="row"> 
                           <div className="col-lg-12">
                              <button type="button" className="close close-top-right" data-dismiss="modal" aria-label="Close" id='closee_add_stote_rating' ref={closee_add_stote_rating}>
                                 <span aria-hidden="true"><i className="mdi mdi-close" style={{color:'#fff'}}></i></span>
                                 <span className="sr-only">Close</span>
                              </button>

                              
 
                              <Formik
                                    initialValues={{
                                       rating: '',  
                                       store_id: JSON.parse(localStorage.getItem('userCartiststore_id')), 
                                    }}  
                                    onSubmit={values => {
                                       setRatingButton({ val: "disabled" })
                                       apiRequestPOST(`${BASE_URL}app_user_ctrl/add_stote_rating`, values, false)
                                          .then((response) => {
                                             setRatingButton({ val: "" }) 
                                             closee_add_stote_rating.current.click()  
                                                  history.push({
                                                   pathname: "/home",
                                                   state: ''
                                                }) 

                                          }, (error) => {
                                             console.log(error);
                                          });
 
                                    }}
                                 >
                                    {({ errors, touched }) => (
                                       <Form>
                           
 <div className="rating-css">
   <div>Store rating</div>
   <div className="star-icon">
     <Field type="radio" name="rating" id="rating11" value="1"/>
     <label htmlFor="rating11" className="fa fa-star"></label>
     <Field type="radio" name="rating" id="rating22" value="2"/>
     <label htmlFor="rating22" className="fa fa-star"></label>
     <Field type="radio" name="rating" id="rating33" value="3"/>
     <label htmlFor="rating33" className="fa fa-star"></label>
     <Field type="radio" name="rating" id="rating44" value="4"/>
     <label htmlFor="rating44" className="fa fa-star"></label>
     <Field type="radio" name="rating" id="rating55" value="5"/>
     <label htmlFor="rating55" className="fa fa-star"></label>
     <div className="row"><div className="col-lg-3"></div>
   <div className="col-lg-3"> <button type="button" className="btn btn-lg btn-danger   btn-block" onClick={() => 
      {  history.push({  pathname: "/home",  state: '' })
      document.getElementById('closee_add_stote_rating').click()}}>Cancel</button></div>
   <div className="col-lg-1"></div>
    <div className="col-lg-3"> <button type="submit"  disabled={(ratingButton.val) ? "disabled" : ""} className="btn btn-lg btn-success btn-block"  >Post</button></div>
   </div>
   </div>
    </div> 
 
    </Form>
                                    )}
                                 </Formik>



                           
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>



         <div className="modal fade login-modal-main" id="bd-example-modal_diffrent_branch">
            <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
               <div className="modal-content">
                  <div className="modal-body">
                     <div className="login-modal">
                        <div className="row">
                           <div className="col-lg-2 pad-left-0"></div>
                           <div className="col-lg-8 pad-left-0">
                              <button type="button" style={{visibility:'hidden'}} className="close close-top-right" data-dismiss="modal" aria-label="Close" id='closee'>
                                 <span aria-hidden="true"><i className="mdi mdi-close"></i></span>
                                 <span className="sr-only">Close</span>
                              </button>

                              <center><h4>Items already in cart</h4>  </center>
                              <p style={{ color: '#535665' }}>Your cart contains items from other restaurant. Would you like to reset your cart for adding items from this restaurant?</p>

                              <div className="row">
                                 <div className="col-lg-6"> <button type="button" style={{ borderWidth: 1, borderColor: '#60b246' }} className="btn btn-lg   btn-block" onClick={() => document.getElementById('closee').click()}>No</button></div>
                                 <div className="col-lg-6"> <button type="submit" className="btn btn-lg btn-success btn-block"
                                    onClick={() =>
                                       apiRequestPOST(`${BASE_URL}app_user_ctrl/product_add_to_cart_diffrent_branch`, JSON.parse(localStorage.getItem('diffrent_branch_userCartist')), false)
                                          .then((response) => {

                                                apiRequestPOST(`${BASE_URL}app_ctrl/datahistory`, JSON.parse(localStorage.getItem('diffrent_branch_userCartist')), false)
                                                .then(async (response2) => {
                                                   await localStorage.setItem('datahistory',JSON.stringify(response2));
                                                   await contextData.setDataHistory(JSON.stringify(response2));
                                                   await contextData.setShopDatails(response2.shop_datails);
                                                   await contextData.setShopIdz(response2.id);
                                                   await contextData.setBranchId(response2.shop_datails.branch_id);
                                                   })
                                                .catch((error) => {
                                                   console.log(error);
                                                });

                                             apiRequestPOST(`${BASE_URL}app_user_ctrl/user_cart_list`, '', false)
                                                .then(async (response1) => {
                                                   await contextData.setUserCartist(response1.user_cart_list);
                                                   localStorage.setItem('userCartist', JSON.stringify(response1.user_cart_list))
                                                   localStorage.setItem('userCartiststore_id', JSON.stringify(response1.store_id))
                                                   localStorage.setItem('userCartistbranch_id', JSON.stringify(response1.branch_id))
                                                })
                                                .catch((error) => {
                                                   console.log(error);
                                                });
                                             
                                                document.getElementById('closee').click()
                                             if (response == 0) {
                                                toast.error('Failed, Try again', {
                                                   position: "top-center",
                                                   autoClose: 5000,
                                                   hideProgressBar: false,
                                                   closeOnClick: true,
                                                   pauseOnHover: true,
                                                   draggable: true,
                                                   progress: undefined,
                                                });
                                             }
                                             if (response == 1) {

                                                toast.success('Updated cart', {
                                                   position: "top-center",
                                                   autoClose: 5000,
                                                   hideProgressBar: false,
                                                   closeOnClick: true,
                                                   pauseOnHover: true,
                                                   draggable: true,
                                                   progress: undefined,
                                                   });
                                             }
                                             if (response == 2) {
                                                toast.error('Not enough stock !!!', {
                                                   position: "top-center",
                                                   autoClose: 5000,
                                                   hideProgressBar: false,
                                                   closeOnClick: true,
                                                   pauseOnHover: true,
                                                   draggable: true,
                                                   progress: undefined,
                                                });
                                             }
                                             if (response == 3) {
                                                toast.error('Invalid Quantity !!!', {
                                                   position: "top-center",
                                                   autoClose: 5000,
                                                   hideProgressBar: false,
                                                   closeOnClick: true,
                                                   pauseOnHover: true,
                                                   draggable: true,
                                                   progress: undefined,
                                                });
                                             }
                                          }, (error) => {
                                             console.log(error);
                                          })
                                    }
                                 >Yes, start afresh</button></div>
                              </div>


                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>




         <div className="modal fade login-modal-main" id="bd-example-modal">
            <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
               <div className="modal-content">
                  <div className="modal-body">
                     <div className="login-modal">
                        <div className="row" style={{backgroundColor:'white'}}>
                        <div className="col-lg-6 pad-right-0"  >
                              <div className="login-modal-leftx"  >
                                 <center>           <br/><br/><br/>                   
                              <img src={`${BASE_URL}assets/admin/original/${contextData?.admindata?.logo_image}`} alt="logo" style={{width:'50%'}}/>
                              {/* <br/>   <h5 style={{color:'#095c91'}}><b>Multivendor</b></h5> */}
                                  {/* <br/><br/>
                             <h7 ><b>NOW AVAILABLE</b></h7>
                             <br/>  */}
                             {/* <div className="app">
                    <Link ><img src="assets/img/google.png" alt=""/></Link>
                    <Link ><img src="assets/img/apple.png" alt=""/></Link>
                 </div> */}
                              </center>
                              </div>
                           </div>
                           <div className="col-lg-6 pad-left-0">
                              <button type="button" className="close close-top-right" data-dismiss="modal" aria-label="Close" id='closee'>
                                 <span aria-hidden="true"><i className="mdi mdi-close"></i></span>
                                 <span className="sr-only">Close</span>
                              </button>



                              <div className="login-modal-right">



                                 <Formik
                                    initialValues={{
                                       username: '',
                                       password: '',
                                    }}
                                    validationSchema={loginSchema}
                                    onSubmit={values => {
                                       setLoginButton({ val: "disabled" })
                                       apiRequestPOST(`${BASE_URL}app_ctrl/login_check`, values, false)
                                          .then((response) => {
                                             if (response != 1) {
                                                localStorage.setItem('userToken', response.token)
                                                document.getElementById('closee').click()
                                                toast.success('Login Successfully', {
                                                   position: "top-center",
                                                   autoClose: 5000,
                                                   hideProgressBar: false,
                                                   closeOnClick: true,
                                                   pauseOnHover: true,
                                                   draggable: true,
                                                   progress: undefined,
                                                });
                                                window.location.reload(false);
                                                // history.push({
                                                //    pathname: "/",
                                                //    state: ''
                                                // })
                                             } else {
                                                setLoginButton({ val: "" })
                                                document.getElementById('username_id').focus();
                                                toast.error('Login Failed', {
                                                   position: "top-center",
                                                   autoClose: 5000,
                                                   hideProgressBar: false,
                                                   closeOnClick: true,
                                                   pauseOnHover: true,
                                                   draggable: true,
                                                   progress: undefined,
                                                });
                                             }

                                          }, (error) => {
                                             console.log(error);
                                          });

                                       // console.log(values);
                                    }}
                                 >
                                    {({ errors, touched }) => (
                                       <Form>
                                          <div className="tab-content">
                                             <div className={(tab == 'login') ? 'active tab-pane' : 'tab-pane'} id="login" role="tabpanel">
                                                <h5 className="heading-design-h5">Login to your account</h5>
                                                <fieldset className="form-group">
                                                   <label>Enter Email/Mobile number</label>
                                                   <Field type="text" className="form-control" id='username_id' name='username' placeholder="Email/Mobile number" />
                                                   {errors.username && touched.username ? (<div style={{ color: 'red' }}>{errors.username}</div>) : null}
                                                </fieldset>
                                                <fieldset className="form-group">
                                                   <label>Enter Password</label>
                                                   <Field type="password" className="form-control" name='password' placeholder="********" />
                                                   {errors.password && touched.password ? (<div style={{ color: 'red' }}>{errors.password}</div>) : null}
                                                </fieldset>
                                                <fieldset className="form-group">
                                                   <button type="submit" style={{backgroundColor:'#095c91',color:'#fff'}} disabled={(loginButton.val) ? "disabled" : ""} className="btn btn-lg btn-secondary_block btn-block">Enter to your account</button>
                                                </fieldset>


                                          <div className="login-with-sites text-right">
                                       
                                          <Link id="login_btn" style={{color:'#095c91'}}
                                             onClick={() => {
                                                setTab('forgot')
                                             }}
                                             className={(tab == 'forgot') ? 'active nav-link' : 'nav-link'}  >
                                             <p> Forgot Password</p>
                                                </Link>
                                          </div>

                                                {/* <div className="login-with-sites text-center">
                                          <p>or Login with your social profile:</p>
                                          <button className="btn-facebook login-icons btn-lg"><i className="mdi mdi-facebook"></i> Facebook</button>
                                          <button className="btn-google login-icons btn-lg"><i className="mdi mdi-google"></i> Google</button>
                                          <button className="btn-twitter login-icons btn-lg"><i className="mdi mdi-twitter"></i> Twitter</button>
                                       </div> */}
                                                {/* <div className="custom-control custom-checkbox">
                                          <Field type="checkbox" className="custom-control-input" id="customCheck1"/>
                                          <label className="custom-control-label" htmlFor="customCheck1">Remember me</label>
                                       </div> */}
                                             </div>
                                          </div>
                                       </Form>
                                    )}
                                 </Formik>
                                 {(tab == 'register' ) &&
                                    <Formik
                                       initialValues={{
                                          name: '',
                                          phonenumber: '',
                                          email: '',
                                          password: '',
                                          confirmpassword: '',
                                          terms_and_condition: false,
                                       }}
                                       validationSchema={registrationSchema}
                                       onSubmit={values => {
                                          setRegistarionButton({ val: "disabled" })
                                          apiRequestPOST(`${BASE_URL}app_ctrl/add_registration`, values, false)
                                             .then((response) => {
                                                if (response == 1) {
                                                   document.getElementById('closee').click()
                                                   setRegistarionButton({ val: "" })
                                                   toast.success('Registered Successfully', {
                                                      position: "top-center",
                                                      autoClose: 5000,
                                                      hideProgressBar: false,
                                                      closeOnClick: true,
                                                      pauseOnHover: true,
                                                      draggable: true,
                                                      progress: undefined,
                                                   });
                                                } else {
                                                   toast.error('Registered Failed', {
                                                      position: "top-center",
                                                      autoClose: 5000,
                                                      hideProgressBar: false,
                                                      closeOnClick: true,
                                                      pauseOnHover: true,
                                                      draggable: true,
                                                      progress: undefined,
                                                   });
                                                }

                                             }, (error) => {
                                                console.log(error);
                                             }).finally(() => {
                                                setRegistarionButton({ val: "" })
                                             });

                                          // console.log(values);
                                       }}
                                    >
                                       {({ errors, touched }) => (
                                          <Form>

                                             <div className="tab-content">
                                                <div className={(tab != 'login') ? 'active tab-pane' : 'tab-pane'} id="register" role="tabpanel">
                                                   <h5 className="heading-design-h5">Register Now!</h5>
                                                   <fieldset className="form-group">
                                                      <label>Enter Name</label>
                                                      <Field type="text" className="form-control" name='name' placeholder="Your Name" />
                                                      {errors.name && touched.name ? (<div style={{ color: 'red' }}>{errors.name}</div>) : null}
                                                   </fieldset>
                                                   <fieldset className="form-group">
                                                      <label>Enter Mobile number</label>
                                                      <Field type="text" className="form-control" name='phonenumber' placeholder="Mobile number" />
                                                      {errors.phonenumber && touched.phonenumber ? (<div style={{ color: 'red' }}>{errors.phonenumber}</div>) : null}
                                                   </fieldset>
                                                   <fieldset className="form-group">
                                                      <label>Enter Email </label>
                                                      <Field type="text" className="form-control" name='email' placeholder="Email" />
                                                      {errors.email && touched.email ? (<div style={{ color: 'red' }}>{errors.email}</div>) : null}
                                                   </fieldset>
                                                   <fieldset className="form-group">
                                                      <label>Enter Password</label>
                                                      <Field type="password" className="form-control" name='password' placeholder="********" />
                                                      {errors.password && touched.password ? (<div style={{ color: 'red' }}>{errors.password}</div>) : null}
                                                   </fieldset>
                                                   <fieldset className="form-group">
                                                      <label>Enter Confirm Password </label>
                                                      <Field type="password" className="form-control" name='confirmpassword' placeholder="********" />
                                                      {errors.confirmpassword && touched.confirmpassword ? (<div style={{ color: 'red' }}>{errors.confirmpassword}</div>) : null}
                                                   </fieldset>
                                                   <div className="custom-control custom-checkbox">
                                                      <Field type="checkbox" className="custom-control-input" id="customCheck2" name="terms_and_condition" />
                                                      <label className="custom-control-label" htmlFor="customCheck2">I Agree with <Link >Term and Conditions</Link></label>
                                                      {errors.terms_and_condition && touched.terms_and_condition ? (<div style={{ color: 'red' }}>{errors.terms_and_condition}</div>) : null}
                                                   </div>
                                                   <fieldset className="form-group">
                                                      <button type="submit" style={{backgroundColor:'#095c91',color:'#fff'}} disabled={(registarionButton.val) ? "disabled" : ""} className="btn btn-lg btn-secondary_block btn-block">Create Your Account</button>
                                                   </fieldset>

                                                </div>
                                             </div>
                                          </Form>
                                       )}
                                    </Formik>
                                 }
                                   {(tab == 'forgot' ) &&
                                    <Formik
                                       initialValues={{ 
                                          email: '', 
                                       }}
                                       validationSchema={forgotSchema}
                                       onSubmit={values => {
                                          setForgotButton({ val: "disabled" })
                                          apiRequestPOST(`${BASE_URL}app_ctrl/forgot`, values, false)
                                             .then((response) => {
                                                if (response == 1) {
                                                   document.getElementById('closee').click()
                                                   setForgotButton({ val: "" })
                                                   toast.success('Please Check Email', {
                                                      position: "top-center",
                                                      autoClose: 5000,
                                                      hideProgressBar: false,
                                                      closeOnClick: true,
                                                      pauseOnHover: true,
                                                      draggable: true,
                                                      progress: undefined,
                                                   });
                                                } else {
                                                   toast.error('Process Failed', {
                                                      position: "top-center",
                                                      autoClose: 5000,
                                                      hideProgressBar: false,
                                                      closeOnClick: true,
                                                      pauseOnHover: true,
                                                      draggable: true,
                                                      progress: undefined,
                                                   });
                                                }

                                             }, (error) => {
                                                console.log(error);
                                             }).finally(() => {
                                                setForgotButton({ val: "" })
                                             });

                                          // console.log(values);
                                       }}
                                    >
                                       {({ errors, touched }) => (
                                          <Form>

                                             <div className="tab-content">
                                                <div className={(tab != 'login') ? 'active tab-pane' : 'tab-pane'} id="register" role="tabpanel">
                                                   <h5 className="heading-design-h5">Forgot Password!</h5>
                                                 
                                                   <fieldset className="form-group">
                                                      <label>Enter Email </label>
                                                      <Field type="text" className="form-control" name='email' placeholder="Email" />
                                                      {errors.email && touched.email ? (<div style={{ color: 'red' }}>{errors.email}</div>) : null}
                                                   </fieldset> 
                                                   <fieldset className="form-group">
                                                      <button type="submit" style={{backgroundColor:'#095c91',color:'#fff'}} disabled={(forgotButton.val) ? "disabled" : ""} className="btn btn-lg btn-secondary_block btn-block">Submit</button>
                                                   </fieldset>

                                                </div>
                                             </div>
                                          </Form>
                                       )}
                                    </Formik>
                                 }

                                 <div className="clearfix"></div>
                                 <div className="text-center login-footer-tab">
                                    <ul className="nav nav-tabs" role="tablist">
                                       <li className="nav-item">
                                          <Link id="login_btn" style={{color:'#095c91'}}
                                             onClick={() => {
                                                setTab('login')
                                             }}
                                             className={(tab == 'login') ? 'active nav-link' : 'nav-link'}  ><i className="mdi mdi-lock"></i> LOGIN</Link>
                                       </li>
                                       <li className="nav-item">
                                          <Link id="register_btn" style={{color:'#095c91'}}
                                             onClick={() => {
                                                setTab('register')
                                             }}
                                             className={(tab == 'register') ? 'active nav-link' : 'nav-link'}  ><i className="mdi mdi-pencil"></i> REGISTER</Link>
                                       </li>

                                    </ul>
                                 </div>
                                 <div className="clearfix"></div>
                              </div>

                           </div>
                           {/* <div style={{backgroundColor:'#e1e2e3',width:"100%",bottom:0,height:'14px'}}></div>  */}

                        </div> 
                   
                   
                    </div>
                  </div>
               </div>
            </div>
         </div>



 <nav className="navbar navbar-light navbar-expand-lg bg-dark bg-faded osahan-menu">
  <div className="container-fluid">
    <Link className="navbar-brand" to="/">
      <img src={`${BASE_URL}assets/admin/original/${contextData?.admindata?.logo_image}`} alt="logo" className='logo_cls' />
    </Link>
    <Link className="location-top" data-target="#bd-example-modal_searchcountry" data-toggle="modal">
      <i className="mdi mdi-map-marker-circle" aria-hidden="true"></i>
      {
        contextData?.country !== '' ?
          contextData?.country.toUpperCase()
          : 'Select Location'
      }
    </Link>
    <button className="navbar-toggler navbar-toggler-white" type="button" data-toggle="collapse" data-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="navbar-collapse" id="navbarNavDropdown">
      <div className="navbar-nav mr-auto mt-2 mt-lg-0 margin-auto top-categories-search-main">
        <div className="top-categories-search">
          <div className="input-group">
            <input
              className="form-control"
              placeholder="Search Product Or Store"
              aria-label="Search Products in Your City"
              type="text"
              onChange={e => {
                setInputValueProduct(e.target.value);
                e.target.value.length === 0 && setSuggestionValue([]);
                apiRequestPOST(`${BASE_URL}app_ctrl/get_search_product_header`, { search: e.target.value, log: JSON.parse(localStorage.getItem("geoLng")), lng: JSON.parse(localStorage.getItem("geoLat")) }, false)
                  .then((response) => {
                    setSuggestionValue(response);
                  }, (error) => {
                    console.log(error);
                  });
              }}
              onBlur={() => {
                setTimeout(function () {
                  setInputValue('');
                  setInputValueProduct('');
                }, 500);
              }}
            />
            <span className="input-group-btn">
              <button className="btn btn-secondary" type="button">
                <i className="mdi mdi-file-find"></i> Search
              </button>
            </span>

            {(inputValue.length > 0 || inputValueProduct.length > 0) &&
              <div className="col-md-12" style={{ zIndex: 9, marginTop: '5px', marginLeft: '-15px' }}>
                <div className="card card-body cart-table" style={{ maxHeight: '340px', width: '92%' }}>
                  <div className="table-responsive">
                    <table className="table cart_summary">
                      <tbody>
                        {
                          suggestionValue.length > 0 && suggestionValue.map(item => {
                            return (
                              <tr style={{ cursor: 'pointer' }}
                                onClick={async () => {
                                  history.push({
                                    pathname: "/products",
                                    state: { search: item.name }
                                  });
                                }}>
                                <td className="cart_product" style={{ width: '100px' }}>
                                  <img className="img-fluid" src={`${BASE_URL}assets/products/original/${item.pic1}`} alt="products" />
                                </td>
                                <td className="cart_description">
                                  <h4 className="product-name">{item.name}</h4>
                                  <h6><strong><span className="mdi mdi-approval"></span> {item.category_name}</strong></h6>
                                </td>
                              </tr>
                            );
                          })
                        }
                        {
                          suggestionValue.length === 0 && inputValue.length > 0 &&
                          <tr>
                            <td colSpan={2}>
                              <center>No Result Found</center>
                            </td>
                          </tr>
                        }
                      </tbody>
                    </table>
                    <br />
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
      <div className="my-2 my-lg-0">
        <ul className="list-inline main-nav-right">
        <li className="list-inline-item">
  {
    (localStorage.getItem("userToken") == null) ?
      <>
        <Link href="#" data-target="#bd-example-modal" data-toggle="modal" className="btn btn-link">
          <i className="mdi mdi-lock" onClick={() => { setTab('login') }}></i> Login/Sign Up
        </Link>
      </>
      :
      <>
        <Link to="/my_profile" className="text-white ml-3 mr-3">
          <i className="mdi mdi-account"></i>
          {contextData?.username?.name?.toUpperCase()}
        </Link>
        <span style={{ display: 'none' }} data-target="#bd-example-modal_diffrent_branch" data-toggle="modal" id='diffrent_branch' className="btn btn-link"></span>
        <span style={{ display: 'none' }} data-target="#bd-example-modal_store_rating" data-toggle="modal" id='store_rating' className="btn btn-link"> </span>
      </>
  }
</li>

          <li className="list-inline-item cart-btn">
            {
              (localStorage.getItem("userToken") == null) ?
                <Link
                  data-toggle="offcanvas-block"
                  onClick={() => {
                    document.body.className = "toggled";
                  }}
                  className="btn btn-link border-none"
                >
                  <img src='assets/img/selected product.png' style={{ width: '41px' }} />
                  <small className="cart-value">0</small>
                  <span style={{ fontSize: '14px;' }}>&nbsp;0.00</span>
                </Link>
                :
                <Link
                  data-toggle="offcanvas-block"
                  onClick={() => {
                    document.body.className = "toggled";
                  }}
                  className="btn btn-link border-none"
                >
                  <span style={{ fontSize: '18px' }}>Selected Product</span> <br />
                  <img src='assets/img/shopping-cart.svg' style={{ width: '32px' }} />
                  <small className="cart-value">
                    {contextData?.usercartlist?.length}
                  </small>
                  <span style={{ fontSize: '14px;' }}>&nbsp;{contextData?.usercartlist?.length?.toFixed(2)}</span>
                </Link>
            }
          </li>
        </ul>
      </div>
    </div>
  </div>
</nav>

 
      <nav className="navbar navbar-expand-lg navbar-light osahan-menu-2 pad-none-mobile">
         <div className="container-fluid">
            <div className="collapse navbar-collapse" id="navbarText" style={{width:'100%'}}>
               <ul className="navbar-nav mr-auto mt-2 mt-lg-0 margin-auto" style={{overflowX:'scroll1',scrollbarWidth:'none'}}>
               
               <li className="nav-item">
                           <Link className="nav-link shop" to="/" style={{whiteSpace:'nowrap'}}>
                           <img src='assets/img/home.png' alt="icon"  style={{width:'41px'}}/><br/>  
                            Home
                            </Link>
                        </li>
                  {/* {     contextData?.shopDatails?.store_name&& <li className="nav-item">
                           <Link className="nav-link shop" to="/home" style={{whiteSpace:'nowrap'}}>
                            <span className="mdi mdi-store"></span>{contextData?.shopDatails?.store_name}
                            </Link>
                        </li> }  */}
                        
  {/* {JSON.stringify(contextData?.businessCategory)} */}
  {  contextData?.businessCategory&&contextData?.businessCategory.map((item,key) => {
                        return ( 
                           <center>
                           <li key={key} className="nav-item dropdown">
                           <Link className="nav-link dropdown-toggle"  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                           to={   {   pathname: '/categories',  search: `?store_typeId=${item.store_type_id}&store_type=${item.store_type}`,
                           state: { store_typeId: item.store_type_id, store_type: item.store_type }
                                  }
                            }  
                           >
                       <img src={`${BASE_URL}assets/storetypes/original/${item.store_type_icon}`} alt="icon"  style={{width:'41px'}}/><br/>  
                            {item.store_type} 
                           </Link>
                           <div className="dropdown-menu" >
                            
                     {
                            item && item?.cats?.map(subItm => {
                              return (
                                 <Link className="dropdown-item"
                                 to={   {   pathname: '/category',   search: `?categoryId=${subItm.category_id}&categoryName=${subItm.category_name}`,
                                   state: { categoryId: subItm.category_id, categoryName: subItm.category_name }
                                          }
                                    } 
                                 //  onClick={() =>
                                 //    history.push({
                                 //       pathname: "/category",
                                 //       state: { categoryId: subItm.category_id, categoryName: subItm.category_name }
                                 //    })
                                 // }  
                                ><i className="mdi mdi-chevron-right" aria-hidden="true"></i> {subItm.category_name}</Link>
                                 )
                              })
                           }  
                           </div>
                           </li>
                           </center> 
                           )
                        })  
   } 
  
                  {/* {    contextData?.businessCategory&&contextData?.businessCategory.bcat.map((item,key) => {
                        return ( 
                          
                           <li key={key} className="nav-item dropdown">
                     <Link className="nav-link dropdown-toggle"  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                     onClick={() =>
                        history.push({
                           pathname: "/category",
                           state: { categoryId: item.category_id, categoryName: item.category_name }
                        })
                     } 
                     >
                    <img src={`${BASE_URL}assets/storetypes/original/${item.store_type_icon}`} alt="icon"  style={{width:'23px'}}/>&nbsp;
                      {item.store_type} 
                     </Link>
                     <div className="dropdown-menu" >
                     {
                            item && item.subs.map(subItm => {
                              return (
                        <Link className="dropdown-item" onClick={() =>
                           history.push({
                              pathname: "/subcategory",
                              state: { categoryId: item.category_id, categoryName: item.category_name,subCategoryId: subItm.subcategory_id,subCategoryName: subItm.subcategory_name }
                           })
                        }  
                       ><i className="mdi mdi-chevron-right" aria-hidden="true"></i> {subItm.subcategory_name}</Link>
                        )
                                       })
                                    }  </div>
                     </li> 
                     )
                     })  
}  */}
                
                

               </ul>
            </div>
         </div>
      </nav>
      </>
      </React.Fragment>
   )
}
