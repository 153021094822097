import React,{useState,useEffect,useContext,useRef} from 'react'
import { Link,useLocation } from 'react-router-dom' 
import Autocomplete from "react-google-autocomplete";
import { useHistory } from "react-router-dom";
import {apiRequestPOST} from '../utils/ApiCall'
import get from 'lodash.get' 
import { BASE_URL } from '../utils/Constants'; 
import { userDetailContext } from '../App';
import Header from './Header'
import Footer from './Footer' 
import { toast } from 'react-toastify'
import { ExternalLink } from 'react-external-link';
export default function Index() { 
    
   
    const contextData = useContext(userDetailContext) 
   const [shopcategory, setShopCategory] = useState([]);
   let history = useHistory(); 
   const [shopproduct, setShopProduct] = useState([]);
   const [storearrayVal, setStorearrayVal] = useState([]); 
   const [emptyCheck, setemptyCheck] = useState(0);
   const location = useLocation();

  useEffect(async()=> {   

      apiRequestPOST(`${BASE_URL}app_ctrl/get_homecategory`,'', false)
      .then((response) => {
         setShopCategory(response) 
      }, (error) => {
         console.log(error);
      }); 

      const storearray = [] 
      await  contextData?.shoplist&&contextData?.shoplist.map(item => {
         storearray.push(item.id)
         })  
         setStorearrayVal(storearray) 
  },[location])

  
  useEffect(async()=>{ 
   await apiRequestPOST(`${BASE_URL}app_ctrl/get_homeproduct`,{storearray:storearrayVal}, false)
   .then((response) => {
      setShopProduct(response) 
   }, (error) => {
      console.log(error);
   });  
      },[storearrayVal])

  const shoplistingItem = get(contextData,'shoplist',false) 

  const add_to_cart = (ProductId,store_id,branch_id) => { 
   const shopId =store_id; 
   const branchId =branch_id; 
     
   const userCartistbranch_id=JSON.parse(localStorage.getItem('userCartistbranch_id'));
   if(branchId==userCartistbranch_id || userCartistbranch_id==''){   
        apiRequestPOST(`${BASE_URL}app_user_ctrl/product_add_to_cart`,{product_id : ProductId,store_id:shopId,branch_id:branchId,qty:1},false)
      .then((response) => {
       
       apiRequestPOST(`${BASE_URL}app_user_ctrl/user_cart_list`,'' ,false) 
       .then(async (response1) => { 
   await contextData.setUserCartist(response1);  
    localStorage.setItem('userCartist',JSON.stringify(response1.user_cart_list))    
    localStorage.setItem('userCartiststore_id', JSON.stringify(response1.store_id)) 
    localStorage.setItem('userCartistbranch_id', JSON.stringify(response1.branch_id)) 
       })
       .catch((error) => {  
        console.log(error); 
       });

        if(response == 0){  
          toast.error('Failed, Try again', {
             position: "top-center",
             autoClose: 5000,
             hideProgressBar: false,
             closeOnClick: true,
             pauseOnHover: true,
             draggable: true,
             progress: undefined,
             });
      }
      if(response == 1){ 
          
          toast.success('Updated cart', {
             position: "top-center",
             autoClose: 5000,
             hideProgressBar: false,
             closeOnClick: true,
             pauseOnHover: true,
             draggable: true,
             progress: undefined,
             });
      }
      if(response == 2){ 
          toast.error('Not enough stock !!!', {
             position: "top-center",
             autoClose: 5000,
             hideProgressBar: false,
             closeOnClick: true,
             pauseOnHover: true,
             draggable: true,
             progress: undefined,
             });
      }
      if(response == 3){ 
          toast.error('Invalid Quantity !!!', {
             position: "top-center",
             autoClose: 5000,
             hideProgressBar: false,
             closeOnClick: true,
             pauseOnHover: true,
             draggable: true,
             progress: undefined,
             });
      }
     }, (error) => {
        console.log(error);
      });
    }else{  
localStorage.setItem('diffrent_branch_userCartist',JSON.stringify({product_id : ProductId,store_id:shopId,branch_id:branchId,qty:1})) 
    document.getElementById('diffrent_branch').click()
 }
     }
    return (
       
      <React.Fragment>
      <Header />

 
      <section class="top-category section-padding">
         <div class="container" >
              <div class="section-header">
               <h1 class="heading-design-h5">Our Products 
                  {/* <Link class="float-right text-secondary"  to="/home">View All</Link> */}
               </h1>
            </div>
         <div class="row no-gutters">
                  {
                     shopcategory&&shopcategory.map(item => {
                        return (

                           <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-2" 
                            to={   {   pathname: '/category', search: `?categoryId=${item.category_id}&categoryName=${item.category_name}`,
                                   state: { categoryId: shopcategory.category_id, categoryName: shopcategory.category_name }
                                          }
                                    } 
                          
                                   >
                              <div className="category-item" >
                                 <Link>
                                 {item.category_image?
                                    <img className="img-fluid" src={`${BASE_URL}assets/category/original/${item.category_image}`} alt="" style={{width:'100%',height:'auto'}}/>
                                :  
                                <img className="img-fluid" src='http://via.placeholder.com/250x250&text=No%20Image' alt="" style={{width:'100%',height:'auto'}}/>
                                //   <p data-letters={item.category_name.charAt(0)?.toUpperCase()}  style={{fontSize:'14px'}}></p>
                                  } <br/><br/>
                                    <p style={{wordBreak:'break-all',width:'100%',fontWeight:'500' }}>
                                    {
                                                     item.category_name.length >= 120?
                                                     item.category_name.substring(0,120) + "..."
                                                  :item.category_name
                                                  }
                                                  {}</p>  
                        {/* <p>150 Items</p> */}
                      
                                 </Link>
                                 
                             
                              <div className='category-item_btn'>
                        <button class="btn_spl btn-danger" type="submit"
                         onClick={() =>
                           history.push({
                              pathname: "/category",search: `?categoryId=${item.category_id}&categoryName=${item.category_name}`,
                              state: { categoryId: shopcategory.category_id, categoryName: shopcategory.category_name }
                           })
                        }>Buy Now</button>
                        </div> </div>
                           </div>
                        )
                     })
                  } 
                </div> 

          
            </div>
         </section>

         {/* <section class="carousel-slider-main text-center border-top border-bottom bg-white">
         <div class="owl-carousel owl-carousel-slider">
       
            <div className="item">
               <img className="img-fluid" src={`${BASE_URL}assets/admin/resized/${contextData?.admindata?.slider_image1}`} alt="slider"/> 
                </div>
            <div className="item"> 
               <img className="img-fluid" src={`${BASE_URL}assets/admin/resized/${contextData?.admindata?.slider_image2}`} alt="slider"/> 
                </div>
            <div className="item"> 
               <img className="img-fluid" src={`${BASE_URL}assets/admin/resized/${contextData?.admindata?.slider_image3}`} alt="slider"/> 
                </div>
            
         </div>
      </section> */}
      
      <section className="offer-product">
         <div className="container">
            <div className="row no-gutters">
               <div className="col-md-6"> 
                  <ExternalLink href="https://aaranmulakannadi.com/">
                     <img className="img-fluid" src="assets/img/middle1.png" alt=""  style={{width:'100%'}}/> 
                     </ExternalLink>
               </div> 
               
               <div className="col-md-6"> 
                   <ExternalLink href="http://www.keralahandlooms.com/">
                   <img className="img-fluid" src="assets/img/middle2.png" alt=""  style={{width:'100%'}}/>
                     </ExternalLink>
               </div>
            </div>
         </div>
      </section>
      

       
       
      <section className="product-items-slider section-padding">
      <h5 class="heading-design-h5">Premium Products  </h5> 
      {/* <Link class="float-right text-secondary"  to="/home">View All</Link> */}
         <div className="container">
         

            <div onLoad={() =>setemptyCheck(1)}>
                             
                               <div className="row no-gutters">
                                  { 
                                     contextData?.shoplist&&shopproduct&&shopproduct?.map(subItm => {

                                       let original_price = subItm.original_price;
                                       let selling_price = subItm.selling_price;
                                       let discountamount = original_price - selling_price;
                                       let discount_percent = (discountamount / original_price) * 100;
                                       return (  

<div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-2  shop_cls"> 
                                            
                                              <div className="product" style={{ cursor: 'pointer' }} onClick={() =>
                                                    history.push({
                                                       pathname: "/single",
                                                       state: { productId: subItm.id }
                                                    })
                                                 } >

                                                 <div className="product-header" >
                                                    {discount_percent > 0 ? (
                                                       <span className="badge badge-success">{(discount_percent).toFixed(2)}% OFF</span>
                                                    ) : (<></>)}
                                                    <span className="badge_right badge-success">{subItm.brand_name}</span>
                                                  
                                                   {subItm.pic1?<img className="img-fluid" src={`${BASE_URL}assets/products/original/${subItm.pic1}`} alt="" />
                                                  :<img className="img-fluid" src='http://via.placeholder.com/250x250&text=No Image' alt="" />}
                                                
                                                
                                             
                                                 </div>
                                                 <div className="product-body" >

<h5 title={subItm.name}>{
                                                   subItm.name.length >= 230?
                                                   subItm.name.substring(0,230) + "..."
                                                :subItm.name
                                                }</h5>



                                            
<h6><strong><span className="mdi mdi "></span>  </strong> {subItm.qty} {subItm.product_unit}

<span className="float-center">
  <strong> {subItm.category_name}</strong>
  {subItm.brand_name}</span></h6> 
                                                  </div>

                                                  <div className="product-footer"> 
                                                     <p className="offer-price mb-0">
                                                     {(localStorage.getItem("userToken") == null) ?
                                                         <button type="button"  data-target="#bd-example-modal" data-toggle="modal" className="btn btn-secondary btn-sm float-right"><i className="mdi mdi-cart-outline"></i> </button>
                                                         : <button type="button" className="btn btn-secondary btn-sm float-right"
                                                            onClick={() => add_to_cart(subItm.id,subItm.store_id,subItm.branch_id)}
                                                         >Click To Buy <i className="mdi mdi-cart-outline"></i></button>
                                                      }
                                                      {contextData?.country=='INDIA'?  '₹'+ subItm.selling_price : '$' + subItm.selling_price_abroad}  <br /><span className="regular-price">{contextData?.country=='INDIA'? subItm.original_price>0&&'₹' + subItm.original_price : subItm.original_price_abroad>0&&'$' + subItm.original_price_abroad}</span>
                                                        </p>
                                                  </div>

                                              </div>

                                           </div>
                                        )
                                     })
                                  }
                               </div> 
                            </div> 
                         
               
         </div>
      </section>

       

      <section className="section-padding bg-white border-top">
         <div className="container">
            <div className="row">
               <div className="col-lg-4 col-sm-6">
                  <div className="feature-box">
                     <i className="mdi mdi-truck-fast"></i>
                     <h6>Free & Next Day Delivery</h6>
                     <p>Lorem ipsum dolor sit amet, cons...</p>
                  </div>
               </div>
               <div className="col-lg-4 col-sm-6">
                  <div className="feature-box">
                     <i className="mdi mdi-basket"></i>
                     <h6>100% Satisfaction Guarantee</h6>
                     <p>Rorem Ipsum Dolor sit amet, cons...</p>
                  </div>
               </div>
               <div className="col-lg-4 col-sm-6">
                  <div className="feature-box">
                     <i className="mdi mdi-tag-heart"></i>
                     <h6>Great Daily Deals Discount</h6>
                     <p>Sorem Ipsum Dolor sit amet, Cons...</p>
                  </div>
               </div>
            </div>
         </div>
      </section>

      <Footer />

   </React.Fragment>
    )
}
