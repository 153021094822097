
import React, { useState, useContext, useEffect } from 'react'
import { Link,useLocation } from 'react-router-dom'
import Header from './Header'
import Footer from './Footer'
import { apiRequestPOST } from '../utils/ApiCall';
import { BASE_URL } from '../utils/Constants';
import { toast } from 'react-toastify';
import { userDetailContext } from '../App'; 
import moment from 'moment'
import { useHistory } from "react-router-dom";
import get from 'lodash.get'

export default function Orderlist() {
   const logout= () => { 
      localStorage.removeItem('userName') 
      localStorage.removeItem('userCartist')
      localStorage.removeItem('userCartiststore_id')
      localStorage.removeItem('userCartistbranch_id')
      localStorage.removeItem('orderlistid')
      localStorage.removeItem('productId')
      localStorage.removeItem('userToken')
      history.push({
         pathname: "/home",
         state: ''
      }) 
   }

   let history = useHistory();
   var contextData = useContext(userDetailContext);
   const [orderList, setOrderList] = useState([]);
   const location = useLocation();
  useEffect(() => {
      apiRequestPOST(`${BASE_URL}app_user_ctrl/get_orderlist`, '', false)
         .then((response) => {
            setOrderList(response)
         }, (error) => {
            console.log(error);
         });

   }, [location])


   return (
      <React.Fragment>
         <Header />

         <section className="account-page section-padding">
            <div className="container">
               <div className="row">
               <div className="col-lg-10 col-md-12 mx-auto">
                     <div className="row no-gutters">
                     <div className="col-md-4 col-lg-3">
                           <div className="card account-left">
                              <div className="user-profile-header">
                                 {/* <img alt="logo" src="assets/img/user.jpg"/> */}
                                 <h5 className="mb-1 text-secondary"><strong>Hi </strong> {contextData?.username?.name}</h5>
                                 <p> {contextData?.username?.mob}</p>
                              </div>
                              <div className="list-group">
                                 <Link to="/my_profile" className="list-group-item list-group-item-action"><i aria-hidden="true" className="mdi mdi-account-outline"></i>  My Profile</Link>
                                 <Link to="/my_address" className="list-group-item list-group-item-action"><i aria-hidden="true" className="mdi mdi-map-marker-circle"></i>  My Address</Link>
                                 <Link to="/wishlist" className="list-group-item list-group-item-action"><i aria-hidden="true" className="mdi mdi-heart-outline"></i>  Wish List </Link>
                                 <Link to="/rating" className="list-group-item list-group-item-action"><i aria-hidden="true" className="mdi mdi-star-outline"></i>  Store Rating </Link>
                                   <Link to="/orderlist" className="list-group-item list-group-item-action active"><i aria-hidden="true" className="mdi mdi-format-list-bulleted"></i>  Order List</Link>
                                 <Link   className="list-group-item list-group-item-action"
                                 onClick={()=>logout()}><i aria-hidden="true" className="mdi mdi-lock"></i>  Logout</Link>
                              </div>
                           </div>
                        </div>
                        <div className="col-md-8 col-lg-9">
                           <div className="card card-body account-right">
                              <div className="widget">
                                 <div className="section-header">
                                    <h5 className="heading-design-h5">
                                       Order List
                                    </h5>
                                 </div>
                                 <div className="order-list-tabel-main table-responsive">
                                    <table className="datatabel table table-striped table-bordered order-list-tabel" width="100%" cellspacing="0">
                                       <thead>

                                          <tr>
                                             <th>Order #</th>
                                             <th>Date Purchased</th>
                                             <th>Status</th>
                                             <th>Total</th>
                                             <th>Action</th>
                                          </tr>
                                       </thead>
                                       <tbody>

                                          {
                                             orderList.length > 0 && orderList.map(item => {
                                                return (

                                                   <tr>
                                                      <td>{item.orderno}</td>

                                                      <td>{
                                                         moment(item.created_at).format('D-MMM-yyyy')}
                                                      </td>
                                                      <td>
                                                         {item.deliverystatus == 0 && <span className="badge badge-info">In Progress</span>}
                                                         {item.deliverystatus == 1 && <span className="badge badge-primary">Approved</span>}
                                                         {item.deliverystatus == 2 && <span className="badge badge-danger">Canceled</span>}
                                                         {item.deliverystatus == 3 && <span className="badge badge-warning">Assigned</span>}
                                                         {item.deliverystatus == 4 && <span className="badge badge-success">Delivered</span>}
                                                      </td>
                                                      <td>₹{item.total_amount}</td>
                                                      <td onClick={() => 
                                                            history.push({
                                                              pathname: "/orderview", 
                                                                 state:{id: item.orderid} 
                                                           })  }  >
                                                         <Link 
                                                          
                             data-toggle="tooltip" data-placement="top" title=""  data-original-title="View Detail" className="btn btn-info btn-sm">
                                                            <i className="mdi mdi-eye"></i>
                                                         </Link></td>
                                                   </tr>
                                                )
                                             })
                                          }
                                       </tbody>
                                    </table>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
         <section className="section-padding bg-white border-top">
            <div className="container">
               <div className="row">
                  <div className="col-lg-4 col-sm-6">
                     <div className="feature-box">
                        <i className="mdi mdi-truck-fast"></i>
                        <h6>Free & Next Day Delivery</h6>
                        <p>Lorem ipsum dolor sit amet, cons...</p>
                     </div>
                  </div>
                  <div className="col-lg-4 col-sm-6">
                     <div className="feature-box">
                        <i className="mdi mdi-basket"></i>
                        <h6>100% Satisfaction Guarantee</h6>
                        <p>Rorem Ipsum Dolor sit amet, cons...</p>
                     </div>
                  </div>
                  <div className="col-lg-4 col-sm-6">
                     <div className="feature-box">
                        <i className="mdi mdi-tag-heart"></i>
                        <h6>Great Daily Deals Discount</h6>
                        <p>Sorem Ipsum Dolor sit amet, Cons...</p>
                     </div>
                  </div>
               </div>
            </div>
         </section>


         <Footer />

      </React.Fragment>
   )
}
