import React from 'react'
import { Link,useLocation } from 'react-router-dom'
import Header from './Header'
import Footer from './Footer'

export default function Returnpolicy() {
    return (
        <React.Fragment>
            
    
            <Header/>
      
   
   
     <section className="section-padding bg-white">
        <div className="container">
           <div className="row"> 
              <div className="col-lg-12 col-md-12 pl-5 pr-5">
                 <h2 className="mt-5 mb-5 text-secondary">Cancellation Policy</h2>
                 <p>
                 see the 'Cancel' button but I can't click on it. Why?

A greyed out and disabled 'Cancel' button can mean any one of the following:

1. The item has been delivered already
OR
2. The item is non-refundable (e.g. Gift Card)
What are the refund timelines if I cancel or return a product?

The refund timelines will depend on the payment modes as listed below:

Debit card - 7-9 Business days
Credit - 7-9 Business days
Netbanking - 3-7 business days
COD - IMPS, 1 Business days
EMI (Standard+No cost+Debit card) - 7- 9 Business days
Flipkart Pay Later - 24 to 48 hours
Gift Card - 24 hours
PhonePe:
PhonePe wallet - 24 hours
PhonePe UPI - 1 business day
PhonePe Credit/Debit card - 7 business days
Note: Refunds will be credited to the payment source used at the time of placing an order.
The mode of refund in case of payment through 'Cash on Delivery' will be IMPS
How long does it take to cancel an order?

Cancellation of item(s) in an order happens immediately if the order hasn't been shipped yet by the seller. If your order has been shipped, it will be cancelled as soon as the courier service confirms that the shipment is being returned to the seller. Orders from certain categories cannot be cancelled after 24 hours, please check the product page for more details.
 </p>
                  </div>
           </div>
        </div>
     </section> 
     <Footer/>
     </React.Fragment>
    )
}
