 
	import React from 'react'
   import { Link,useLocation } from 'react-router-dom'
   import Header from './Header'
   import Footer from './Footer'
   
   export default function Cart() {
       return (
        <React.Fragment> 
        <Header/>
        
        <section className="pt-3 pb-3 page-info section-padding border-bottom bg-white">
         <div className="container">
            <div className="row">
               <div className="col-md-12">
                  <Link ><strong><span className="mdi mdi-home"></span> Home</strong></Link> <span className="mdi mdi-chevron-right"></span> <Link >Cart</Link>
               </div>
            </div>
         </div>
      </section>
      <section className="cart-page section-padding">
         <div className="container">
            <div className="row">
               <div className="col-md-12">
                  <div className="card card-body cart-table">
                     <div className="table-responsive">
                        <table className="table cart_summary">
                           <thead>
                              <tr>
                                 <th className="cart_product">Product</th>
                                 <th>Description</th>
                                 <th>Avail.</th>
                                 <th>Unit price</th>
                                 <th>Qty</th>
                                 <th>Total</th>
                                 <th className="action"><i className="mdi mdi-delete-forever"></i></th>
                              </tr>
                           </thead>
                           <tbody>
                              <tr>
                                 <td className="cart_product"><Link ><img className="img-fluid" src="assets/img/item/11.jpg" alt=""/></Link></td>
                                 <td className="cart_description">
                                    <h5 className="product-name"><Link >Ipsums Dolors Untra </Link></h5>
                                    <h6><strong><span className="mdi mdi-approval"></span> Available in</strong> - 500 gm</h6>
                                 </td>
                                 <td className="availability in-stock"><span className="badge badge-success">In stock</span></td>
                                 <td className="price"><span>$49.88</span></td>
                                 <td className="qty">
                                    <div className="input-group">
                                       <span className="input-group-btn"><button disabled="disabled" className="btn btn-theme-round btn-number" type="button">-</button></span>
                                       <input type="text" max="10" min="1" value="1" className="form-control border-form-control form-control-sm input-number" name="quant[1]"/>
                                       <span className="input-group-btn"><button className="btn btn-theme-round btn-number" type="button">+</button>
                                       </span>
                                    </div>
                                 </td>
                                 <td className="price"><span>$49.88</span></td>
                                 <td className="action">
                                    <Link className="btn btn-sm btn-danger" data-original-title="Remove"  title="" data-placement="top" data-toggle="tooltip"><i className="mdi mdi-close-circle-outline"></i></Link>
                                 </td>
                              </tr>
                              <tr>
                                 <td className="cart_product"><Link ><img alt="Product" src="assets/img/item/10.jpg" className="img-fluid"/></Link></td>
                                 <td className="cart_description">
                                    <h5 className="product-name"><Link >Ipsums Dolors Untra </Link></h5>
                                    <h6><strong><span className="mdi mdi-approval"></span> Available in</strong> - 500 gm</h6>
                                 </td>
                                 <td className="availability out-of-stock"><span className="badge badge-primary">No stock</span></td>
                                 <td className="price"><span>$00.00</span></td>
                                 <td className="qty">
                                    <div className="input-group">
                                       <span className="input-group-btn"><button disabled="disabled" className="btn btn-theme-round btn-number" type="button">-</button></span>
                                       <input type="text" max="10" min="1" value="1" className="form-control border-form-control form-control-sm input-number" name="quant[1]"/>
                                       <span className="input-group-btn"><button className="btn btn-theme-round btn-number" type="button">+</button>
                                       </span>
                                    </div>
                                 </td>
                                 <td className="price"><span>00.00</span></td>
                                 <td className="action">
                                    <Link className="btn btn-sm btn-danger" data-original-title="Remove"  title="" data-placement="top" data-toggle="tooltip"><i className="mdi mdi-close-circle-outline"></i></Link>
                                 </td>
                              </tr>
                              <tr>
                                 <td className="cart_product"><Link ><img alt="Product" src="assets/img/item/9.jpg" className="img-fluid"/></Link></td>
                                 <td className="cart_description">
                                    <h5 className="product-name"><Link >Ipsums Dolors Untra </Link></h5>
                                    <h6><strong><span className="mdi mdi-approval"></span> Available in</strong> - 500 gm</h6>
                                 </td>
                                 <td className="availability in-stock"><span className="badge badge-warning">In stock</span></td>
                                 <td className="price"><span>$99.00</span></td>
                                 <td className="qty">
                                    <div className="input-group">
                                       <span className="input-group-btn"><button disabled="disabled" className="btn btn-theme-round btn-number" type="button">-</button></span>
                                       <input type="text" max="10" min="1" value="1" className="form-control border-form-control form-control-sm input-number" name="quant[1]"/>
                                       <span className="input-group-btn"><button className="btn btn-theme-round btn-number" type="button">+</button>
                                       </span>
                                    </div>
                                 </td>
                                 <td className="price"><span>$188.00</span></td>
                                 <td className="action">
                                    <Link className="btn btn-sm btn-danger" data-original-title="Remove"  title="" data-placement="top" data-toggle="tooltip"><i className="mdi mdi-close-circle-outline"></i></Link>
                                 </td>
                              </tr>
                           </tbody>
                           <tfoot>
                              <tr>
                                 <td colspan="1"></td>
                                 <td colspan="4">
                                    <form className="form-inline float-right">
                                       <div className="form-group">
                                          <input type="text" placeholder="Enter discount code" className="form-control border-form-control form-control-sm"/>
                                       </div>
                                       &nbsp;
                                       <button className="btn btn-success float-left btn-sm" type="submit">Apply</button>
                                    </form>
                                 </td>
                                 <td colspan="2">Discount : $237.88 </td>
                              </tr>
                              <tr>
                                 <td colspan="2"></td>
                                 <td className="text-right"  colspan="3">Total products (tax incl.)</td>
                                 <td colspan="2">$437.88 </td>
                              </tr>
                              <tr>
                                 <td className="text-right" colspan="5"><strong>Total</strong></td>
                                 <td className="text-danger" colspan="2"><strong>$337.88 </strong></td>
                              </tr>
                           </tfoot>
                        </table>
                     </div>
                     <Link to="/checkout"><button className="btn btn-secondary btn-lg btn-block text-left" type="button"><span className="float-left"><i className="mdi mdi-cart-outline"></i> Proceed to Checkout </span><span className="float-right"><strong>$1200.69</strong> <span className="mdi mdi-chevron-right"></span></span></button></Link>
                  </div>
                  <div className="card mt-2">
                     <h5 className="card-header">My Cart (Design Two)<span className="text-secondary float-right">(5 item)</span></h5>
                     <div className="card-body pt-0 pr-0 pl-0 pb-0">
                        <div className="cart-list-product">
                           <Link className="float-right remove-cart" ><i className="mdi mdi-close"></i></Link>
                           <img className="img-fluid" src="assets/img/item/11.jpg" alt=""/>
                           <span className="badge badge-success">50% OFF</span>
                           <h5><Link >Product Title Here</Link></h5>
                           <h6><strong><span className="mdi mdi-approval"></span> Available in</strong> - 500 gm</h6>
                           <p className="offer-price mb-0">$450.99 <i className="mdi mdi-tag-outline"></i> <span className="regular-price">$800.99</span></p>
                        </div>
                        <div className="cart-list-product">
                           <Link className="float-right remove-cart" ><i className="mdi mdi-close"></i></Link>
                           <img className="img-fluid" src="assets/img/item/1.jpg" alt=""/>
                           <span className="badge badge-success">50% OFF</span>
                           <h5><Link >Product Title Here</Link></h5>
                           <h6><strong><span className="mdi mdi-approval"></span> Available in</strong> - 500 gm</h6>
                           <p className="offer-price mb-0">$450.99 <i className="mdi mdi-tag-outline"></i> <span className="regular-price">$800.99</span></p>
                        </div>
                        <div className="cart-list-product">
                           <Link className="float-right remove-cart" ><i className="mdi mdi-close"></i></Link>
                           <img className="img-fluid" src="assets/img/item/2.jpg" alt=""/>
                           <span className="badge badge-success">50% OFF</span>
                           <h5><Link >Product Title Here</Link></h5>
                           <h6><strong><span className="mdi mdi-approval"></span> Available in</strong> - 500 gm</h6>
                           <p className="offer-price mb-0">$450.99 <i className="mdi mdi-tag-outline"></i> <span className="regular-price">$800.99</span></p>
                        </div>
                     </div>
                     <div className="card-footer cart-sidebar-footer">
                        <div className="cart-store-details">
                           <p>Sub Total <strong className="float-right">$900.69</strong></p>
                           <p>Delivery Charges <strong className="float-right text-danger">+ $29.69</strong></p>
                           <h6>Your total savings <strong className="float-right text-danger">$55 (42.31%)</strong></h6>
                        </div>
                        <Link to="/checkout"><button className="btn btn-secondary btn-lg btn-block text-left" type="button"><span className="float-left"><i className="mdi mdi-cart-outline"></i> Proceed to Checkout </span><span className="float-right"><strong>$1200.69</strong> <span className="mdi mdi-chevron-right"></span></span></button></Link>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <section className="section-padding bg-white border-top">
         <div className="container">
            <div className="row">
               <div className="col-lg-4 col-sm-6">
                  <div className="feature-box">
                     <i className="mdi mdi-truck-fast"></i>
                     <h6>Free & Next Day Delivery</h6>
                     <p>Lorem ipsum dolor sit amet, cons...</p>
                  </div>
               </div>
               <div className="col-lg-4 col-sm-6">
                  <div className="feature-box">
                     <i className="mdi mdi-basket"></i>
                     <h6>100% Satisfaction Guarantee</h6>
                     <p>Rorem Ipsum Dolor sit amet, cons...</p>
                  </div>
               </div>
               <div className="col-lg-4 col-sm-6">
                  <div className="feature-box">
                     <i className="mdi mdi-tag-heart"></i>
                     <h6>Great Daily Deals Discount</h6>
                     <p>Sorem Ipsum Dolor sit amet, Cons...</p>
                  </div>
               </div>
            </div>
         </div>
      </section>
      
      <Footer/>
        
        </React.Fragment>
       )
   }
   
   