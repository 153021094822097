 
import React, { useEffect, useState, useContext } from 'react'
import { Link,useLocation } from 'react-router-dom'
import Header from './Header'
import Footer from './Footer'
import { BASE_URL } from '../utils/Constants'
import { apiRequestPOST } from '../utils/ApiCall'
import { toast } from 'react-toastify'
import GoogleMapReact from 'google-map-react';
import get from 'lodash.get'
import Geocode from "react-geocode";
import { Field, Formik, Form } from 'formik'
import * as Yup from 'yup';
import { userDetailContext } from '../App';
import { useHistory } from "react-router-dom";
   
   export default function Wishlist() {
      var contextData = useContext(userDetailContext); 
      const [wishListProduct, setWishListProduct] = useState([]); 
      let history = useHistory();
         const location = useLocation();
         useEffect(async()=> {   
             apiRequestPOST(`${BASE_URL}app_user_ctrl/user_product_favourite`,'', false)
             .then((response) => {
               setWishListProduct(response) 
             }, (error) => {
                console.log(error);
             });
            },[location])

            const add_to_cart = (ProductId,store_id,branch_id) => { 
               const shopId =store_id; 
               const branchId =branch_id;  
                 
               const userCartistbranch_id=JSON.parse(localStorage.getItem('userCartistbranch_id'));
               if(branchId==userCartistbranch_id || userCartistbranch_id==''){   
                    apiRequestPOST(`${BASE_URL}app_user_ctrl/product_add_to_cart`,{product_id : ProductId,store_id:shopId,branch_id:branchId,qty:1},false)
                  .then((response) => {
                   
                   apiRequestPOST(`${BASE_URL}app_user_ctrl/user_cart_list`,'' ,false) 
                   .then(async (response1) => { 
               await contextData.setUserCartist(response1);  
                localStorage.setItem('userCartist',JSON.stringify(response1.user_cart_list))    
                localStorage.setItem('userCartiststore_id', JSON.stringify(response1.store_id)) 
                localStorage.setItem('userCartistbranch_id', JSON.stringify(response1.branch_id)) 
                   })
                   .catch((error) => {  
                    console.log(error); 
                   });
       
                    if(response == 0){  
                      toast.error('Failed, Try again', {
                         position: "top-center",
                         autoClose: 5000,
                         hideProgressBar: false,
                         closeOnClick: true,
                         pauseOnHover: true,
                         draggable: true,
                         progress: undefined,
                         });
                  }
                  if(response == 1){ 
                      
                      toast.success('Updated cart', {
                         position: "top-center",
                         autoClose: 5000,
                         hideProgressBar: false,
                         closeOnClick: true,
                         pauseOnHover: true,
                         draggable: true,
                         progress: undefined,
                         });
                  }
                  if(response == 2){ 
                      toast.error('Not enough stock !!!', {
                         position: "top-center",
                         autoClose: 5000,
                         hideProgressBar: false,
                         closeOnClick: true,
                         pauseOnHover: true,
                         draggable: true,
                         progress: undefined,
                         });
                  }
                  if(response == 3){ 
                      toast.error('Invalid Quantity !!!', {
                         position: "top-center",
                         autoClose: 5000,
                         hideProgressBar: false,
                         closeOnClick: true,
                         pauseOnHover: true,
                         draggable: true,
                         progress: undefined,
                         });
                  }
                 }, (error) => {
                    console.log(error);
                  });
                }else{  
          localStorage.setItem('diffrent_branch_userCartist',JSON.stringify({product_id : ProductId,store_id:shopId,branch_id:branchId,qty:1})) 
                document.getElementById('diffrent_branch').click()
             }
                 }
              
       return (
        <React.Fragment> 
        <Header/>
        
         <section className="account-page section-padding">
         <div className="container">
            <div className="row">
               <div className="col-lg-10 col-md-12 mx-auto">
                  <div className="row no-gutters">
                     <div className="col-md-4 col-lg-3">
                        <div className="card account-left">
                        <div className="user-profile-header">
                                 {/* <img alt="logo" src="assets/img/user.jpg"/> */}
                                 <h5 className="mb-1 text-secondary"><strong>Hi </strong> {contextData?.username?.name}</h5>
                                 <p> {contextData?.username?.mob}</p>
                              </div>
                           <div className="list-group">
                           <Link to="/my_profile" className="list-group-item list-group-item-action"><i aria-hidden="true" className="mdi mdi-account-outline"></i>  My Profile</Link>
                                 <Link to="/my_address" className="list-group-item list-group-item-action"><i aria-hidden="true" className="mdi mdi-map-marker-circle"></i>  My Address</Link>
                                 <Link to="/wishlist" className="list-group-item list-group-item-action active"><i aria-hidden="true" className="mdi mdi-heart-outline"></i>  Wish List </Link>
                                 <Link to="/rating" className="list-group-item list-group-item-action"><i aria-hidden="true" className="mdi mdi-star-outline"></i>  Store Rating </Link>
                                 <Link to="/orderlist" className="list-group-item list-group-item-action"><i aria-hidden="true" className="mdi mdi-format-list-bulleted"></i>  Order List</Link>
                                 <Link  className="list-group-item list-group-item-action"><i aria-hidden="true" className="mdi mdi-lock"></i>  Logout</Link>
                           </div>
                        </div>
                     </div>
                     <div className="col-md-8 col-lg-9">
                        <div className="card card-body account-right">
                           <div className="widget">
                              <div className="section-header">
                                 <h5 className="heading-design-h5">
                                    Wishlist
                                 </h5>
                              </div>
 
                              <div className="row no-gutters">
                                     
                                     {
                                        wishListProduct.length>0 && wishListProduct.map(subItm => {
 
                                           let original_price = subItm.original_price;
                                           let selling_price = subItm.selling_price;
                                           let discountamount = original_price - selling_price;
                                           let discount_percent = (discountamount / original_price) * 100;
                                           return ( 
                                              <div className="col-md-6 col-lg-4 shop_cls">
                                                 {/* {JSON.stringify(subItm)} */} 
                                                 <div className="product" style={{ cursor: 'pointer' }} onClick={() =>
                                                       history.push({
                                                          pathname: "/single",
                                                          state: { productId: subItm.id }
                                                       })
                                                    }  >
 
                                                    <div className="product-header">
                                                       {discount_percent > 0 ? (
                                                          <span className="badge badge-success">{(discount_percent).toFixed(2)}% OFF</span>
                                                       ) : (<></>)}
                                                     {subItm.pic1?<img className="img-fluid" src={`${BASE_URL}assets/products/original/${subItm.pic1}`} alt="" />
                                                     :<img className="img-fluid" src='http://via.placeholder.com/250x250&text=No Image' alt="" />}
                                                  
                                                   
 
                                                    </div>
                                                    <div className="product-body">
 
 <h5 title={subItm.name}>{
                                                      subItm.name.length >= 230?
                                                      subItm.name.substring(0,230) + "..."
                                                   :subItm.name
                                                   }</h5>
 
 
 
 <h6><strong><span className="mdi mdi "></span>  </strong> {subItm.qty} {subItm.product_unit}
                                                      <span className="float-right"><strong><span className="mdi mdi "></span>  </strong> {subItm.brand_name}</span></h6>  </div>
                                                    <div className="product-footer">
                                                       {(localStorage.getItem("userToken") == null) ?
                                                          <button type="button"  data-target="#bd-example-modal" data-toggle="modal" className="btn btn-secondary btn-sm float-right"><i className="mdi mdi-cart-outline"></i> </button>
                                                          : <button type="button" className="btn btn-secondary btn-sm float-right"
                                                             onClick={() => add_to_cart(subItm.id,subItm.store_id,subItm.favourite_branch_id)}
                                                          ><i className="mdi mdi-cart-outline"></i> </button>
                                                       }
                                                       <p className="offer-price mb-0">{contextData?.country=='INDIA'? '₹'+ subItm.selling_price : '$' + subItm.selling_price_abroad} <i className="mdi mdi-tag-outline"></i><br /><span className="regular-price">{contextData?.country=='INDIA'? '₹' + subItm.original_price : '$' + subItm.original_price_abroad}</span></p>
                                                    </div>
 
                                                 </div> 
                                              </div>
                                           )
                                        })
                                     }
                                  </div>
                 

						 
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <section className="section-padding bg-white border-top">
         <div className="container">
            <div className="row">
               <div className="col-lg-4 col-sm-6">
                  <div className="feature-box">
                     <i className="mdi mdi-truck-fast"></i>
                     <h6>Free & Next Day Delivery</h6>
                     <p>Lorem ipsum dolor sit amet, cons...</p>
                  </div>
               </div>
               <div className="col-lg-4 col-sm-6">
                  <div className="feature-box">
                     <i className="mdi mdi-basket"></i>
                     <h6>100% Satisfaction Guarantee</h6>
                     <p>Rorem Ipsum Dolor sit amet, cons...</p>
                  </div>
               </div>
               <div className="col-lg-4 col-sm-6">
                  <div className="feature-box">
                     <i className="mdi mdi-tag-heart"></i>
                     <h6>Great Daily Deals Discount</h6>
                     <p>Sorem Ipsum Dolor sit amet, Cons...</p>
                  </div>
               </div>
            </div>
         </div>
      </section>
  
      
      <Footer/>
     
     </React.Fragment>
    )
}
