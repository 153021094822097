import React from 'react'
import { Link,useLocation } from 'react-router-dom'
import Header from './Header'
import Footer from './Footer'

export default function Terms() {
    return (
        <React.Fragment>
            
    
            <Header/>
      
   
   
     <section className="section-padding bg-white">
        <div className="container">
           <div className="row"> 
              <div className="col-lg-12 col-md-12 pl-5 pr-5">
                 <h2 className="mt-5 mb-5 text-secondary">Terms & Conditions POLICY</h2>
                 <p>
                 We value the trust you place in us and recognize the importance of secure transactions and information privacy. This Privacy Policy describes how Multivendor and its affiliates (collectively Multivendor, we, our, us”) collect, use, share or otherwise process your personal information through Flipkart website www.flipkart.com, its mobile application, and m-site (hereinafter referred to as the “Platform”).

While you may be able to browse certain sections of the Platform without registering with us, however, please note we do not offer any product or service under this Platform outside India. Your personal information will primarily be stored and processed in India and may have data protection laws that are different from those that apply in the country in which you are located. By visiting this Platform, providing your information or availing out product/service, you expressly agree to be bound by the terms and conditions of this Privacy Policy, the Terms of Use and the applicable service/product terms and conditions, and agree to be governed by the laws of India including but not limited to the laws applicable to data protection and privacy. If you do not agree please do not use or access our Platform.

Collection of Your Information<br/><br/>

When you use our Platform, we collect and store your information which is provided by you from time to time. In general, you can browse the Platform without telling us who you are or revealing any personal information about yourself. Once you give us your personal information, you are not anonymous to us. Where possible, we indicate which fields are required and which fields are optional. You always have the option to not provide information by choosing not to use a particular service, product or feature on the Platform.<br/><br/>

We may track your buying behaviour, preferences, and other information that you choose to provide on our Platform. We use this information to do internal research on our users' demographics, interests, and behaviour to better understand, protect and serve our users. This information is compiled and analysed on an aggregated basis. This information may include the URL that you just came from (whether this URL is on our Platform or not), which URL you next go to (whether this URL is on our Platform or not), your computer browser information, and your IP address.<br/><br/>

We may collect personal information (such as email address, delivery address, name, phone number, credit card/debit card and other payment instrument details) from you when you set up an account or transact with us or participate in any event or contest. While you can browse some sections of our Platform without being a registered member, certain activities (such as placing an order or consuming our online content or services) do require registration. We use your contact information to send you offers based on your previous orders and your interests.<br/><br/>
                 </p>
                  </div>
           </div>
        </div>
     </section> 
     <Footer/>
     </React.Fragment>
    )
}
