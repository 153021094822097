 
     import React from 'react'
     import { Link,useLocation } from 'react-router-dom'
     import Header from './Header'
     import Footer from './Footer'
     
     export default function No_found() {
         return (
          <React.Fragment> 
          <Header/>
          
         <section className="not-found-page section-padding">
         <div className="container">
            <div className="row">
               <div className="col-md-8 mx-auto text-center  pt-4 pb-5">
                  <h1><img className="img-fluid" src="assets/img/404.png" alt="404"/></h1>
                  <h1>Sorry! Page not found.</h1>
                  <p className="land">Unfortunately the page you are looking for has been moved or deleted.</p>
                  <div className="mt-5">
                     <Link to="/home" className="btn btn-success btn-lg"><i className="mdi mdi-home"></i> GO TO HOME PAGE</Link>
                  </div>
               </div>
            </div>
         </div>
      </section>
    
      
   <Footer/>
     
     </React.Fragment>
    )
}
