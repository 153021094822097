 
import React,{ useEffect, useState,useContext} from 'react'
import { Link,useLocation } from 'react-router-dom'
import { userDetailContext } from '../App';
import { BASE_URL } from '../utils/Constants'; 
import { apiRequestPOST } from '../utils/ApiCall' 
import { toast } from 'react-toastify'
import { useHistory } from "react-router-dom";  
import GoogleMapReact from 'google-map-react';
import get from 'lodash.get'
import Geocode from "react-geocode";
import { Field, Formik,Form } from 'formik'
import * as Yup from 'yup'; 
import Header from './Header'
import Footer from './Footer'
    
    export default function Checkout() {
      const location = useLocation();
      useEffect(() => {
            setShopId(JSON.parse(localStorage.getItem('userCartiststore_id'))) 
            setBranchId(JSON.parse(localStorage.getItem('userCartistbranch_id'))) 
           
            const shopIdStringify=localStorage.getItem('datahistory')
            if(shopIdStringify){
            setMaxDistance(JSON.parse(shopIdStringify).shop_datails?.max_delivery_distance);
            setFreeDelivery(JSON.parse(shopIdStringify).shop_datails?.free_delivery);
            setAfterFreeDelivery(JSON.parse(shopIdStringify).shop_datails?.after_free_delivery);
            }else{
               apiRequestPOST(`${BASE_URL}app_ctrl/get_shopdetails`, { id: localStorage.getItem('userCartistbranch_id') }, false)
               .then(async(response) => {
                  console.log('qweq',response);
                  await localStorage.setItem('datahistory',JSON.stringify({ id: response.id,shop_datails:response.shop_datails })); 
                  await contextData.setDataHistory(JSON.stringify({ id: response.id,shop_datails:response.shop_datails }));
                  await contextData.setShopDatails(response.shop_datails);
                  await contextData.setShopIdz(response.id);
                  await contextData.setBranchId(response.shop_datails.branch_id);
                 
            setShopLangititude(response.shop_datails.latitude)
            setShopLongtitude(response.shop_datails.longitude) 
               }, (error) => {
                  console.log(error);
               }); 
            }
           

           (async function fetchData() {
            apiRequestPOST(`${BASE_URL}app_user_ctrl/get_delivery_address`,'',true)
            .then((response) => {  
               setDeliveryAdress(response)  
            }, (error) => { 
              console.log(error);
            }); 
            const datahistory=  JSON.parse(localStorage.getItem('datahistory'))  
            console.log('datahistory',datahistory);
             setShopLangititude(datahistory.shop_datails.latitude)
            setShopLongtitude(datahistory.shop_datails.longitude) 
            console.log('datahistory.shop_datails.latitude',datahistory.shop_datails.latitude);
            await apiRequestPOST(`${BASE_URL}app_user_ctrl/get_delivery_address_current`,{latitude:datahistory.shop_datails.latitude,longitude:datahistory.shop_datails.longitude},false)
            .then((response) => { 
               console.log('km',shopIdStringify);
               setCurrentDeliveryAdress(response.delivery_address)  
               setShopdistance(response.distance.km)   
               response.distance.km>=(JSON.parse(shopIdStringify).shop_datails.free_delivery)?setAmountDeliveryDistance((response.distance.km-JSON.parse(shopIdStringify).shop_datails.free_delivery)*JSON.parse(shopIdStringify).shop_datails.after_free_delivery)
               :setAmountDeliveryDistance(0)
               setCurrentTab('addresslist') 
            }, (error) => {
              console.log(error);
            }); 
           })();
         }, [location]);

         const placeorder = () => { 
         apiRequestPOST(`${BASE_URL}app_user_ctrl/placeorder`,
         { order_address : currentDeliveryAdress?.id, store_id : shopId, branch_id : branchId,delivery_charge : amountDeliveryDistance},false)
         .then((response) => {  
            
            if(response==1){    
                  setRegistarionButton({val:""})
               toast.success('Order Successfully', {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  });
                  document.getElementById('store_rating').click()
                  // history.push({
                  //    pathname: "/home", 
                  //       state:'' 
                  // })
               }else{
                  toast.error('Order Failed', {
                     position: "top-center",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                     });
               }
         })
         .catch(err=>{
            console.log('>>err',err)
         })
         .finally(()=>{ 
            setRegistarionButton({val:""})
         })
      }
  
        
      const [shopId, setShopId] = useState([]);
      const [branchId, setBranchId] = useState([]);
      const [shopdistance, setShopdistance] = useState([]);
      const [amountDeliveryDistance, setAmountDeliveryDistance] = useState([]); 
      const [maxDistance, setMaxDistance] = useState(''); 
      const [freeDelivery, setFreeDelivery] = useState([]); 
      const [afterFreeDelivery, setAfterFreeDelivery] = useState([]); 
      const [shopLangititude, setShopLangititude] = useState([]); 
      const [shopLongtitude, setShopLongtitude] = useState([]); 
      const [currentDeliveryAdress, setCurrentDeliveryAdress] = useState([]); 
      const [deliveryAdress, setDeliveryAdress] = useState([]); 
      const [googleAdress, setGoogleAdress] = useState([]); 
      const [googleLangititude, setGoogleLangititude] = useState([]); 
      const [googleLongtitude, setGoogleLongtitude] = useState([]); 
      const [editGoogleAdress, setEditGoogleAdress] = useState([]); 
      const [editGoogleLangititude, setEditGoogleLangititude] = useState(false);
      const [editGoogleLongtitude, setEditGoogleLongtitude] = useState(false);
      const [editDeliveryAdress, setEditDeliveryAdress] = useState([]); 
      const [tab,setTab] = useState('addresslist') 
      const [edittab,setEditTab] = useState('addresslist')
      const [currenttab,setCurrentTab] = useState([]) 
      const [registarionButton,setRegistarionButton] = useState('false') 
      const [orderButton,setOrderButton] = useState('false') 
      const [currentState,setCurrentState] = useState({
         langititude: 10.526505634649208,
         longtitude:76.21396229150965
      })
         const [editCurrentState,setEditCurrentState] = useState([])
      const center = {
         lat: 10.526505634649208,
         lng:76.21396229150965
     }   
    
    const center1 = {
     //   lat:editGoogleLangititude,
     //   lng:editGoogleLongtitude  
       lat: parseFloat(editGoogleLangititude),
       lng:parseFloat(editGoogleLongtitude)
   }
    const zoom = 11
    const AnyReactComponent = ({ text }) => <img style={{width :20,height :'auto'}} src='assets/img/marker.png'/>;
    const getlocalNameByCoord = (langititude,longtitude) => { 
      
        Geocode.setApiKey("AIzaSyD_ivQdtQK5UuTcspqtw5u5_r27Xbi_f9w");

        Geocode.setLanguage("en");

        Geocode.setRegion("es");

        Geocode.setLocationType("ROOFTOP");

        Geocode.enableDebug();

        Geocode.fromLatLng(langititude, longtitude).then(
            (response) => {
                const address = response.results[0].formatted_address;
               //  console.log("address", address);
                  setGoogleAdress(address)
                  setGoogleLangititude(langititude)
                  setGoogleLongtitude(longtitude)
                  setEditGoogleAdress(address)
                  setEditGoogleLangititude(langititude)
                  setEditGoogleLongtitude(longtitude)
                  console.log(googleLangititude);
                  console.log(googleLongtitude); 
            },
            (error) => {
                console.error(error);
            }
        )
    }

    const phoneRegExp = /^!*([0-9]!*){10,}$/

    const registrationSchema = Yup.object().shape({
       address: Yup.string().required('Required'),
       name: Yup.string().required('Required'),
       phonenumber: Yup.string().matches(phoneRegExp, 'Phone number is not valid').required('Required'),
       place: Yup.string().required('Required'), 
     });

      var contextData = useContext(userDetailContext);
      let history = useHistory();
      var subtotal=0; 
      const update_to_cart = (ProductId,userCartId,productQty) => { 
         //   console.log(ProductId);
         //   console.log(productQty);
        const shopIdStringify=localStorage.getItem('datahistory')
        const shopIdx =JSON.parse(shopIdStringify).id; 
        const branchIdx =JSON.parse(shopIdStringify).shop_datails.branch_id; 
           apiRequestPOST(`${BASE_URL}app_user_ctrl/product_cart_update`,{cart_id:userCartId,product_id : ProductId,productqty:productQty,store_id:shopIdx,branch_id:branchIdx,qty:1},false)
           .then((response) => {
            
            apiRequestPOST(`${BASE_URL}app_user_ctrl/user_cart_list`,'' ,false) 
            .then(async (response1) => { 
        await contextData.setUserCartist(response1.user_cart_list);  
         localStorage.setItem('userCartist',JSON.stringify(response1.user_cart_list))   
         localStorage.setItem('userCartiststore_id', JSON.stringify(response1.store_id))
         localStorage.setItem('userCartistbranch_id', JSON.stringify(response1.branch_id)) 
            })
            .catch((error) => {  
             console.log(error); 
            });
   
             if(response == 0){  
               toast.error('Failed, Try again', {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  });
           }
           if(response == 1){ 
               
               toast.success('Updated cart', {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  });
           }
           if(response == 2){ 
               toast.error('Not enough stock !!!', {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  });
           }
           if(response == 3){ 
               toast.error('Invalid Quantity !!!', {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  });
           }
          }, (error) => {
             console.log(error);
           });
          }
   
   
      const remove_to_cart = (cartId) => {
         //   console.log(ProductId);
        const shopIdStringify=localStorage.getItem('datahistory')
        const shopIdx =JSON.parse(shopIdStringify).id; 
           apiRequestPOST(`${BASE_URL}app_user_ctrl/product_remove_item_cart`,{cart_id : cartId},false)
           .then((response) => {
            
            apiRequestPOST(`${BASE_URL}app_user_ctrl/user_cart_list`,'' ,false) 
            .then(async (response) => { 
        await contextData.setUserCartist(response.user_cart_list);  
         localStorage.setItem('userCartist',JSON.stringify(response.user_cart_list))    
         localStorage.setItem('userCartiststore_id', JSON.stringify(response.store_id))
         localStorage.setItem('userCartistbranch_id', JSON.stringify(response.branch_id))
            })
            .catch((error) => {  
             console.log(error); 
            });
            toast.success('Remove !!!', {
               position: "top-center",
               autoClose: 5000,
               hideProgressBar: false,
               closeOnClick: true,
               pauseOnHover: true,
               draggable: true,
               progress: undefined,
               });
          }, (error) => {
            toast.error('Failed, Try again', {
               position: "top-center",
               autoClose: 5000,
               hideProgressBar: false,
               closeOnClick: true,
               pauseOnHover: true,
               draggable: true,
               progress: undefined,
               });
             console.log(error);
           });
          }
          
        return (
         <React.Fragment> 
         <Header/>
        
         <section className="pt-3 pb-3 page-info section-padding border-bottom bg-white">
         <div className="container">
            <div className="row">
               <div className="col-md-12">
                  <Link ><strong><span className="mdi mdi-home"></span> Home</strong></Link> <span className="mdi mdi-chevron-right"></span> <Link >Checkout</Link>
               </div>
            </div>
         </div>
      </section>
      <section className="checkout-page section-padding">
         <div className="container">
            <div className="row">
               <div className="col-md-8">
                  <div className="checkout-step">
                     <div className="accordion" id="accordionExample">
 

                      
                        <div className="card checkout-step-one">
                           <div className="card-header" id="headingOne">
                              <h5 className="mb-0">
                              <button className="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                  <span className="number">1</span> Delivery Address
                                 </button>
                              </h5>
                           </div>
                           <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                              <div className="card-body">
                            
                              currentDeliveryAdress
                              <div    style={currentDeliveryAdress&&(currenttab=='addresslist')&&(edittab=='addresslist')&&(tab=='addresslist')?{display: "block"}:{display: "none"} }>
                     <div className="row no-gutters">
                   
     
                           
                       <div className="col-md-12">
                              <div className="product">
                                 
                                    <div className="product-body">
                                       <h5 style={{color:currentDeliveryAdress?.address_status=='true'?'green':''}}>{currentDeliveryAdress?.delivery_fname}{currentDeliveryAdress?.address_status=='true'?'*':''}
                                       <span style={{ color: "red", fontWeight: 'bold', cursor: 'pointer',marginLeft:280}} onClick={() => {
                                                   setCurrentTab('');  
                                                }}>Change</span></h5>
                                  <h6><strong>{currentDeliveryAdress?.delivery_address}</strong></h6>
                                       <h6>House Name/Number  : {currentDeliveryAdress?.delivery_place}</h6>
                                       <h6>Phone Number : {currentDeliveryAdress?.delivery_phone}</h6>
                                    </div>
                                    <div className="product-footer">
                                       <center>
                                        
                                    
                                          </center>
                          </div>
                                
                              </div>
                           </div>  
                      
     
   
                                             </div>
                                             </div>


                              <div    style={(currenttab=='')&&(edittab=='addresslist')&&(tab=='addresslist')?{display: "block"}:{display: "none"} }>
                     <div className="row no-gutters">
                     { (deliveryAdress.length==0)&& 
           
           <img className="rounded img-fluid" src="assets/img/empty.png" alt="Logo"/>
          
          
}

        { deliveryAdress.length > 0 && deliveryAdress.map(item => {  
                        return (  
                           
                       <div className="col-md-5 shop_cls">
                              <div className="product">
                                 
                                    <div className="product-body" style={{height :120}}  >
                                       <h5 style={{color:item.address_status=='true'?'green':''}}>{item.delivery_fname}{item.address_status=='true'?'*':''}</h5>
                                       <h6><strong>{item.delivery_address}</strong></h6>
                                       <h6>House Name/Number  : {item.delivery_place}</h6>
                                       <h6>Phone Number : {item.delivery_phone}</h6>
                                   
                                    </div>
                                    <div className="product-footer">
                                       <center>
                                       <button type="button" className="btn btn-success btn-lg" onClick={()=>{
                                             apiRequestPOST(`${BASE_URL}app_user_ctrl/get_delivery_address_current_change`,{id:item.id} ,false) 
                                             .then(async (response) => { 
                                           apiRequestPOST(`${BASE_URL}app_user_ctrl/get_delivery_address_current`,{latitude:shopLangititude,longitude:shopLongtitude},false)
                                         .then((response) => { 
                                          
                                          setCurrentDeliveryAdress(response.delivery_address)  
                                          setShopdistance(response.distance.km)  
                                          response.distance.km>=freeDelivery?setAmountDeliveryDistance((response.distance.km-freeDelivery)*afterFreeDelivery)
                                          :setAmountDeliveryDistance(0)
                                          setCurrentTab('addresslist') 

                                          
               
               apiRequestPOST(`${BASE_URL}app_user_ctrl/get_delivery_address`,'',true)
               .then((response) => { 
                  setDeliveryAdress(response)  
               }, (error) => {
                 console.log(error);
               }); 
               
                                         }, (error) => {
                                           console.log(error);
                                         }); 
                                             setCurrentTab('addresslist')   
                                             })
                                             .catch((error) => {  
                                              console.log(error); 
                                             });
                                               
                                             }}> Select </button>
                                             
                                    
                                          </center>
                          </div>
                                
                              </div>
                           </div>  
                      
                          

              
    ) }  ) 
   }
    <div className="col-md-12">
        <center ><br/>
           <button type="button" id='addNewId' className="btn btn-success btn-lg" onClick={()=>{
                                               setTab('')   
                                             }}> Add New </button> 
                                             
                                             </center>  
                                             </div>
                                             </div>
                                             </div>

 
                        <div   style={((currenttab=='')&&(tab==''))||currentDeliveryAdress==null?{display: "block"}:{display: "none"} }>
                         <div className="card card-body account-right">
                           <div className="widget">
                              <div className="section-header">
                                 <h5 className="heading-design-h5">
                                    Delivery Address
                                 </h5>
                              </div>

                              
                            { <Formik
       initialValues={{
          name: '',
         address: googleAdress,
         langititude:googleLangititude,
         longtitude:googleLongtitude,
         phonenumber: '',
         place: '',
         address_statuss:'',
       }} 
       enableReinitialize
       validationSchema={registrationSchema}
       onSubmit={values => {
         setOrderButton({val:"disabled"})
         apiRequestPOST(`${BASE_URL}app_user_ctrl/add_user_address`,values ,true)
         .then((response) => { 
            if(response==1){   
            document.getElementById('closee').click() 
            window.location.reload(false);
            setOrderButton({val:""})
               apiRequestPOST(`${BASE_URL}app_user_ctrl/get_delivery_address`,'',true)
               .then((response) => { 
                  setDeliveryAdress(response)  
                  setCurrentTab('addresslist')
                  setEditTab('addresslist')
                  setTab('addresslist')
                  }, (error) => {
                 console.log(error);
               });
            toast.success('Process Successfully', {
               position: "top-center",
               autoClose: 5000,
               hideProgressBar: false,
               closeOnClick: true,
               pauseOnHover: true,
               draggable: true,
               progress: undefined,
               });
            }else{
               toast.error('Process Failed', {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  });
            }

         }, (error) => {
           console.log(error);
         }).finally(()=>{ 
            setOrderButton({val:""})
         });

         // console.log(values);
       }}
     >
       {({ errors, touched,values }) => (
         <Form>
                              <div className="row"> 
                                    <div className="col-sm-12">
                                    <div style={{ height: '40vh', width: '100%' }}>
                <GoogleMapReact
                  //   onClick={getlocalNameByCoord}
                    bootstrapURLKeys={{ key: 'AIzaSyD_ivQdtQK5UuTcspqtw5u5_r27Xbi_f9w' }}
                    defaultCenter={center}
                    defaultZoom={zoom}
                    onDrag={(e)=>{
                        
                     setCurrentState({
                        langititude: e.center.lat(),
                        longtitude:e.center.lng()
                     })  
                  }}
                  
                  onGoogleApiLoaded={async (e) => {
                     await getlocalNameByCoord(currentState.langititude, currentState.longtitude)
                   }}
                   onDragEnd={async (e) => {
                     await getlocalNameByCoord(currentState.langititude, currentState.longtitude)
                   }}
                >
                     <AnyReactComponent
                           lat={currentState.langititude}
                           lng={currentState.longtitude}
                           text="My Marker"
                     />
                </GoogleMapReact>
            </div>
            <label className="control-label">Address <span className="required">*</span></label>
      <Field className="form-control border-form-control" value={values.address} placeholder="Address" name='address' type="hidden" />
      <Field className="form-control border-form-control" value={values.address} placeholder="Address" name='address' type="text" disabled/>
      {errors.address && touched.address ? (<div style={{ color: 'red' }}>{errors.address}</div>  ) : null}             
                                </div>
                                </div>

                                 <div className="row"> 
                                    <div className="col-sm-12">
                                       <div className="form-group">
                                          <Field type="hidden" name="langititude"  value={values.langititude}/>
                                          <Field type="hidden" name="longtitude"  value={values.longtitude}/>
                                          <label className="control-label">Name <span className="required">*</span></label>
                                          <Field className="form-control border-form-control" placeholder="Name" type="text" name="name"/>
                                          {errors.name && touched.name ? (<div style={{ color: 'red' }}>{errors.name}</div>  ) : null}
                                       </div>
                                    </div>  
                                 </div>
                                 
                                 <div className="row"> 
                                    <div className="col-sm-12">
                                       <div className="form-group">
                                          <label className="control-label">House Name/Number <span className="required">*</span></label>
                                          <Field className="form-control border-form-control" name="place"  placeholder="House Name/Number" type="text"/>
                                          {errors.place && touched.place ? (<div style={{ color: 'red' }}>{errors.place}</div>  ) : null}
                                       </div>
                                    </div>  
                                 </div>
                                 
                                 
                                 <div className="row"> 
                                    <div className="col-sm-12">
                                       <div className="form-group">
                                          <label className="control-label">Phone Number <span className="required">*</span></label>
                                          <Field className="form-control border-form-control" placeholder="Phone Number" name="phonenumber" type="text"/>
                                          {errors.phonenumber && touched.phonenumber ? (<div style={{ color: 'red' }}>{errors.phonenumber}</div>  ) : null}
                                       </div>
                                    </div>  
                                 </div>
                               
                                   <div className="custom-control custom-checkbox mb-3">
                                       <Field type="checkbox" name='address_statuss'  className="custom-control-input" id="customCheckbills" 
                                       />
                                       <label className="custom-control-label" htmlFor="customCheckbills">Use my default address</label>
                                    </div> 
                               
                          
                                 <div className="row">
                                    <div className="col-sm-12 text-right">
                                       <button type="button" className="btn btn-danger btn-lg" onClick={()=>{
                                                setTab('addresslist')
                                             }}> Cancel </button> &nbsp;
                                       <button type="submit"  disabled = {(orderButton.val)? "disabled" : ""} className="btn btn-success btn-lg"> Add Address </button>
                                    </div>
                                 </div>
                                 </Form>
       )}
     </Formik>
}
                           </div>
                        </div>
                     </div>


                     <div   style={(currenttab=='')||(edittab=='addresslist')?{display: "none"}:{display: "inherit"} }>
                         <div className="card card-body account-right">
                           <div className="widget">
                              <div className="section-header">
                                 <h5 className="heading-design-h5">
                                 Update  Delivery Address  
                                 </h5>
                              </div>

                              
                            { <Formik
       initialValues={{
          id: editDeliveryAdress.id,
          name: editDeliveryAdress.delivery_fname,
          address: editGoogleAdress,
          langititude:editGoogleLangititude,
          longtitude:editGoogleLongtitude,
         phonenumber: editDeliveryAdress.delivery_phone,
         place: editDeliveryAdress.delivery_place,
         address_status:editDeliveryAdress.address_status,
       }} 
       enableReinitialize
       validationSchema={registrationSchema}
       onSubmit={values => {
         setRegistarionButton({val:"disabled"})
         apiRequestPOST(`${BASE_URL}app_user_ctrl/update_user_address`,values ,false)
         .then((response) => { 
            if(response==1){   
            document.getElementById('closee').click()
               setRegistarionButton({val:""})
               apiRequestPOST(`${BASE_URL}app_user_ctrl/get_delivery_address`,'',true)
               .then((response) => { 
                  setDeliveryAdress(response)  
               }, (error) => {
                 console.log(error);
               });
            toast.success('Update Successfully', {
               position: "top-center",
               autoClose: 5000,
               hideProgressBar: false,
               closeOnClick: true,
               pauseOnHover: true,
               draggable: true,
               progress: undefined,
               });
            }else{
               toast.error('Process Failed', {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  });
            }

         }, (error) => {
           console.log(error);
         }).finally(()=>{ 
            setRegistarionButton({val:""})
         });

         // console.log(values);
       }}
     >
       {({ errors, touched,values }) => (
         <Form>
                              <div className="row"> 
                                    <div className="col-sm-12">
                                    <div
                                     style={{ height: '40vh', width: '100%' }}>
                
                {(editGoogleLangititude && editGoogleLongtitude )&&
                  <GoogleMapReact
                  //   onClick={getlocalNameByCoord}
                    bootstrapURLKeys={{ key: 'AIzaSyD_ivQdtQK5UuTcspqtw5u5_r27Xbi_f9w' }}
                    defaultCenter={center1}
                    defaultZoom={zoom}
                    onDrag={(e)=>{
                     setEditCurrentState({
                        langititude: e.center.lat(),
                        longtitude:e.center.lng()
                     })
                  }}
                  onDragEnd={(e)=>{
                     getlocalNameByCoord(editCurrentState.langititude,editCurrentState.longtitude) 
                  }}
                >
                     <AnyReactComponent
                           lat={editCurrentState.langititude}
                           lng={editCurrentState.longtitude}
                           text="My Marker"
                     />
                </GoogleMapReact>
    }
            </div>
            <label className="control-label">Address <span className="required">*</span></label>
      <Field value={values.id} name='id' type="hidden"/>
      <Field className="form-control border-form-control" value={values.address} placeholder="Address" name='address' type="hidden"/>
      <Field className="form-control border-form-control" value={values.address} placeholder="Address" name='address' type="text" disabled/>
      {errors.address && touched.address ? (<div style={{ color: 'red' }}>{errors.address}</div>  ) : null}             
                                </div>
                                </div>

                                 <div className="row"> 
                                    <div className="col-sm-12">
                                       <div className="form-group">
                                          <Field type="hidden" name="langititude"  value={values.langititude}/>
                                          <Field type="hidden" name="longtitude"  value={values.longtitude}/>
                                          <label className="control-label">Name <span className="required">*</span></label>
                                          <Field className="form-control border-form-control" placeholder="Name" type="text" name="name"/>
                                          {errors.name && touched.name ? (<div style={{ color: 'red' }}>{errors.name}</div>  ) : null}
                                       </div>
                                    </div>  
                                 </div>
                                 
                                 <div className="row"> 
                                    <div className="col-sm-12">
                                       <div className="form-group">
                                          <label className="control-label">House Name/Number <span className="required">*</span></label>
                                          <Field className="form-control border-form-control" name="place"  placeholder="House Name/Number" type="text"/>
                                          {errors.place && touched.place ? (<div style={{ color: 'red' }}>{errors.place}</div>  ) : null}
                                       </div>
                                    </div>  
                                 </div>
                                 
                                 
                                 <div className="row"> 
                                    <div className="col-sm-12">
                                       <div className="form-group">
                                          <label className="control-label">Phone Number <span className="required">*</span></label>
                                          <Field className="form-control border-form-control" placeholder="Phone Number" name="phonenumber" type="text"/>
                                          {errors.phonenumber && touched.phonenumber ? (<div style={{ color: 'red' }}>{errors.phonenumber}</div>  ) : null}
                                       </div>
                                    </div>  
                                 </div>
                               
                               
                                 <div className="custom-control custom-checkbox mb-3">
                                       <Field type="checkbox" name='address_status'  className="custom-control-input" id="customCheckbill" 
                                       />
                                       <label className="custom-control-label" htmlFor="customCheckbill">Use my default address</label>
                                    </div>

                                 <div className="row">
                                    <div className="col-sm-12 text-right">
                                       <button type="button" className="btn btn-danger btn-lg" onClick={()=>{
                                                setEditTab('addresslist')
                                             }}> Cancel </button> &nbsp;
                                       <button type="submit"  disabled = {(registarionButton.val)? "disabled" : ""} className="btn btn-success btn-lg"> Update Address </button>
                                    </div>
                                 </div>
                                 </Form>
       )}
     </Formik>
}
                           </div>
                        </div>
                     </div>





                              </div>
                           </div>
                        </div>


                   <div className="card checkout-step-two">
                           <div className="card-header" id="headingTwo">
                              <h5 className="mb-0">
                              <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                <span className="number">2</span> Payment
                                 </button>
                              </h5>
                           </div>
                           <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                              <div className="card-body">
                                
                                    <div className="custom-control custom-radio">
                                       <input type="radio" id="customRadio1" name="customRadio" checked className="custom-control-input"/>
                                       <label className="custom-control-label" htmlFor="customRadio1">Cash on Delivery</label>
                                    </div>
                                    <p>In this online shopiing cart only cash on delivery is available.</p>
                                  
                              </div>
                           </div>
                        </div>
                      
                     </div>
                  </div>
               </div>
               <div className="col-md-4">
                  <div className="card">
                     <h5 className="card-header">My Cart <span className="text-secondary float-right">( {contextData.usercartlist.length}  item)</span></h5>
                     <div className="card-body pt-0 pr-0 pl-0 pb-0">
 
                     {  
            contextData.usercartlist.map(item => { 
               let original_price = item.original_price;
               let selling_price = item.selling_price;
            if(contextData?.country=='INDIA'){
               let original_price = item.original_price;
               let selling_price = item.selling_price;
}else{
let original_price = item.original_price_abroad;
let selling_price = item.selling_price_abroad;
}

            let discountamount = original_price-selling_price; 
            let discount_percent = (discountamount/original_price)*100; 
             subtotal=parseFloat(subtotal)+ (parseFloat(selling_price) * parseFloat(item.user_cart_qty));
                        return (  
                           <div className="cart-list-product">
                           <Link className="float-right remove-cart" onClick={() => remove_to_cart(item.user_cart_id)} ><i className="mdi mdi-delete"></i></Link>
                           <img className="img-fluid" src={`${BASE_URL}assets/products/original/${item.pic1}`} alt=""/>
                           <span className="badge badge-success">{discount_percent.toFixed(0)}% OFF</span>
                           <Link  data-toggle="offcanvas"><h5 style={{cursor:'pointer',fontSize:16}} onClick={() => 
                         history.push({
                           pathname: "/single", 
                              state:{productId: item.id} 
                        })
                        } >{item.name}</h5></Link>
                       <h6><strong>Quantity</strong> : &nbsp;
              <span className="product" style={{padding: 2}}>
              <span className="input-group-btn"  onClick={() => update_to_cart(item.id,item.user_cart_id,parseFloat(item.user_cart_qty)-1)}><button  className="btn btn-theme-round btn-number" type="button">-</button></span> &nbsp;
              <strong style={{fontSize: 14}}>{item.user_cart_qty}</strong> &nbsp;
               <span className="input-group-btn" onClick={() => update_to_cart(item.id,item.user_cart_id,parseFloat(item.user_cart_qty)+1)}><button className="btn btn-theme-round btn-number" type="button">+</button> </span>
             </span>
             {/* &nbsp;{item.product_unit} */}
               </h6>
                           <p className="offer-price mb-0">{contextData?.country=='INDIA'? '₹'+ item.selling_price : '$' + item.selling_price_abroad} <i className="mdi mdi-tag-outline"></i> <span className="regular-price">{contextData?.country=='INDIA'? '₹' + item.original_price : '$' + item.original_price_abroad}</span></p>
                        </div>
 ) }  ) 
}
<div className="cart-sidebar-footer">
           <div className="cart-store-details">
              {/* <p>Sub Total <strong className="float-right">₹{subtotal.toFixed(2)}  </strong></p>  */}
               <h6>Bill Details</h6>
               <hr/>
              <p>Item Total  <strong className="float-right text-danger">₹{subtotal.toFixed(2)}</strong></p>
            
              <p>Delivery Fee | {shopdistance!=''&&shopdistance>0&&shopdistance.toFixed(2)} kms  <strong className="float-right text-danger">
                 {
                 shopdistance<=maxDistance?
               //   setAmountDeliveryDistance(subtotal)
               (amountDeliveryDistance==0)?'Free':amountDeliveryDistance.toFixed(2)
                 :"Out for Delivery"
                 }
                 
                 
                 </strong></p>
              <hr/> 
              <h6>Bill Total  <strong className="float-right text-danger">₹{(parseFloat(subtotal)+parseFloat(amountDeliveryDistance)).toFixed(2)}</strong> </h6>
            

           </div> 
 
        
           {
(currentDeliveryAdress?.id!=''&&currentDeliveryAdress?.id!=null)?

           shopdistance<=maxDistance?
           subtotal>0?
          <button className="btn btn-secondary btn-lg btn-block text-left" type="button"   disabled = {(orderButton.val)? "disabled" : ""}
          onClick={() => placeorder() }
          >
            <center> <i className="mdi mdi-cart-outline"></i><strong> Pay &nbsp;{(parseFloat(subtotal)+parseFloat(amountDeliveryDistance)).toFixed(2)}₹</strong> </center>
            </button>
            
       :  <button disabled className="btn btn-secondary btn-lg btn-block text-left" type="button">
       <center> <i className="mdi mdi-cart-outline"></i><strong> Pay &nbsp;{(parseFloat(subtotal)+parseFloat(amountDeliveryDistance)).toFixed(2)}₹</strong> </center>
       </button>
   : <><center style={{color:'Red',fontWeight:'bold'}}>Out for Delivery</center>
   <button disabled className="btn btn-secondary btn-lg btn-block text-left" type="button">
   <center> <i className="mdi mdi-cart-outline"></i><strong> Pay &nbsp;{(parseFloat(subtotal)+parseFloat(amountDeliveryDistance)).toFixed(2)}₹</strong> </center>
   </button> </> 
   :<><center style={{color:'Red',fontWeight:'bold'}}>Please Select Delivery Address</center>
   <button disabled className="btn btn-secondary btn-lg btn-block text-left" type="button">
   <center> <i className="mdi mdi-cart-outline"></i><strong> Pay &nbsp;{(parseFloat(subtotal)+parseFloat(amountDeliveryDistance)).toFixed(2)}₹</strong> </center>
   </button> </> 
   }
     
           </div>
                   


                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <section className="section-padding bg-white border-top">
         <div className="container">
            <div className="row">
               <div className="col-lg-4 col-sm-6">
                  <div className="feature-box">
                     <i className="mdi mdi-truck-fast"></i>
                     <h6>Free & Next Day Delivery</h6>
                     <p>Lorem ipsum dolor sit amet, cons...</p>
                  </div>
               </div>
               <div className="col-lg-4 col-sm-6">
                  <div className="feature-box">
                     <i className="mdi mdi-basket"></i>
                     <h6>100% Satisfaction Guarantee</h6>
                     <p>Rorem Ipsum Dolor sit amet, cons...</p>
                  </div>
               </div>
               <div className="col-lg-4 col-sm-6">
                  <div className="feature-box">
                     <i className="mdi mdi-tag-heart"></i>
                     <h6>Great Daily Deals Discount</h6>
                     <p>Sorem Ipsum Dolor sit amet, Cons...</p>
                  </div>
               </div>
            </div>
         </div>
      </section>
      
      <Footer/>
        
        </React.Fragment>
       )
   }
   
    