 
    import React,{useState,useContext,useEffect} from 'react'
    import { Link,useLocation } from 'react-router-dom'
    import Header from './Header'
    import Footer from './Footer'
    import { userDetailContext } from '../App';
    import { apiRequestPOST } from '../utils/ApiCall';
    import { BASE_URL } from '../utils/Constants';
    import { toast } from 'react-toastify'; 
    import { Field, Form, Formik } from 'formik'; 
    import * as Yup from 'yup';
    import { useHistory } from "react-router-dom";
    
    export default function My_profile() {
      const logout= () => { 
         localStorage.removeItem('userName') 
         localStorage.removeItem('userCartist')
         localStorage.removeItem('userCartiststore_id')
         localStorage.removeItem('userCartistbranch_id')
         localStorage.removeItem('orderlistid')
         localStorage.removeItem('productId')
         localStorage.removeItem('userToken')
         history.push({
            pathname: "/home",
            state: ''
         }) 
      }
      
      let history = useHistory();
      var contextData = useContext(userDetailContext);
      const [registarionButton,setRegistarionButton] = useState('false') 
      const phoneRegExp = /^!*([0-9]!*){10,}$/

      const registrationSchema = Yup.object().shape({
         name: Yup.string().required('Required'),
         phonenumber: Yup.string().matches(phoneRegExp, 'Phone number is not valid').required('Required')
           .test('Unique Phone Number', 'Phone Number already in use',  
           function (value) { 
              if(value>=10) {
               return new Promise((resolve, reject) => {
                 apiRequestPOST(`${BASE_URL}app_user_ctrl/my_phonenumbercheck`,{value:value} ,false) 
                       .then((res) => {
                        //   console.log(res);
                          if(res==1){  resolve(true)} else{ resolve(false) } 
                       })
                       .catch((error) => { 
                               resolve(false)
                       })
               })
              }
           }
       ), 
         email: Yup.string().email('Invalid email').required('Required')
         .test('Unique Email', 'Email already in use',  
         function (value) { 
           const status =  (typeof value == 'string')&&value.match(
               /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
             ); 
            if(status) {
             return new Promise((resolve, reject) => {
               apiRequestPOST(`${BASE_URL}app_user_ctrl/my_emailcheck`,{value:value} ,false) 
                     .then((res) => {
                        // console.log(res);
                        if(res==1){  resolve(true)} else{ resolve(false) } 
                     })
                     .catch((error) => { 
                             resolve(false)
                     })
             })
            }
         }
     ), 
       });
        return (
         <React.Fragment> 
         <Header/>
         
         <section className="account-page section-padding">
         <div className="container">
            <div className="row">
            <div className="col-lg-10 col-md-12 mx-auto">
                  <div className="row no-gutters">
                  <div className="col-md-4 col-lg-3">
                        <div className="card account-left">
                           <div className="user-profile-header">
                              {/* <img alt="logo" src="assets/img/user.jpg"/> */}
                              <h5 className="mb-1 text-secondary"><strong>Hi </strong> {contextData?.username?.name}</h5>
                                 <p> {contextData?.username?.mob}</p>
                           </div>
                           <div className="list-group">
                              <Link to="/my_profile" className="list-group-item list-group-item-action active"><i aria-hidden="true" className="mdi mdi-account-outline"></i>  My Profile</Link>
                              <Link to="/my_address" className="list-group-item list-group-item-action"><i aria-hidden="true" className="mdi mdi-map-marker-circle"></i>  My Address</Link>
                              <Link to="/wishlist" className="list-group-item list-group-item-action"><i aria-hidden="true" className="mdi mdi-heart-outline"></i>  Wish List </Link>
                              <Link to="/rating" className="list-group-item list-group-item-action"><i aria-hidden="true" className="mdi mdi-star-outline"></i>  Store Rating </Link>
                                  <Link to="/orderlist" className="list-group-item list-group-item-action"><i aria-hidden="true" className="mdi mdi-format-list-bulleted"></i>  Order List</Link> 
                              <Link   className="list-group-item list-group-item-action"
                                 onClick={()=>logout()}><i aria-hidden="true" className="mdi mdi-lock"></i>  Logout</Link>
                           </div>
                        </div>
                     </div>
                     <div className="col-md-8 col-lg-9">
                        <div className="card card-body account-right">
                           <div className="widget">
                              <div className="section-header">
                                 <h5 className="heading-design-h5">
                                    My Profile
                                 </h5>
                              </div>
                              <Formik
       initialValues={{
         name: contextData?.username?.name,
         phonenumber: contextData?.username?.mob,
         email: contextData?.username?.email, 
       }} 
       validationSchema={registrationSchema}
       onSubmit={values => {
         setRegistarionButton({val:"disabled"})
         apiRequestPOST(`${BASE_URL}app_user_ctrl/my_profile_update`,values ,false)
         .then(async(response) => { 
            if(response!=0){    
               localStorage.setItem('userName',JSON.stringify(response))  
               await   contextData.setUsername(response);   
               setRegistarionButton({val:""})
            toast.success(' Successfully', {
               position: "top-center",
               autoClose: 5000,
               hideProgressBar: false,
               closeOnClick: true,
               pauseOnHover: true,
               draggable: true,
               progress: undefined,
               });
            }else{
               toast.error('Registered Failed', {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  });
            }

         }, (error) => {
           console.log(error);
         }).finally(()=>{ 
            setRegistarionButton({val:""})
         });

         // console.log(values);
       }}
     >
       {({ errors, touched,values }) => (
         <Form>
                                 <div className="row">
                                    <div className="col-sm-12">
                                       <div className="form-group">
                                          <label className="control-label"> Name <span className="required">*</span></label>
                                          <Field className="form-control border-form-control" 
                                           placeholder="Name" type="text"   
                                          name= "name" value={values.name}
                                          />
                                       {errors.name && touched.name ? (<div style={{ color: 'red' }}>{errors.name}</div>  ) : null}
                                      </div>
                                    </div> 
                                 </div>
                                 <div className="row">
                                    <div className="col-sm-6">
                                       <div className="form-group">
                                          <label className="control-label">Phone <span className="required">*</span></label>
                                          <Field className="form-control border-form-control" value={values.phonenumber} placeholder="Phone Number" type="text" name="phonenumber"/>
                                          {errors.phonenumber && touched.phonenumber ? (<div style={{ color: 'red' }}>{errors.phonenumber}</div>  ) : null}
                                       </div>
                                    </div>
                                    <div className="col-sm-6">
                                       <div className="form-group">
                                          <label className="control-label">Email Address <span className="required">*</span></label>
                                          <Field className="form-control border-form-control " value={values.email} placeholder="Email Address"   type="text" name="email"/>
                                          {errors.email && touched.email ? (<div style={{ color: 'red' }}>{errors.email}</div>  ) : null}
                                      </div>
                                    </div>
                                 </div>
                             
                                
                               
                                 <div className="row">
                                    <div className="col-sm-12 text-right">
                                       {/* <button type="button" className="btn btn-danger btn-lg"> Cancel </button> &nbsp; */}
                                       <button type="submit" disabled = {(registarionButton.val)? "disabled" : ""} className="btn btn-success btn-lg"> Save Changes </button>
                                    </div>
                                 </div>
                                 </Form>
       )}
     </Formik>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <section className="section-padding bg-white border-top">
         <div className="container">
            <div className="row">
               <div className="col-lg-4 col-sm-6">
                  <div className="feature-box">
                     <i className="mdi mdi-truck-fast"></i>
                     <h6>Free & Next Day Delivery</h6>
                     <p>Lorem ipsum dolor sit amet, cons...</p>
                  </div>
               </div>
               <div className="col-lg-4 col-sm-6">
                  <div className="feature-box">
                     <i className="mdi mdi-basket"></i>
                     <h6>100% Satisfaction Guarantee</h6>
                     <p>Rorem Ipsum Dolor sit amet, cons...</p>
                  </div>
               </div>
               <div className="col-lg-4 col-sm-6">
                  <div className="feature-box">
                     <i className="mdi mdi-tag-heart"></i>
                     <h6>Great Daily Deals Discount</h6>
                     <p>Sorem Ipsum Dolor sit amet, Cons...</p>
                  </div>
               </div>
            </div>
         </div>
      </section>
  
      
      <Footer/>
     
     </React.Fragment>
    )
}
