
import React, { useEffect, useState, useContext,Redirect } from 'react'
import { Link,useLocation } from 'react-router-dom'
import Header from './Header'
import Footer from './Footer'
import get from 'lodash.get'
import { useHistory } from "react-router-dom";
import { BASE_URL } from '../utils/Constants'
import { apiRequestPOST } from '../utils/ApiCall'
import { toast } from 'react-toastify'
import { userDetailContext } from '../App';
 
export default function Home() {
   var contextData = useContext(userDetailContext);
   let history = useHistory();
   const [shopproduct, setShopProduct] = useState([]);
   const [shopcategory, setShopCategory] = useState([]);
   const [isCheckedCategory, setIsCheckedCategory] = useState([]);
   const [isCheckedCategoryId, setIsCheckedCategoryId] = useState([]);
   const [isCheckedBrand, setIsCheckedBrand] = useState([]);  
   const [sortOrder, setSortOrder] = useState([]);
   const [priceSortmin, setPriceSortMin] = useState(1);
   const [priceSortmax, setPriceSortMax] = useState(1000000);
   const [allBrand, setAllBrand] = useState([]);
   const [allBrandFilter, setAllBrandFilter] = useState('');
   const [allCategoryFilter, setAllCategoryFilter] = useState('');
   const [storeRating, setStoreRating] = useState('');
   const [emptyCheck, setemptyCheck] = useState(0);
   const [store_type, setstore_type] = useState('');
    
   
   const location = useLocation();
  useEffect(() => {
      try {
         // const data = location.state 
         const datax = location.search   
         const store_typeId = new URLSearchParams(datax).get("store_typeId"); 
         const store_type = new URLSearchParams(datax).get("store_type");     
         const data ={ store_typeId: store_typeId, store_type: store_type }

         console.log('data',data);
         if (data) {
            localStorage.setItem('datahistory', JSON.stringify(data))
              contextData.setDataHistory(JSON.parse(data));
              contextData.setShopDatails(data.shop_datails);
                contextData.setShopIdz(data.id);
                contextData.setBranchId(data.shop_datails.branch_id);
              }
      } catch (err) {
         console.log('er')
      }
      
      var datahistory = JSON.parse(localStorage.getItem('datahistory')) 
      setstore_type(datahistory?.store_type)
      apiRequestPOST(`${BASE_URL}app_ctrl/get_businesscategories`, { store_typeId: datahistory?.store_typeId }, false)
         .then((response) => {
            setShopCategory(response)
            setShopProduct(response)
            const parseDataforCheckbox = response.map((it) => {
               return (
                  it.subs.length>0&&
                  { id: it.category_id, category_id: it.category_id, category_name: it.category_name, status: false }
               )
            })
            const categoryCheckboxIdarray = []
            response.map((itm) => { 
               itm.subs.length>0&&
               categoryCheckboxIdarray.push(itm.category_id)
            })
            setIsCheckedCategoryId(categoryCheckboxIdarray)

            setIsCheckedCategory(parseDataforCheckbox)
         }, (error) => {
            console.log(error);
         });

         
      apiRequestPOST(`${BASE_URL}app_ctrl/get_allbrand`,'', false)
      .then(async (response) => {   
         localStorage.setItem('allbrand',JSON.stringify(response));  
         // await setAllBrand(response) 
      }, (error) => {       
         console.log(error);
      }) 

      apiRequestPOST(`${BASE_URL}app_ctrl/store_rating`,{store_id: contextData?.shopIdz}, false)
      .then(async (response) => {   
         await setStoreRating(response)   
      }, (error) => {       
         console.log(error);
      }) 
   }, [location])
       
   const get_shopcategoryproduct = (order) => {
      setSortOrder(order)
      apiRequestPOST(`${BASE_URL}app_ctrl/get_shopcategoryproduct`, { shopid: contextData?.shopIdz,branchid:contextData?.branchId, id: isCheckedCategoryId, price: priceSortmin,pricemax:priceSortmax,order:order, brand: isCheckedBrand }, false)
                           .then((response) => {
                              console.log('response',response);
                              setShopProduct(response)
                           }, (error) => {
                              console.log(error);
                           })
                        }

                   
                        const get_shopcategoryproductpricemin = (price) => { 
                           
                           setPriceSortMin(price)
                        apiRequestPOST(`${BASE_URL}app_ctrl/get_shopcategoryproduct`, { shopid: contextData?.shopIdz,branchid:contextData?.branchId, id: isCheckedCategoryId, price: price,pricemax:priceSortmax,order:sortOrder, brand: isCheckedBrand }, false)
                                                .then((response) => {
                                                   console.log('response',response);
                                                   setShopProduct(response)
                                                }, (error) => {
                                                   console.log(error);
                                                })
                                             }

                       const get_shopcategoryproductpricemax = (price) => { 
                           
                                                setPriceSortMax(price)
                                             apiRequestPOST(`${BASE_URL}app_ctrl/get_shopcategoryproduct`, { shopid: contextData?.shopIdz,branchid:contextData?.branchId, id: isCheckedCategoryId, price: priceSortmin,pricemax:price,order:sortOrder, brand: isCheckedBrand }, false)
                                                                     .then((response) => {
                                                                        console.log('response',response);
                                                                        setShopProduct(response)
                                                                     }, (error) => {
                                                                        console.log(error);
                                                                     })
                                                                  }
   const add_to_cart = (ProductId) => {
      //   console.log(ProductId);
      const shopIdStringify = localStorage.getItem('datahistory')
      const shopId =contextData?.shopIdz;
      const branchId =contextData?.branchId; 

      const userCartistbranch_id = JSON.parse(localStorage.getItem('userCartistbranch_id'));
      if (branchId == userCartistbranch_id || userCartistbranch_id=='') {
         apiRequestPOST(`${BASE_URL}app_user_ctrl/product_add_to_cart`, { product_id: ProductId, store_id: shopId, branch_id: branchId, qty: 1 }, false)
            .then((response) => {

               apiRequestPOST(`${BASE_URL}app_user_ctrl/user_cart_list`, '', false)
                  .then(async (response1) => {
                     await contextData.setUserCartist(response1.user_cart_list);
                     localStorage.setItem('userCartist', JSON.stringify(response1.user_cart_list))
                     localStorage.setItem('userCartiststore_id', JSON.stringify(response1.store_id))
                     localStorage.setItem('userCartistbranch_id', JSON.stringify(response1.branch_id))
                  })
                  .catch((error) => {
                     console.log(error);
                  });

               if (response == 0) {
                  toast.error('Failed, Try again', {
                     position: "top-center",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                  });
               }
               if (response == 1) {

                  toast.success('Updated cart', {
                     position: "top-center",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                  });
               }
               if (response == 2) {
                  toast.error('Not enough stock !!!', {
                     position: "top-center",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                  });
               }
               if (response == 3) {
                  toast.error('Invalid Quantity !!!', {
                     position: "top-center",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                  });
               }
            }, (error) => {
               console.log(error);
            });
      } else {
         localStorage.setItem('diffrent_branch_userCartist', JSON.stringify({ product_id: ProductId, store_id: shopId, branch_id: branchId, qty: 1 }))
         document.getElementById('diffrent_branch').click()
      }
   }

   const categoryOnChange = (catId, status) => {
      const categoryCheckbox = []
      const categoryCheckboxId = []
      isCheckedCategory.map(itm => {
         // console.log('>> >',itm)
         if (itm.category_id == catId) {
            categoryCheckbox.push({ id: itm.category_id, category_id: itm.category_id, category_name: itm.category_name, status: !status })
            if (status != true) {
               categoryCheckboxId.push(itm.category_id)
            }
         }
         else {
            categoryCheckbox.push({ id: itm.category_id, category_id: itm.category_id, category_name: itm.category_name, status: itm.status })
            if (itm.status == true) {
               categoryCheckboxId.push(itm.category_id)
            }
         }
      })
      setIsCheckedCategory(categoryCheckbox);
      setIsCheckedCategoryId(categoryCheckboxId)
      //  console.log("sss",categoryCheckboxId);
      apiRequestPOST(`${BASE_URL}app_ctrl/get_shopcategoryproduct`, { shopid: contextData?.shopIdz,branchid:contextData?.branchId, id: categoryCheckboxId, price: priceSortmin,pricemax:priceSortmax,order:sortOrder, brand: isCheckedBrand }, false)
         .then((response) => {
            console.log(response);
            setShopProduct(response)
         }, (error) => {
            console.log(error);
         });

   };
 
   const selectShortlistedApplicant = (e, priceId) => {  
      const checked = e.target.checked;
      const priceCheckbox = isCheckedBrand 
      if (checked) {
         !priceCheckbox.includes(priceId) && priceCheckbox.push(priceId)

      } else {
         const index = priceCheckbox.indexOf(priceId);
         if (index > -1) {
            priceCheckbox.splice(index, 1)
         }
      } 

      apiRequestPOST(`${BASE_URL}app_ctrl/get_shopcategoryproduct`, { shopid: contextData?.shopIdz,branchid:contextData?.branchId, id: isCheckedCategoryId, price: priceSortmin,pricemax:priceSortmax,order:sortOrder, brand: priceCheckbox  }, false)
         .then((response) => {
            console.log(response);
            setShopProduct(response)
         }, (error) => {
            console.log(error);
         });


      setIsCheckedBrand(priceCheckbox)
     
   };

  
   const allRanges = document.querySelectorAll(".range-wrap");
   allRanges.forEach(wrap => {
     const range = wrap.querySelector(".range");
     const bubble = wrap.querySelector(".bubble");
   
     range.addEventListener("input", () => {
       setBubble(range, bubble);
     });
     setBubble(range, bubble);
   });
   
   function setBubble(range, bubble) {
     const val = range.value;
     const min = range.min ? range.min : 0;
     const max = range.max ? range.max : 100;
     const newVal = Number(((val - min) * 100) / (max - min));
     bubble.innerHTML = val;
   
     // Sorta magic numbers based on size of the native UI thumb
     bubble.style.left = `calc(${newVal}% + (${8 - newVal * 0.15}px))`;
   }

 
const funallBrandFilter=(searchStr)=>{
   setAllBrandFilter(searchStr)
}
const funallCategoryFilter=(searchStr)=>{
   setAllCategoryFilter(searchStr)
}


const branch_location= contextData?.shopDatails?.branch_location?.split(',')

   return (
      <React.Fragment>
         <Header /> 
        

         <section className="shop-list section-padding">
            <div className="container">
            <div class="row no-gutters">

            <div class="col-sm-5 col-md-4 col-lg-3 col-xl-3 col-xxl-2">
                     <div className="shop-filters">
                        <div id="accordion">
                           <div className="card">
                              <div className="card-header" id="headingOne">
                                 <h5 className="mb-0">
                                    <button className="btn btn-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                       Category <span className="mdi mdi-chevron-down float-right"></span>
                                    </button>
                                 </h5>
                              </div>
                              <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                                 <div className="card-body card-shop-filters">
                              
<div className="input-group">
<input type="text" className="form-control" placeholder="Search By Category" onKeyUp={(e) => {funallCategoryFilter(e.target.value)}}/>
<span className='fa-search_cls'><i className="fa fa-search"></i></span>
  </div>                     
                                    {
                                       isCheckedCategory.length > 0 && isCheckedCategory.map(item => {
                                          if(item.category_name?.toUpperCase().indexOf(allCategoryFilter.toUpperCase()) > -1) {
                                        return ( 
                                          item.category_id&&
                                             <div className="custom-control custom-checkbox">
                                                <input type="checkbox"
                                                   onChange={() => categoryOnChange(item.category_id, item.status)}
                                                   checked={item.status}
                                                   className="custom-control-input" id={item.category_id}
                                                />
                                                <label className="custom-control-label" for={item.category_id}>{item.category_name}
                                                   {/* <span className="badge badge-primary">5% OFF</span> */}
                                                </label>
                                             </div>
                                          )
                                        } })
                                    }

                                 </div>
                              </div>
                           </div>

                           <div className="card">
                              <div className="card-header" id="headingTwo">
                                 <h5 className="mb-0">
                                    <button className="btn btn-link  " data-toggle="collapse" data-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                                       Price <span className="mdi mdi-chevron-down float-right"></span>
                                    </button>
                                 </h5>
                              </div>
                              <div id="collapseTwo" className="collapse show" aria-labelledby="headingTwo" data-parent="#accordion">
                                 <div className="card-body card-shop-filters">

                                 {/* <div className="range-wrap">
                                 <input type="range" class="range" min="0" max="200000"  style={{width:'100%'}} value={priceSort} onChange={(e) => get_shopcategoryproductprice(e.target.value)}/>
  <br/><center><output class="bubble"></output></center>
</div> */}
<div className='row'>
<div class="col-md-5" style={{paddingLeft:'0px',paddingRight:'0px'}}>
<input type='number'  onChange={(e) => get_shopcategoryproductpricemin(e.target.value)} className="form-control"  value={priceSortmin} minLength='1' maxLength='1000000' style={{width:'100%'}}/> 
</div>
<div class="col-md-2" style={{paddingLeft:'0px',paddingRight:'0px'}}>
&nbsp; To 
</div>
<div class="col-md-5" style={{paddingLeft:'0px',paddingRight:'0px'}}>
<input type='number'  onChange={(e) => get_shopcategoryproductpricemax(e.target.value)} className="form-control"  value={priceSortmax} minLength='1' maxLength='1000000' style={{width:'100%'}}/> 
</div> 
</div>
{/* 
 <div className="custom-control custom-checkbox">
                                                <input type="checkbox"
                                                   onChange={() => get_shopcategoryproductprice('0-99')}
                                                  
                                                   className="custom-control-input" 
                                                   id='p0'
                                                />
                                                <label className="custom-control-label"    for='p0'  >  01-99 
                                                </label>
                                             </div>


 <div className="custom-control custom-checkbox">
                                                <input type="checkbox"
                                                   onChange={() => get_shopcategoryproductprice('100-500')}
                                                  
                                                   className="custom-control-input" 
                                                   id='p1'
                                                />
                                                <label className="custom-control-label"    for='p1'  >  100-500 
                                                </label>
                                             </div>

                                             <div className="custom-control custom-checkbox">
                                                <input type="checkbox"
                                                   onChange={() => get_shopcategoryproductprice('501-1000')}
                                                   
                                                   className="custom-control-input" 
                                                   id='p2'
                                                />
                                                <label className="custom-control-label"    for='p2'  >  501-1000 
                                                </label>
                                             </div>
                                             
                                             <div className="custom-control custom-checkbox">
                                                <input type="checkbox"
                                                   onChange={() => get_shopcategoryproductprice('1001-1500')}
                                                   
                                                   className="custom-control-input" 
                                                   id='p3'
                                                />
                                                <label className="custom-control-label"    for='p3'  >  1001-1500 
                                                </label>
                                             </div>
                                     
                                             <div className="custom-control custom-checkbox">
                                                <input type="checkbox" 
                                               
                                                   onChange={() => get_shopcategoryproductprice('1501-2000')}
                                                   
                                                   className="custom-control-input" 
                                                   id='p4'
                                                />
                                                <label className="custom-control-label"    for='p4'  >  1501-2000 
                                                </label>
                                             </div>
  */}
                                 </div>
                              </div>
                           </div>

                           <div className="card">
                              <div className="card-header" id="headingThree">
                                 <h5 className="mb-0">
                                    <button className="btn btn-link  " data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseTwo">
                                       Brand <span className="mdi mdi-chevron-down float-right"></span>
                                    </button>
                                 </h5>
                              </div>
                              <div id="collapseThree" className="collapse  show" aria-labelledby="headingThree" data-parent="#accordion">
                                 <div className="card-body card-shop-filters">
                              
                                 <form className="form-inline mb-3">
<div className="form-group">
<input type="text" className="form-control" placeholder="Search By Brand" onKeyUp={(e) => {funallBrandFilter(e.target.value)}}/>
{/* <button type="button" className="pl-2 pr-2 btn btn-secondary btn-lg"><i className="mdi mdi-file-find"></i></button> */}
<span className='fa-search_cls'><i className="fa fa-search"></i></span>
</div>
</form>
                                 
                                 {
                     allBrand&&allBrand.map(item => {
                        if(item.brand?.toUpperCase().indexOf(allBrandFilter.toUpperCase()) > -1) {

                        return ( 
                                    <div className="custom-control custom-checkbox">
                                       <input type="checkbox" checked={isCheckedBrand.includes(item.id)} onClick={(e) => {
                                          selectShortlistedApplicant(e, item.id);}
                                       } className="custom-control-input" id={item.brand} />
                                       <label className="custom-control-label" for={item.brand}>{item.brand}
                                       </label>
                                    </div> 
                                    
                        )
                   }
                     })
                        }
                                  
                                

                                 </div>
                              </div>
                           </div>
                           
                        </div>
                     </div>
                     <div className="left-ad mt-4">
					  <img className="img-fluid" src="assets/img/left.jpg" alt=""/> 
					  <img className="img-fluid" src="assets/img/left1.gif" alt="" style={{marginTop:'62px'}}/> 
				   </div>
                  </div>

               <div class="col-sm-7 col-md-8 col-lg-9 col-xl-9  col-xxl-10" >
                
                <Link to="/"><img class="img-fluid mb-3 shortbanner_top_img" src="../assets/img/shop2.svg" alt=""/></Link>
                 
                <h2> <center>{store_type}</center></h2>
                 
                   <div className="shop-head">
                   <Link ><span className="mdi mdi-home"></span> Home</Link>  
                
                   {  emptyCheck!=0&&   
                   <div className="btn-group float-right mt-2">
                      <button type="button" className="btn btn-dark dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      Sort by Products &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </button>
                      <div className="dropdown-menu dropdown-menu-right">
                         {/* <Link className="dropdown-item" >Relevance</Link> */}
                         <Link className="dropdown-item"   onClick={() => get_shopcategoryproduct('LH')} >Price (Low to High)</Link>
                         <Link className="dropdown-item"   onClick={() => get_shopcategoryproduct('HL')}>Price (High to Low)</Link>
                         {/* <Link className="dropdown-item" >Discount (High to Low)</Link> */}
                         <Link className="dropdown-item"   onClick={() => get_shopcategoryproduct('AZ')}>Name (A to Z)</Link>
                         <Link className="dropdown-item"   onClick={() => get_shopcategoryproduct('ZA')}>Name (Z to A)</Link>
                      </div>
                   </div>}
                 
                </div>
                {
                   isCheckedCategory&&isCheckedCategory.map(item => {
                      if(item.category_name?.toUpperCase().indexOf(allCategoryFilter.toUpperCase()) > -1) { 
                      return (
                         item.status&&
<><button type="button" className="btn btn-success" style={{marginTop:'12px' }}>{item.category_name}  </button>&nbsp;  </> 
                         )
                 }
                   })
                      } 
                  
                   {
                      shopproduct&&shopproduct.map(item => {
                         return (
item.subs.length > 0&&  
                            <div onLoad={() =>setemptyCheck(1)}>
                               <h5 className="mb-3 cat_head" sty>{item.category_name}</h5> 
                               <div className="row no-gutters_block">
                                  { 
                                     item && item.subs.map(subItm => {

                                        let original_price = subItm.original_price;
                                        let selling_price = subItm.selling_price;
                                        let discountamount = original_price - selling_price;
                                        let discount_percent = (discountamount / original_price) * 100;
                                        return (

<div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 col-xxl-3  shop_cls"> 
                                              {/* {JSON.stringify(contextData.branchId)} */}


                                              <div className="product" style={{ cursor: 'pointer' }} onClick={() =>
                                                    history.push({
                                                       pathname: "/single",
                                                       state: { productId: subItm.id }
                                                    })
                                                 }  >

                                                 <div className="product-header" >
                                                    {discount_percent > 0 ? (
                                                       <span className="badge badge-success">{(discount_percent).toFixed(2)}% OFF</span>
                                                    ) : (<></>)}
                                                    <span className="badge_right badge-success">{subItm.brand_name}</span>
                                                  
                                                   {subItm.pic1?<img className="img-fluid" src={`${BASE_URL}assets/products/original/${subItm.pic1}`} alt="" />
                                                  :<img className="img-fluid" src='http://via.placeholder.com/250x250&text=No Image' alt="" />}
                                               

 

                                                 </div>
                                                 <div className="product-body">

<h5 title={subItm.name}>{
                                                   subItm.name.length >= 230?
                                                   subItm.name.substring(0,230) + "..."
                                                :subItm.name
                                                }</h5>



                                            
<h6><strong><span className="mdi mdi "></span>  </strong> {subItm.qty} {subItm.product_unit}

<span className="float-center">
  <strong> {subItm.category_name}</strong>
  {subItm.brand_name}</span></h6> 
                                                  </div>

                                                  <div className="product-footer"> 
                                                     <p className="offer-price mb-0">

                                                     {contextData?.country=='INDIA'?  '₹'+ subItm.selling_price : '$' + subItm.selling_price_abroad}  <br /><span className="regular-price">{contextData?.country=='INDIA'? subItm.original_price>0&&'₹' + subItm.original_price : subItm.original_price_abroad>0&&'$' + subItm.original_price_abroad}</span>
                                                        </p>
                                                     {(localStorage.getItem("userToken") == null) ?

                                                
                                                     <img src="assets/img/click to buy2.png" alt=""/>

                                                         // <button type="button"  data-target="#bd-example-modal" data-toggle="modal" className="btn btn-secondary btn-sm float-right"><i className="mdi mdi-cart-outline"></i> </button>
                                                         : <button type="button" className="btn btn-secondary btn-sm float-right"
                                                            onClick={() => add_to_cart(subItm.id,subItm.store_id,subItm.branch_id)}
                                                         >Click To Buy <i className="mdi mdi-cart-outline"></i></button>
                                                      }
                                                     
                                                  </div>

                                              </div>

                                           </div>
                                        )
                                     })
                                  }
                               </div> 
                            </div> 
                         )
                      })
                   }
                    
{
 emptyCheck==0&&
 <div className="row no-gutters" style={{marginTop:'22px'}}>
     <div className="col-md-12">
        <center>
        <img src="assets/img/empty_product.png" alt=""  />
        <br/><h2>Empty Product</h2>
        </center>
        </div></div>
}
                   
                </div>

               
                 
               </div>
            </div>
         </section>
      
         <section className="section-padding bg-white border-top">
            <div className="container">
               <div className="row">
                  <div className="col-lg-4 col-sm-6">
                     <div className="feature-box">
                        <i className="mdi mdi-truck-fast"></i>
                        <h6>Free & Next Day Delivery</h6>
                        <p>Lorem ipsum dolor sit amet, cons...</p>
                     </div>
                  </div>
                  <div className="col-lg-4 col-sm-6">
                     <div className="feature-box">
                        <i className="mdi mdi-basket"></i>
                        <h6>100% Satisfaction Guarantee</h6>
                        <p>Rorem Ipsum Dolor sit amet, cons...</p>
                     </div>
                  </div>
                  <div className="col-lg-4 col-sm-6">
                     <div className="feature-box">
                        <i className="mdi mdi-tag-heart"></i>
                        <h6>Great Daily Deals Discount</h6>
                        <p>Sorem Ipsum Dolor sit amet, Cons...</p>
                     </div>
                  </div>
               </div>
            </div>
         </section>


         <Footer />

      </React.Fragment>
   )
}

