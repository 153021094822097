 
   import React from 'react'
   import { Link,useLocation } from 'react-router-dom'
   import Header from './Header'
   import Footer from './Footer'
   
   export default function Faq() {
       return (
        <React.Fragment> 
        <Header/>
        
         <section className="faq-page section-padding">
         <div className="container">
            <div className="row">
               <div className="col-lg-10 mx-auto">
                  <div className="row">
                     <div className="col-lg-6 col-md-6">
                        <div className="card card-body">
                           <div className="accordion" id="accordionExample">
                              <div className="card mb-0">
                                 <div className="card-header" id="headingOne">
                                    <h6 className="mb-0">
                                       <Link  data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                       <i className="icofont icofont-question-square"></i>   Where can I get access to Capital IQ? 
                                       </Link>
                                    </h6>
                                 </div>
                                 <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                                    <div className="card-body">
                                       Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil helvetica, craf.
                                    </div>
                                 </div>
                              </div>
                              <div className="card mb-2 mt-2">
                                 <div className="card-header" id="headingTwo">
                                    <h6 className="mb-0">
                                       <Link  data-toggle="collapse" data-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                                       <i className="icofont icofont-question-square"></i>   How do I get access to case studies? 
                                       </Link>
                                    </h6>
                                 </div>
                                 <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                                    <div className="card-body">
                                       Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil helvetica, craf.
                                    </div>
                                 </div>
                              </div>
                              <div className="card">
                                 <div className="card-header" id="headingThree">
                                    <h6 className="mb-0">
                                       <Link  data-toggle="collapse" data-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree">
                                       <i className="icofont icofont-question-square"></i>   How much should I capitalize? 
                                       </Link>
                                    </h6>
                                 </div>
                                 <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                    <div className="card-body">
                                       Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil helvetica, craf.
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className="col-lg-6 col-md-6">
                        <div className="card card-body">
                           <div className="section-header">
                              <h5 className="heading-design-h5">
                                 Ask us question
                              </h5>
                           </div>
                           <form>
                              <div className="row">
                                 <div className="col-sm-12">
                                    <div className="form-group">
                                       <label className="control-label">Your Name <span className="required">*</span></label>
                                       <input className="form-control border-form-control" value="" placeholder="Enter Name" type="text"/>
                                    </div>
                                 </div>
                              </div>
                              <div className="row">
                                 <div className="col-sm-6">
                                    <div className="form-group">
                                       <label className="control-label">Email Address <span className="required">*</span></label>
                                       <input className="form-control border-form-control " value="" placeholder="ex@gmail.com" type="email"/>
                                    </div>
                                 </div>
                                 <div className="col-sm-6">
                                    <div className="form-group">
                                       <label className="control-label">Phone <span className="required">*</span></label>
                                       <input className="form-control border-form-control" value="" placeholder="Enter Phone" type="number"/>
                                    </div>
                                 </div>
                              </div>
                              <div className="row">
                                 <div className="col-sm-12">
                                    <div className="form-group">
                                       <label className="control-label">Your Message <span className="required">*</span></label>
                                       <textarea className="form-control border-form-control"></textarea>
                                    </div>
                                 </div>
                              </div>
                              <div className="row">
                                 <div className="col-sm-12 text-right">
                                    <button type="button" className="btn btn-danger btn-lg"> Cancel </button>
                                    <button type="button" className="btn btn-success btn-lg"> Send Message </button>
                                 </div>
                              </div>
                           </form>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <section className="section-padding bg-white border-top">
         <div className="container">
            <div className="row">
               <div className="col-lg-4 col-sm-6">
                  <div className="feature-box">
                     <i className="mdi mdi-truck-fast"></i>
                     <h6>Free & Next Day Delivery</h6>
                     <p>Lorem ipsum dolor sit amet, cons...</p>
                  </div>
               </div>
               <div className="col-lg-4 col-sm-6">
                  <div className="feature-box">
                     <i className="mdi mdi-basket"></i>
                     <h6>100% Satisfaction Guarantee</h6>
                     <p>Rorem Ipsum Dolor sit amet, cons...</p>
                  </div>
               </div>
               <div className="col-lg-4 col-sm-6">
                  <div className="feature-box">
                     <i className="mdi mdi-tag-heart"></i>
                     <h6>Great Daily Deals Discount</h6>
                     <p>Sorem Ipsum Dolor sit amet, Cons...</p>
                  </div>
               </div>
            </div>
         </div>
      </section>
    
      
   <Footer/>
     
     </React.Fragment>
    )
}
