 
    import React,{useState,useContext,useEffect} from 'react'
    import { Link,useLocation } from 'react-router-dom'
    import Header from './Header'
    import Footer from './Footer'
    import { userDetailContext } from '../App'; 
    import { useHistory } from "react-router-dom"; 
    import { BASE_URL } from '../utils/Constants'
    import { apiRequestPOST } from '../utils/ApiCall' 
    import { toast } from 'react-toastify'; 
    
    export default function Notification() {
    
      
      let history = useHistory();
      var contextData = useContext(userDetailContext);
   
        return (
         <React.Fragment> 
         <Header/>
         
       
         <section className="faq-page section-padding">
<div className="container">
<div className="row">
<div className="col-lg-10 mx-auto">
<div className="row">
<div className="col-lg-12 col-md-12">
<div className="card card-body">
<div className="accordion" id="accordionExample"> 
{
 contextData?.userNotificvation?.length>0 && contextData?.userNotificvation.map((subItm,key) => {
   return ( 

<div className="card mb-0"> 
<div className="card-header" id="headingOne">
<h6 className="mb-0">
<a href="#" data-toggle="collapse" data-target={`#collapseOne${key}`} aria-expanded="true" aria-controls="collapseOne"
   onClick={() => subItm.deliverystatus!=1&& 
      apiRequestPOST(`${BASE_URL}app_user_ctrl/add_order_notification_view`,{ orderid: subItm.orderid }, false)
      .then(async (response1) => {   })
       .catch((error) => {
         console.log(error);
          })  } 
>
<i className="icofont icofont-question-square"></i> 
{subItm.deliverystatus==1&&'Order No.'+subItm.orderno+' approved'}
{subItm.deliverystatus==2&&'Order No.'+subItm.orderno+' cancel'}
{subItm.deliverystatus==3&&'Order No.'+subItm.orderno+' assigned'}
{subItm.deliverystatus==4&&'Order No.'+subItm.orderno+' delivered'}


</a>
</h6>
</div>
<div id={`collapseOne${key}`} className={key==0?"collapse show":"collapse"} aria-labelledby="headingOne" data-parent="#accordionExample">
<div className="card-body">
{subItm.deliverystatus==1&&
<> 
Your order has been approved. We will confirm your payment and continue your order
<div className="row"  style={{marginTop:'12px'}}> 
<div className="col-lg-4" style={{cursor:'pointer'}} 
onClick={() => 
   apiRequestPOST(`${BASE_URL}app_user_ctrl/add_order_payment`,{ orderid: subItm.orderid,paymentstatus:1 }, false)
   .then(async (response1) => { 
      if(response1 == 1){ 
      toast.success(' Successfully', {
         position: "top-center",
         autoClose: 5000,
         hideProgressBar: false,
         closeOnClick: true,
         pauseOnHover: true,
         draggable: true,
         progress: undefined,
         });
      }else{
         toast.error('Process Failed', {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });
      }
    })
    .catch((error) => {
      console.log(error);
       })  }>  
<center>
<h4>Gpay/PhonePay</h4>
<img src="assets/img/gpay.jpg" alt="logo" /> 
<h5>{subItm.upi_id}</h5>
</center>
 </div>
 <div className="col-lg-4" style={{cursor:'pointer'}} 
onClick={() => 
   apiRequestPOST(`${BASE_URL}app_user_ctrl/add_order_payment`,{ orderid: subItm.orderid,paymentstatus:3 }, false)
   .then(async (response1) => { 
      if(response1 == 1){ 
      toast.success(' Successfully', {
         position: "top-center",
         autoClose: 5000,
         hideProgressBar: false,
         closeOnClick: true,
         pauseOnHover: true,
         draggable: true,
         progress: undefined,
         });
      }else{
         toast.error('Process Failed', {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });
      }
    })
    .catch((error) => {
      console.log(error);
       })  }>   
<center>
<h4>QR Code</h4>
<img src={`${BASE_URL}assets/stores/resized/${subItm?.qrcode}`} alt=""/> 
</center>
 </div>
 <div className="col-lg-4" style={{cursor:'pointer'}} 
onClick={() => 
   apiRequestPOST(`${BASE_URL}app_user_ctrl/add_order_payment`,{ orderid: subItm.orderid,paymentstatus:2 }, false)
   .then(async (response1) => { 
      if(response1 == 1){ 
      toast.success(' Successfully', {
         position: "top-center",
         autoClose: 5000,
         hideProgressBar: false,
         closeOnClick: true,
         pauseOnHover: true,
         draggable: true,
         progress: undefined,
         });
      }else{
         toast.error('Process Failed', {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });
      }
    })
    .catch((error) => {
      console.log(error);
       })  }>  
 <center>
<h4>Cash On Delivery</h4> 
 <img src="assets/img/cod.png" alt="logo" /> 
</center>
</div> 
</div> 
</>
} 
{subItm.deliverystatus==2&&
'we are sorry that you didn’t love your order, but we’re here to make it better. '  
}
{subItm.deliverystatus==3&&
'Your order has Assigned to Our delivery boy'+ subItm.name 
}
{subItm.deliverystatus==4&&
' Your order has been delivered.'  
}
 </div>
</div> 
</div>
 
 )
})
    }
    {
contextData?.userNotificvation?.length==0&&
<h2><center> Notification Empty</center></h2>
}
 
 
</div>
</div>
</div>
 
</div>
</div>
</div>
</div>
</section>
 

      <section className="section-padding bg-white border-top">
         <div className="container">
            <div className="row">
               <div className="col-lg-4 col-sm-6">
                  <div className="feature-box">
                     <i className="mdi mdi-truck-fast"></i>
                     <h6>Free & Next Day Delivery</h6>
                     <p>Lorem ipsum dolor sit amet, cons...</p>
                  </div>
               </div>
               <div className="col-lg-4 col-sm-6">
                  <div className="feature-box">
                     <i className="mdi mdi-basket"></i>
                     <h6>100% Satisfaction Guarantee</h6>
                     <p>Rorem Ipsum Dolor sit amet, cons...</p>
                  </div>
               </div>
               <div className="col-lg-4 col-sm-6">
                  <div className="feature-box">
                     <i className="mdi mdi-tag-heart"></i>
                     <h6>Great Daily Deals Discount</h6>
                     <p>Sorem Ipsum Dolor sit amet, Cons...</p>
                  </div>
               </div>
            </div>
         </div>
      </section>
  
      
      <Footer/>
     
     </React.Fragment>
    )
}
