import React,{ useEffect, useState,useContext} from 'react'
import { Link,useLocation } from 'react-router-dom'
import { userDetailContext } from '../App';
import { BASE_URL } from '../utils/Constants'; 
import { apiRequestPOST } from '../utils/ApiCall' 
import { toast } from 'react-toastify'
import { useHistory } from "react-router-dom";  



export default function Footer() { 
   var contextData = useContext(userDetailContext);
   let history = useHistory();
   var subtotal=0; 
   const update_to_cart = (ProductId,userCartId,productQty) => { 
      //   console.log(ProductId);
        console.log(productQty);
     const shopIdStringify=localStorage.getItem('datahistory')
     console.log('shopIdStringify',shopIdStringify);
     const shopId =contextData?.shopIdz;
     const branchId =contextData?.branchId; 
        apiRequestPOST(`${BASE_URL}app_user_ctrl/product_cart_update`,{cart_id:userCartId,product_id : ProductId,productqty:productQty,store_id:shopId,branch_id:branchId,qty:1},false)
        .then((response) => {
         
         apiRequestPOST(`${BASE_URL}app_user_ctrl/user_cart_list`,'' ,false) 
         .then(async (response1) => { 
     await contextData.setUserCartist(response1.user_cart_list);  
      localStorage.setItem('userCartist',JSON.stringify(response1.user_cart_list))    
      localStorage.setItem('userCartiststore_id', JSON.stringify(response1.store_id))
      localStorage.setItem('userCartistbranch_id', JSON.stringify(response1.branch_id))
         })
         .catch((error) => {  
          console.log(error); 
         });

          if(response == 0){  
            toast.error('Failed, Try again', {
               position: "top-center",
               autoClose: 5000,
               hideProgressBar: false,
               closeOnClick: true,
               pauseOnHover: true,
               draggable: true,
               progress: undefined,
               });
        }
        if(response == 1){ 
            
            toast.success('Updated cart', {
               position: "top-center",
               autoClose: 5000,
               hideProgressBar: false,
               closeOnClick: true,
               pauseOnHover: true,
               draggable: true,
               progress: undefined,
               });
        }
        if(response == 2){ 
            toast.error('Not enough stock !!!', {
               position: "top-center",
               autoClose: 5000,
               hideProgressBar: false,
               closeOnClick: true,
               pauseOnHover: true,
               draggable: true,
               progress: undefined,
               });
        }
        if(response == 3){ 
            toast.error('Invalid Quantity !!!', {
               position: "top-center",
               autoClose: 5000,
               hideProgressBar: false,
               closeOnClick: true,
               pauseOnHover: true,
               draggable: true,
               progress: undefined,
               });
        }
       }, (error) => {
          console.log(error);
        });
       }


   const remove_to_cart = (cartId) => {
      //   console.log(ProductId);
     const shopIdStringify=localStorage.getItem('datahistory')
     const shopId =JSON.parse(shopIdStringify).id; 
        apiRequestPOST(`${BASE_URL}app_user_ctrl/product_remove_item_cart`,{cart_id : cartId},false)
        .then((response) => {
         
         apiRequestPOST(`${BASE_URL}app_user_ctrl/user_cart_list`,'' ,false) 
         .then(async (response) => { 
     await contextData.setUserCartist(response.user_cart_list);  
      localStorage.setItem('userCartist',JSON.stringify(response.user_cart_list))       
      localStorage.setItem('userCartiststore_id', JSON.stringify(response.store_id))
      localStorage.setItem('userCartistbranch_id', JSON.stringify(response.branch_id))
         })
         .catch((error) => {  
          console.log(error); 
         });
         toast.success('Remove !!!', {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });
       }, (error) => {
         toast.error('Failed, Try again', {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });
          console.log(error);
        });
       }
    return (
       <React.Fragment>

   

<section className="section-padding footer bg-white border-top footer1">
        <div className="container">
           <div className="row">

     
          
               <div className="col-lg-3 col-md-6  col-sm-6"> 
              <h6  className="mb-4">CONTACT</h6>
              <Link class="logo" to="/">
                     <img src={`${BASE_URL}assets/admin/original/${contextData?.admindata?.footer_logo}`} alt="logo" style={{height:'50px'}}/>
                           </Link>
              <p className="mb-0"><Link style={{fontWeight:500}}  ><i className="mdi mdi-phone"></i>{contextData?.admindata?.mob_1}</Link></p>
                 <p className="mb-0"><Link style={{fontWeight:500}}  ><i className="mdi mdi-cellphone-iphone"></i> {contextData?.admindata?.mob_2}</Link></p>
                 <p class="mb-0"><Link class="text-success" to="/"><i class="mdi mdi-email"></i>  {contextData?.admindata?.email}</Link></p>
                 <p class="mb-0"><Link class="text-primary" to="/"><i class="mdi mdi-web"></i> www.multivendor.com</Link></p>
               </div> 
              

              
           <div className="col-lg-3 col-md-6  col-sm-6">
                 <h6  className="mb-4">ABOUT</h6>
                  
                 <p className="mb-0"><Link to='/about' style={{fontWeight:500}}  >About Us</Link></p>
                 <p className="mb-0"><Link to='/contact' style={{fontWeight:500}}  >Contact Us</Link></p> 
                 <p className="mb-0"><Link to='/faq' style={{fontWeight:500}}  >Faq</Link></p> 
                  <p className="mb-0"><Link to="/terms" style={{fontWeight:500}}  >Terms & Conditions</Link></p>
              
              </div>

              <div className="col-lg-3 col-md-6  col-sm-6">
                 <h6   className="mb-4">POLICY</h6>
                 <p className="mb-0"><Link  to="/refundpolicy" style={{fontWeight:500}}  >Refund policy</Link></p>
                 <p className="mb-0"><Link  to="/returnpolicy" style={{fontWeight:500}}  >Return  policy</Link></p>
                 <p className="mb-0"><Link  to="/cancellationpolicy" style={{fontWeight:500}}  >Cancellation policy</Link></p>
                 <p className="mb-0"><Link to="/privacypolicy" style={{fontWeight:500}}  >Privacy Policy</Link></p>
                 <p className="mb-0"><Link to="/shippingpolicy" style={{fontWeight:500}}  >Shipping Policy</Link></p>
                </div>  
               
                      <div class="col-lg-3 col-md-3  col-sm-6">
                  <h6 class="mb-4">Download App</h6>
                  <div class="app">
                     <Link to="/"><img src="../assets/img/google.png" alt=""/></Link>
                     <Link to="/"><img src="../assets/img/apple.png" alt=""/></Link>
                  </div>
                  <h6 class="mb-3 mt-4">GET IN TOUCH</h6>
                  <div class="footer-social">
                  <Link className="btn-facebook" to="/"><i className="mdi mdi-facebook"></i></Link>
                    <Link className="btn-twitter" to="/"><i className="mdi mdi-twitter"></i></Link>
                    <Link className="btn-instagram" to="/"><i className="mdi mdi-instagram"></i></Link>
                    <Link className="btn-whatsapp" to="/"><i className="mdi mdi-whatsapp"></i></Link>
                    <Link className="btn-messenger" to="/"><i className="mdi mdi-facebook-messenger"></i></Link>
                    <Link className="btn-google" to="/"><i className="mdi mdi-google"></i></Link>
                  </div>
               </div>
           
           </div>
        </div>
     </section>
     

     <section class="pt-4 pb-4 footer-bottom">
         <div class="container">
            <div class="row no-gutters">
               <div class="col-lg-6 col-sm-6">
                  <p class="mt-1 mb-0">&copy; Copyright 2022 <strong class="text-dark"></strong>. All Rights Reserved 
				  </p>
               </div>
               <div class="col-lg-6 col-sm-6 text-right">
                  <img alt="osahan logo" src="../assets/img/payment_methods.png"/>
               </div>
            </div>
         </div>
      </section>



     <div className="cart-sidebar">
        <div className="cart-sidebar-header">
           <h5 >
              My Cart <span className="text-success">( {contextData?.usercartlist?.length}  item)</span> <Link style={{fontWeight:500}} data-toggle="offcanvas-block"
               onClick={()=>{
                  document.body.className=""
               }}
              
              className="float-right" ><i className="mdi mdi-close" style={{color:'#000'}}></i>
              </Link>
           </h5>
        </div>
        <div className="cart-sidebar-body">
 
            {  
           contextData?.usercartlist?.length>0&&contextData?.usercartlist?.map(item => { 
            
            let original_price = item.original_price;
            let selling_price = item.selling_price;
            if(contextData?.country=='INDIA'){
               let original_price = item.original_price;
               let selling_price = item.selling_price;
}else{
let original_price = item.original_price_abroad;
let selling_price = item.selling_price_abroad;
}
            let discountamount = original_price-selling_price; 
            let discount_percent = (discountamount/original_price)*100; 
             subtotal=parseFloat(subtotal)+ (parseFloat(selling_price) * parseFloat(item.user_cart_qty));
                        return (  
           <div className="cart-list-product">
              <span  style={{cursor:'pointer'}} className="float-right remove-cart" onClick={() => remove_to_cart(item.user_cart_id)} ><i className="mdi mdi-delete"></i></span>
              <img className="img-fluid" src={`${BASE_URL}assets/products/original/${item.pic1}`} alt=""/>
              <span className="badge badge-success">{discount_percent.toFixed(0)}% OFF</span>
              <Link style={{fontWeight:500}}  data-toggle="offcanvas-block"><h5 style={{cursor:'pointer',color:'#fff'}} onClick={() => {
                                         document.body.className=""

                         history.push({
                           pathname: "/single", 
                          state:{productId: item.id} 
                        })
                     }
                        } >{item.name}</h5></Link>
              <h6 ><strong> Quantity</strong> : &nbsp;
              <span className="product" style={{padding: 2}}>
              <span className="input-group-btn"  onClick={() => update_to_cart(item.id,item.user_cart_id,parseFloat(item.user_cart_qty)-1)}><button  className="btn btn-theme-round btn-number" type="button">-</button></span> &nbsp;
              <strong style={{fontSize: 14}}>{item.user_cart_qty}</strong> &nbsp;
               <span className="input-group-btn" onClick={() => update_to_cart(item.id,item.user_cart_id,parseFloat(item.user_cart_qty)+1)}><button className="btn btn-theme-round btn-number" type="button">+</button> </span>
             </span>&nbsp;{item.qty} {item.product_unit}
               </h6>
              <p className="offer-price mb-0">{contextData?.country=='INDIA'? '₹'+ item.selling_price : '$' + item.selling_price_abroad} <i className="mdi mdi-tag-outline"></i> <span className="regular-price">{contextData?.country=='INDIA'? '₹' + item.original_price : '$' + item.original_price_abroad}</span></p>
           </div>
            ) }  ) 
         }
           
        </div>
        <div className="cart-sidebar-footer">
           <div className="cart-store-details">
              {/* <p>Sub Total <strong className="float-right">₹{subtotal.toFixed(2)}  </strong></p>  */}
               
              <h6 >Sub total  <strong className="float-right text-danger">₹{subtotal.toFixed(2)}</strong> <p>Extra charges may apply</p></h6>
             
           </div> 
           {subtotal>0?
           <Link style={{fontWeight:500}}  data-toggle="offcanvas-block"
           onClick={() =>  
           document.body.className=""
           }
           ><button   onClick={() =>  
            history.push({
              pathname: "/checkout"
           })
           }  className="btn btn-secondary btn-lg btn-block text-left" type="button"><span className="float-left"><i className="mdi mdi-cart-outline"></i> Proceed to Checkout </span><span className="float-right"><strong>₹{subtotal.toFixed(2)}</strong> <span className="mdi mdi-chevron-right"></span></span></button></Link>
       :  <Link style={{fontWeight:500}}  data-toggle="offcanvas-block"><button  disabled className="btn btn-secondary btn-lg btn-block text-left" type="button"><span className="float-left"><i className="mdi mdi-cart-outline"></i> Proceed to Checkout </span><span className="float-right"><strong>₹{subtotal.toFixed(2)}</strong> <span className="mdi mdi-chevron-right"></span></span></button></Link>
      }
           </div>
     </div>


       </React.Fragment>
    )
}
